/* eslint-disable no-console */
import React, { useState, useCallback, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useApolloClient } from '@apollo/client';
import { get } from 'lodash';
import styled from 'styled-components';
import { P, match } from 'ts-pattern';
import {
  Mutation,
  MutationResourceWhatsappSetActiveArgs,
  MutationUpdateWhatsappTemplatesSyncArgs,
  MutationActivateWhatsappResourceArgs,
} from '@lgg/isomorphic/types/__generated__/graphql';
import {
  NotificationContext,
  ShowNotificationProps,
} from 'src/components/general/feedback/hooks/use-show-notification';
import {
  UPDATE_WHATSAPP_TEMPLATES_SYNC_MUTATION,
  WHATSAPP_RESOURCE_SET_ACTIVE_MUTATION,
  ACTIVATE_WHATSAPP_RESOURCE_MUTATION,
  WHATSAPP_RESOURCES_QUERY,
} from 'src/components/pages/whatsapp-channel/shared/queries';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';

export const useActivateWhatsappResource = () => {
  return useMutation<
    Pick<Mutation, 'activateWhatsappResource'>,
    MutationActivateWhatsappResourceArgs
  >(ACTIVATE_WHATSAPP_RESOURCE_MUTATION, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });
};

export const useUpdateWhatsappTemplatesSync = () => {
  return useMutation<
    Pick<Mutation, 'updateWhatsappTemplatesSync'>,
    MutationUpdateWhatsappTemplatesSyncArgs
  >(UPDATE_WHATSAPP_TEMPLATES_SYNC_MUTATION, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });
};

export const useWhatsappResourceSetActive = () => {
  return useMutation<
    Pick<Mutation, 'resourceWhatsappSetActive'>,
    MutationResourceWhatsappSetActiveArgs
  >(WHATSAPP_RESOURCE_SET_ACTIVE_MUTATION, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });
};

type UseHandleSyncWhatsappTemplatesProps = {
  resourceId: number;
  onUpdateError: (message: string) => void;
  onCompleted: (data: Pick<Mutation, 'updateWhatsappTemplatesSync'>) => Promise<void>;
};

export const useHandleSyncWhatsappTemplates = ({
  onCompleted,
  onUpdateError,
  resourceId,
}: UseHandleSyncWhatsappTemplatesProps) => {
  const handleGraphQLError = useHandleGraphQLError();
  const [updateWhatsappTemplatesSync, { loading: isSyncingTemplates }] =
    useUpdateWhatsappTemplatesSync();

  const handleSyncTemplates = useCallback(async () => {
    await updateWhatsappTemplatesSync({
      variables: {
        resourceId,
      },
      onError: (error) => {
        const firstError = error.graphQLErrors[0];

        match(firstError)
          .with(
            {
              extensions: {
                detail: {
                  http: {
                    response: {
                      data: {
                        meta: {
                          axios: {
                            response: {
                              data: {
                                meta: {
                                  developer_message: P.string,
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            (error) => {
              onUpdateError(
                error.extensions.detail.http.response.data.meta.axios.response.data.meta
                  .developer_message,
              );
            },
          )
          .with(
            {
              extensions: {
                detail: {
                  http: {
                    response: {
                      data: {
                        meta: {
                          errorMeta: {
                            code: 'INVALID_ACCESS_TOKEN',
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            (error) => {
              onUpdateError(
                error.extensions.detail.http.response.data.meta.errorMeta.code,
              );
            },
          )
          .otherwise(() => handleGraphQLError(error));
      },
      onCompleted: onCompleted,
    });
  }, [
    updateWhatsappTemplatesSync,
    resourceId,
    onCompleted,
    onUpdateError,
    handleGraphQLError,
  ]);

  return { handleSyncTemplates, isSyncingTemplates };
};

type ActivationData = {
  code: string;
  waba: string;
  phoneNumberId: string;
};

const defaultState = {
  code: '',
  waba: '',
  phoneNumberId: '',
};

const StyledLink = styled.a`
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;

  &,
  &:focus,
  &:visited,
  &:hover,
  &:active {
    color: ${({ theme }) => theme.colors.gogo};
  }
`;

export const useMetaResourceActivation = () => {
  const { t } = useTranslation(['whatsappChannel']);

  const { openNotification } = useContext(NotificationContext);
  const apolloClient = useApolloClient();

  const [activationData, setActivationData] = useState<ActivationData>(defaultState);
  const [activateWhatsappResource, { loading: isActivatingResource }] =
    useActivateWhatsappResource();

  const handleActivateWhatsappResource = useCallback(() => {
    openNotification({
      title: t('whatsappChannel:pages.channel.activateResource.warning.title'),
      message: t('whatsappChannel:pages.channel.activateResource.warning.message'),
      type: 'warning',
    });

    void activateWhatsappResource({
      variables: activationData,
      onCompleted: async () => {
        void apolloClient.refetchQueries({
          include: [WHATSAPP_RESOURCES_QUERY],
        });

        openNotification({
          title: t('whatsappChannel:pages.channel.activateResource.success.title'),
          message: t('whatsappChannel:pages.channel.activateResource.success.message'),
          type: 'success',
        });
      },
      onError: (error) => {
        const firstError = error.graphQLErrors[0];

        const defaultErrorConfig = {
          message: t('whatsappChannel:pages.channel.activateResource.error.general'),
          type: 'error' as const,
        };

        const showNotificationProps: Pick<
          ShowNotificationProps,
          'message' | 'type' | 'actionElements'
        > = match(firstError)
          .with(
            {
              extensions: {
                detail: {
                  http: {
                    response: {
                      data: {
                        meta: {
                          errorMeta: P.select('errorMeta', {
                            code: P.string,
                            detail: P.any,
                          }),
                        },
                      },
                    },
                  },
                },
              },
            },
            ({ errorMeta }) => {
              return match(errorMeta)
                .with({ code: 'RESOURCE_PHONE_NUMBER_NOT_FOUND' }, () => {
                  return {
                    message: t(
                      'whatsappChannel:pages.channel.activateResource.error.resourceNotFound',
                    ),
                    type: 'error' as const,
                  };
                })
                .with({ code: 'WABA_REVIEW_PENDING' }, () => {
                  return {
                    message: t(
                      'whatsappChannel:pages.channel.activateResource.warning.wabaPending',
                    ),
                    type: 'warning' as const,
                  };
                })
                .with({ code: 'WABA_REVIEW_REJECTED' }, () => {
                  return {
                    message: t(
                      'whatsappChannel:pages.channel.activateResource.warning.wabaRejected',
                    ),
                    actionElements: (
                      <StyledLink
                        href="https://business.facebook.com/accountquality"
                        target="_blank"
                      >
                        Meta
                      </StyledLink>
                    ),
                    type: 'warning' as const,
                  };
                })
                .with({ code: 'WABA_PHONE_REGISTRATION_ERROR' }, (errorMeta) => {
                  const detail = get(errorMeta, 'detail.data.error.error_data.details');

                  if (detail) {
                    return {
                      message: t(
                        'whatsappChannel:pages.channel.activateResource.error.phoneRegistrationError',
                        { error: detail },
                      ),
                      type: 'error' as const,
                    };
                  } else {
                    return defaultErrorConfig;
                  }
                })
                .otherwise(() => {
                  return defaultErrorConfig;
                });
            },
          )
          .otherwise(() => defaultErrorConfig);

        openNotification({
          title:
            showNotificationProps.type === 'error'
              ? t('whatsappChannel:pages.channel.activateResource.error.title')
              : t('whatsappChannel:pages.channel.activateResource.warning.title'),
          ...showNotificationProps,
        });
      },
    });
  }, [activateWhatsappResource, activationData, apolloClient, openNotification, t]);

  // Function to handle Facebook login
  const launchWhatsAppSignUp = useCallback(() => {
    setActivationData(defaultState);
    window.FB?.login(
      function (response) {
        if (response.authResponse) {
          const accessToken = response.authResponse.code;
          setActivationData((prev: ActivationData) => ({ ...prev, code: accessToken }));
        } else {
          console.log('User cancelled login or did not fully authorize.');
        }
      },
      {
        config_id: import.meta.env.VITE_META_WHATSAPP_APP_CONFIG,
        response_type: 'code', // must be set to 'code' for System User access token
        override_default_response_type: true,
        extras: {
          feature: 'whatsapp_embedded_signup',
          sessionInfoVersion: 2, //  Receive Session Logging Info
        },
      },
    );
  }, []);

  const sessionInfoListener = useCallback((event) => {
    if (
      event.origin !== 'https://www.facebook.com' &&
      event.origin !== 'https://web.facebook.com'
    ) {
      return;
    }

    try {
      const data = JSON.parse(event.data);
      if (data.type === 'WA_EMBEDDED_SIGNUP') {
        // if user finishes the Embedded Signup flow
        if (data.event === 'FINISH') {
          const { phone_number_id, waba_id } = data.data;
          setActivationData((prev: ActivationData) => ({
            ...prev,
            waba: waba_id,
            phoneNumberId: phone_number_id,
          }));
        }
      }
    } catch {
      // Don’t parse info that’s not a JSON
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', sessionInfoListener);

    return () => {
      window.removeEventListener('message', sessionInfoListener);
    };
  }, [sessionInfoListener]);

  useEffect(() => {
    if (['code', 'waba', 'phoneNumberId'].every((key) => activationData[key])) {
      handleActivateWhatsappResource();
      setActivationData(defaultState);
    }
  }, [activationData, handleActivateWhatsappResource]);

  return { launchWhatsAppSignUp, isActivatingResource };
};
