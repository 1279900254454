import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { match } from 'ts-pattern';
import { Icon } from 'src/components/general/icon';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import {
  getBroadcastPercentageValue,
  getPercentageDisplayValue,
} from 'src/components/pages/broadcast/components/broadcast-wizard/utils';

const BroadcastStatisticCardsContainer = styled(FlexRow)`
  justify-content: space-between;
`;

const BroadcastStatisticCardContainer = styled(FlexColumn)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.porcelain};
  border-radius: 6px;
  height: 178px;
  padding: 26px 10px 0;
  width: 160px;

  & + & {
    margin-left: 5px;
  }
`;

const BroadcastStatisticIcon = styled(Icon)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  display: flex;
  height: 60px;
  justify-content: center;
  margin-bottom: 9px;
  width: 60px;

  svg {
    height: 22px;
    width: 22px;

    path {
      fill: ${({ theme }) => theme.colors.cosmo};
    }
  }
`;

const BroadcastStatisticCardTitle = styled.span`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 1px;
  text-align: center;
`;

const BroadcastStatisticCardValue = styled.span`
  color: ${({ theme }) => theme.colors.flint};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
`;

type BroadcastStatisticCardProps = {
  icon: string;
  label: string;
  value: string;
};

const BroadcastStatisticCard = ({
  icon,
  label,
  value,
  ...rest
}: BroadcastStatisticCardProps) => {
  return (
    <BroadcastStatisticCardContainer {...rest}>
      <BroadcastStatisticIcon type={icon} />
      <BroadcastStatisticCardTitle>{label}</BroadcastStatisticCardTitle>
      <BroadcastStatisticCardValue data-lgg-id="statistic-card-value">
        {value}
      </BroadcastStatisticCardValue>
    </BroadcastStatisticCardContainer>
  );
};

const TIME_UNITS_IN_SECONDS = {
  minute: 60,
  hour: 3600,
  day: 86400,
};

export type BroadcastStatisticsProps = {
  sent: number;
  averageResponseTimeInSeconds: number;
  optOuts: number;
  replies: number;
};

export const BroadcastStatistics = ({
  averageResponseTimeInSeconds,
  optOuts,
  replies,
  sent,
}: BroadcastStatisticsProps) => {
  const { t } = useTranslation(['common', 'broadcast']);

  const getAverageResponseTimeValue = useCallback(
    (timeInSeconds: number) => {
      return match(timeInSeconds)
        .when(
          (value) => value <= 0,
          () => '–',
        )
        .when(
          (value) => value < TIME_UNITS_IN_SECONDS.minute,
          (value) => t('common:timeUnits.second', { count: value }),
        )
        .when(
          (value) => value < TIME_UNITS_IN_SECONDS.hour,
          (value) =>
            t('common:timeUnits.minute', {
              count: Math.floor(value / TIME_UNITS_IN_SECONDS.minute),
            }),
        )
        .when(
          (value) => value < TIME_UNITS_IN_SECONDS.day,
          (value) =>
            t('common:timeUnits.hour', {
              count: Math.floor(value / TIME_UNITS_IN_SECONDS.hour),
            }),
        )
        .otherwise((value) =>
          t('common:timeUnits.day', {
            count: Math.floor(value / TIME_UNITS_IN_SECONDS.day),
          }),
        );
    },
    [t],
  );

  return (
    <BroadcastStatisticCardsContainer>
      <BroadcastStatisticCard
        data-lgg-id="broadcast-statistic-avg-response-time"
        icon="time"
        label={t(
          'broadcast:pages.broadcast.broadcastDetailsModal.statistics.cards.avgResponseTime',
        )}
        value={getAverageResponseTimeValue(averageResponseTimeInSeconds)}
      />
      <BroadcastStatisticCard
        data-lgg-id="broadcast-statistic-opt-outs"
        icon="speedometer"
        label={t(
          'broadcast:pages.broadcast.broadcastDetailsModal.statistics.cards.optedOut',
        )}
        value={getPercentageDisplayValue(getBroadcastPercentageValue(sent, optOuts))}
      />
      <BroadcastStatisticCard
        data-lgg-id="broadcast-statistic-response-rate"
        icon="reply"
        label={t(
          'broadcast:pages.broadcast.broadcastDetailsModal.statistics.cards.responseRate',
        )}
        value={getPercentageDisplayValue(getBroadcastPercentageValue(sent, replies))}
      />
    </BroadcastStatisticCardsContainer>
  );
};
