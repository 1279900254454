import type { Spread } from 'lexical';
import {
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  LexicalNode,
  NodeKey,
  SerializedTextNode,
  TextNode,
} from 'lexical';
import { trimStart } from 'lodash';

export type SerializedMentionNode = Spread<
  {
    userName: string;
    type: 'user-mention';
    version: 1;
    userId: string;
  },
  SerializedTextNode
>;

const convertMentionElement = (domNode: HTMLElement): DOMConversionOutput | null => {
  const textContent = domNode.textContent;

  const userId = domNode.getAttribute('data-mention-user-id');

  if (textContent !== null && userId !== null) {
    const node = $createMentionNode(textContent, userId);

    return {
      node,
    };
  }

  return null;
};

const mentionStyle = `
  color: #2d98da;
  font-size: 12px;
  font-weight: 700;
`;

export class UserMentionNode extends TextNode {
  constructor(userName: string, userId: string, text?: string, key?: NodeKey) {
    const cleanUserName = trimStart(userName, '@');

    super(`@${cleanUserName}`, key);

    this.__userName = cleanUserName;
    this.__userId = userId;
  }

  __userName: string;
  __userId: string;

  static getType(): string {
    return 'user-mention';
  }

  get userId(): string {
    return this.__userId;
  }

  static clone(node: UserMentionNode): UserMentionNode {
    return new UserMentionNode(node.__userName, node.__userId, node.__text, node.__key);
  }

  static importJSON(serializedNode: SerializedMentionNode): UserMentionNode {
    const node = $createMentionNode(serializedNode.userName, serializedNode.userId);
    node.setTextContent(serializedNode.text);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  exportJSON(): SerializedMentionNode {
    return {
      ...super.exportJSON(),
      userId: this.__userId,
      userName: this.__userName,
      type: 'user-mention',
      version: 1,
    };
  }

  createDOM(config: EditorConfig): HTMLElement {
    const dom = super.createDOM(config);
    dom.style.cssText = mentionStyle;
    dom.className = 'mention';
    dom.innerText = this.__text;

    return dom;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('span');
    element.setAttribute('data-lexical-mention', 'true');
    element.setAttribute('data-mention-user-id', this.__userId);
    element.textContent = this.__text;

    return { element };
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute('data-lexical-mention')) {
          return null;
        }
        return {
          conversion: convertMentionElement,
          priority: 1,
        };
      },
    };
  }

  isTextEntity(): true {
    return true;
  }
}

export const $createMentionNode = (userName: string, userId: string): UserMentionNode => {
  const mentionNode = new UserMentionNode(userName, userId);
  mentionNode.setMode('token').toggleDirectionless();

  return mentionNode;
};

export const $isMentionNode = (
  node: LexicalNode | null | undefined,
): node is UserMentionNode => {
  return node instanceof UserMentionNode;
};
