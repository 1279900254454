import SMSCounter from 'sms-counter';
import { match } from 'ts-pattern';
import { getBroadcastOptOutMessage } from '@lgg/isomorphic/utils/get-broadcast-opt-out-message';

export const getBroadcastSmsValue = ({
  companyName,
  message,
  language,
}: {
  companyName?: string | null;
  message?: string | null;
  language?: 'ES' | 'EN' | null;
}): string => {
  const companyNameContent = companyName ?? '';
  const messageContent = message ?? '';
  const optOutMessage = language ? getBroadcastOptOutMessage(language) : '';

  return [companyNameContent, messageContent, optOutMessage].join('\n\n');
};

export const getBroadcastSmsCounterValue = ({
  companyName,
  message,
  language,
}: {
  companyName?: string | null;
  message?: string | null;
  language?: 'ES' | 'EN' | null;
}): number =>
  SMSCounter.count(getBroadcastSmsValue({ companyName, language, message })).messages;

export const getBroadcastPercentageValue = (total: number, value: number) => {
  if (total <= 0) {
    return 0;
  }

  return parseFloat(((value / total) * 100).toFixed(2));
};

export const getPercentageDisplayValue = (value: number) => {
  if (value === 0) {
    return '–';
  }

  return `${Number.isInteger(value) ? Math.round(value) : value.toFixed(2)}%`;
};

export enum OptOutMessageLanguage {
  EN = 'EN',
  ES = 'ES',
}

export const getBroadcastMessageLanguage = (value?: string | null) =>
  match(value)
    .with('ES', () => OptOutMessageLanguage.ES)
    .with('EN', () => OptOutMessageLanguage.EN)
    .otherwise(() => null);
