import imageCompression from 'browser-image-compression';
import { P, match } from 'ts-pattern';

export const compressImage = async (
  originalFile: File,
  maxSizeMb?: number,
): Promise<File> => {
  const unsupportedTypes = ['image/gif'];
  const originalFileType = originalFile.type;

  if (unsupportedTypes.includes(originalFileType)) {
    return originalFile;
  }

  const outputType = match(originalFileType)
    .with(P.union('image/png', 'image/webp'), () => 'image/png')
    .otherwise(() => 'image/jpeg');

  try {
    const compressedFile = await imageCompression(originalFile, {
      maxSizeMB: maxSizeMb,
      maxWidthOrHeight: 4096,
      initialQuality: 0.85,
      fileType: outputType,
    });

    // Safari doesn't support options.type, so the default type is image/png, which is causing an issue.
    // https://developer.mozilla.org/en-US/docs/Web/API/OffscreenCanvas/convertToBlob#type
    // https://github.com/Donaldcwl/browser-image-compression/issues/8
    return compressedFile instanceof File
      ? compressedFile
      : new File([compressedFile], originalFile.name, { type: outputType });
  } catch {}

  return originalFile;
};
