import { orderBy } from 'lodash';
import { Conversation } from '@lgg/isomorphic/types/__generated__/graphql';

export const sortConversations = (
  conversations: Conversation[],
  sortByRelevantUpdateAt: boolean,
) => {
  return orderBy(
    conversations,
    [
      (conversation) => {
        return new Date(
          sortByRelevantUpdateAt
            ? conversation.relevantUpdateAt
            : conversation.lastContactInteraction?.occurredAt,
        ).getTime();
      },
      (conversation) => conversation.id,
    ],
    ['desc', 'asc'],
  );
};
