import { match, P } from 'ts-pattern';

export type InputDateType =
  | 'IN_THE_LAST'
  | 'OVER'
  | 'NO_DATE'
  | 'LAST_WEEK'
  | 'LAST_MONTH'
  | 'LAST_YEAR'
  | 'TODAY'
  | 'YESTERDAY'
  | 'THIS_WEEK'
  | 'THIS_MONTH'
  | 'THIS_YEAR'
  | 'EXACT_DATE'
  | 'DATE_RANGE';

const dateInputTypePatterns = {
  inTheLast: 'IN_THE_LAST' as const,
  over: 'OVER' as const,
  noDate: 'NO_DATE' as const,
  lastWeek: 'LAST_WEEK' as const,
  lastMonth: 'LAST_MONTH' as const,
  lastYear: 'LAST_YEAR' as const,
  today: 'TODAY' as const,
  yesterday: 'YESTERDAY' as const,
  thisWeek: 'THIS_WEEK' as const,
  thisMonth: 'THIS_MONTH' as const,
  thisYear: 'THIS_YEAR' as const,
  exactDate: 'EXACT_DATE' as const,
  dateRange: 'DATE_RANGE' as const,
};

export type AdvancedDateFilterRelativeTypeWithUnitOver =
  | '_isOverXDays'
  | '_isOverXWeeks'
  | '_isOverXMonths'
  | '_isOverXYears';

export type AdvancedDateFilterRelativeTypeWithUnitInTheLast =
  | '_isLastXDays'
  | '_isLastXWeeks'
  | '_isLastXMonths'
  | '_isLastXYears';

type AdvancedDateFilterRelativeType =
  | '_isToday'
  | '_isThisWeek'
  | '_isThisMonth'
  | '_isThisYear'
  | '_isYesterday'
  | '_isLastWeek'
  | '_isLastMonth'
  | '_isLastYear'
  | '_notSet';

export type AdvancedDateFilterType =
  | '_isBetween'
  | '_isExactDate'
  | AdvancedDateFilterRelativeType
  | AdvancedDateFilterRelativeTypeWithUnitOver
  | AdvancedDateFilterRelativeTypeWithUnitInTheLast;

type MatchDateInputTypeHandler<Result> = {
  inTheLast: (type: InputDateType) => Result;
  over: (type: InputDateType) => Result;
  noDate: (type: InputDateType) => Result;
  lastWeek: (type: InputDateType) => Result;
  lastMonth: (type: InputDateType) => Result;
  lastYear: (type: InputDateType) => Result;
  today: (type: InputDateType) => Result;
  yesterday: (type: InputDateType) => Result;
  thisWeek: (type: InputDateType) => Result;
  thisMonth: (type: InputDateType) => Result;
  thisYear: (type: InputDateType) => Result;
  exactDate: (type: InputDateType) => Result;
  dateRange: (type: InputDateType) => Result;
};

export const matchInputDateTypeExhaustive = <Result>(
  type: InputDateType,
  handler: MatchDateInputTypeHandler<Result>,
) => {
  return match(type)
    .with(dateInputTypePatterns.inTheLast, handler.inTheLast)
    .with(dateInputTypePatterns.over, handler.over)
    .with(dateInputTypePatterns.noDate, handler.noDate)
    .with(dateInputTypePatterns.lastWeek, handler.lastWeek)
    .with(dateInputTypePatterns.lastMonth, handler.lastMonth)
    .with(dateInputTypePatterns.lastYear, handler.lastYear)
    .with(dateInputTypePatterns.today, handler.today)
    .with(dateInputTypePatterns.yesterday, handler.yesterday)
    .with(dateInputTypePatterns.thisWeek, handler.thisWeek)
    .with(dateInputTypePatterns.thisMonth, handler.thisMonth)
    .with(dateInputTypePatterns.thisYear, handler.thisYear)
    .with(dateInputTypePatterns.exactDate, handler.exactDate)
    .with(dateInputTypePatterns.dateRange, handler.dateRange)
    .run();
};

export type InputTimeUnit = 'DAY' | 'WEEK' | 'MONTH' | 'YEAR';

type MatchDateInputUnitTypeHandler<Result> = {
  day: (type: InputTimeUnit) => Result;
  week: (type: InputTimeUnit) => Result;
  month: (type: InputTimeUnit) => Result;
  year: (type: InputTimeUnit) => Result;
};

export const matchInputDateUnitExhaustive = <Result>(
  type: InputTimeUnit,
  handler: MatchDateInputUnitTypeHandler<Result>,
) => {
  return match(type)
    .with('DAY', handler.day)
    .with('WEEK', handler.week)
    .with('MONTH', handler.month)
    .with('YEAR', handler.year)
    .run();
};

export const advancedDateFilterOverXPattern = P.union(
  '_isOverXDays' as AdvancedDateFilterRelativeTypeWithUnitOver,
  '_isOverXWeeks' as AdvancedDateFilterRelativeTypeWithUnitOver,
  '_isOverXMonths' as AdvancedDateFilterRelativeTypeWithUnitOver,
  '_isOverXYears' as AdvancedDateFilterRelativeTypeWithUnitOver,
);

export const advancedDateFilterInTheLastXPattern = P.union(
  '_isLastXDays' as AdvancedDateFilterRelativeTypeWithUnitInTheLast,
  '_isLastXWeeks' as AdvancedDateFilterRelativeTypeWithUnitInTheLast,
  '_isLastXMonths' as AdvancedDateFilterRelativeTypeWithUnitInTheLast,
  '_isLastXYears' as AdvancedDateFilterRelativeTypeWithUnitInTheLast,
);

type MatchDateInputTypeOnChangeHandler<Result> = {
  range: (type: '_isBetween') => Result;
  exact: (type: '_isExactDate') => Result;
  relativeWithUnitOver: (type: AdvancedDateFilterRelativeTypeWithUnitOver) => Result;
  relativeWithUnitInTheLast: (
    type: AdvancedDateFilterRelativeTypeWithUnitInTheLast,
  ) => Result;
  relative: (type: AdvancedDateFilterRelativeType) => Result;
  empty: () => Result;
};

export const advancedDateFilterRelativeTypePattern = P.union(
  '_isToday' as AdvancedDateFilterRelativeType,
  '_isThisWeek' as AdvancedDateFilterRelativeType,
  '_isThisMonth' as AdvancedDateFilterRelativeType,
  '_isThisYear' as AdvancedDateFilterRelativeType,
  '_isYesterday' as AdvancedDateFilterRelativeType,
  '_isLastWeek' as AdvancedDateFilterRelativeType,
  '_isLastMonth' as AdvancedDateFilterRelativeType,
  '_isLastYear' as AdvancedDateFilterRelativeType,
  '_notSet' as AdvancedDateFilterRelativeType,
);

export const advancedDateFilterRelativeWithUnitPattern = P.union(
  advancedDateFilterOverXPattern,
  advancedDateFilterInTheLastXPattern,
);

export const matchAdvancedDateInputTypeExhaustive = <Result>(
  type: AdvancedDateFilterType | null,
  handler: MatchDateInputTypeOnChangeHandler<Result>,
) => {
  return match(type)
    .with(null, handler.empty)
    .with('_isBetween', handler.range)
    .with('_isExactDate', handler.exact)
    .with(advancedDateFilterOverXPattern, handler.relativeWithUnitOver)
    .with(advancedDateFilterInTheLastXPattern, handler.relativeWithUnitInTheLast)
    .with(advancedDateFilterRelativeTypePattern, handler.relative)
    .run();
};

type MatchValidAdvancedDateInputKeyOnChangeHandler<Result> = {
  valid: (type: AdvancedDateFilterType) => Result;
  notValid: (type: AdvancedDateFilterType) => Result;
};

export const matchValidAdvancedDateInputKey = <Result>(
  type: string | null,
  handler: MatchValidAdvancedDateInputKeyOnChangeHandler<Result>,
) => {
  return match(type)
    .with(
      P.union(
        advancedDateFilterRelativeWithUnitPattern,
        advancedDateFilterRelativeTypePattern,
        '_isBetween',
        '_isExactDate',
      ),
      handler.valid,
    )
    .otherwise(() => handler.notValid);
};
