import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ApolloError, useMutation } from '@apollo/client';
import { Tag } from 'antd';
import styled, { css } from 'styled-components';
import { match, P } from 'ts-pattern';
import {
  BroadcastStatus,
  Mutation,
  MutationUpdateBroadcastArchiveStatusArgs,
  MutationUpdateBroadcastStatusArgs,
  UpdateBroadcastStatus,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { Scrollbar } from 'src/components/general/display/scrollbar';
import { useShowConfirmationModal } from 'src/components/general/feedback/hooks/use-show-confirmation-modal';
import { useShowNotification } from 'src/components/general/feedback/hooks/use-show-notification';
import { Icon } from 'src/components/general/icon';
import {
  canBroadcastBeEdited,
  isBroadcastInProgress,
} from 'src/components/pages/broadcast/helpers';
import {
  UPDATE_BROADCAST_ARCHIVED_STATUS_MUTATION,
  UPDATE_BROADCAST_STATUS_MUTATION,
} from 'src/components/pages/broadcast/queries';
import { resolvePreviewWithContactPlaceholders } from 'src/components/pages/conversations/components/general/lexical-editor/plugins/insert-contact-placeholder-plugin';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';
import { useUrls } from 'src/hooks/use-urls';

const baseTagStyles = css`
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 8px;
  height: 12px;
  letter-spacing: -0.16px;
  line-height: 10px;
  margin: 3px 0 0;
  padding: 0 4px;
  text-transform: uppercase;
  width: max-content;
`;

const StyledBroadcastStatusTag = styled(({ tagTheme, ...props }) => <Tag {...props} />)`
  ${baseTagStyles};
  background: ${({ theme, tagTheme }) => theme.colors[tagTheme.background]};
  border-color: ${({ theme, tagTheme }) => theme.colors[tagTheme.border]};
  border-radius: 2px;
  color: ${({ theme, tagTheme }) => theme.colors[tagTheme.color]};
`;

const BroadcastArchivedTag = styled(Tag)`
  ${baseTagStyles};
  background: ${({ theme }) => theme.colors.white};
  border-color: ${({ theme }) => theme.colors.geyser};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.flint};
  margin-left: 3px;
`;

export const BroadcastStatusTag = ({ status }: { status: BroadcastStatus }) => {
  const { t } = useTranslation(['common', 'broadcast']);

  const { label, ...tagTheme } = useMemo(() => {
    // All cases handled for fixed values
    // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
    return match(status)
      .with('SCHEDULED', () => ({
        color: 'secondaryGoldDark',
        border: 'secondaryGold60',
        background: 'secondaryGold10',
        label: t('broadcast:pages.broadcast.broadcastStatus.scheduled'),
      }))
      .with('SENT', () => ({
        color: 'secondaryMintDark',
        border: 'secondaryMint60',
        background: 'secondaryMint10',
        label: t('broadcast:pages.broadcast.broadcastStatus.sent'),
      }))
      .with('CANCELED', () => ({
        color: 'secondaryCoralDark',
        border: 'secondaryCoral60',
        background: 'secondaryCoral10',
        label: t('broadcast:pages.broadcast.broadcastStatus.cancelled'),
      }))
      .with(P.union('IN_PROGRESS_PREPARING', 'IN_PROGRESS_SENDING'), () => ({
        color: 'secondaryTopazDark',
        border: 'secondaryTopaz60',
        background: 'secondaryTopaz10',
        label: t('broadcast:pages.broadcast.broadcastStatus.inProgress'),
      }))
      .with(P.union('DRAFT', 'PAUSED'), (type) => ({
        color: 'raven',
        border: 'casper',
        background: 'koala',
        label: t(
          type === 'DRAFT'
            ? 'broadcast:pages.broadcast.broadcastStatus.draft'
            : 'broadcast:pages.broadcast.broadcastStatus.paused',
        ),
      }))
      .exhaustive();
  }, [status, t]);

  return (
    <StyledBroadcastStatusTag tagTheme={tagTheme} data-lgg-id="broadcast-status-badge">
      {label}
    </StyledBroadcastStatusTag>
  );
};

const statusesWithConfirmationToArchive: BroadcastStatus[] = [
  'PAUSED',
  'SCHEDULED',
  'IN_PROGRESS_PREPARING',
  'IN_PROGRESS_SENDING',
];

export const BroadcastArchivedBadge = () => {
  const { t } = useTranslation(['common']);

  return (
    <BroadcastArchivedTag data-lgg-id="broadcast-archived-badge">
      {t('common:archived')}
    </BroadcastArchivedTag>
  );
};

type UseBroadcastMoreOptionsProps = {
  refetchHandler: VoidFunction;
  includeEditOption?: boolean;
};

export const useBroadcastMoreOptions = ({
  refetchHandler,
  includeEditOption = true,
}: UseBroadcastMoreOptionsProps) => {
  const showConfirmationModal = useShowConfirmationModal();
  const handleBroadcastError = useHandleBroadcastError();
  const history = useHistory();
  const { getEditBroadcastUrl } = useUrls();
  const { t } = useTranslation(['common', 'broadcast']);

  const [updateArchiveStatus] = useMutation<
    Pick<Mutation, 'updateBroadcastArchiveStatus'>,
    MutationUpdateBroadcastArchiveStatusArgs
  >(UPDATE_BROADCAST_ARCHIVED_STATUS_MUTATION);

  const [updateStatus] = useMutation<
    Pick<Mutation, 'updateBroadcastStatus'>,
    MutationUpdateBroadcastStatusArgs
  >(UPDATE_BROADCAST_STATUS_MUTATION);

  const updateStatusHandler = useCallback(
    async ({ id, status }: { id: string; status: UpdateBroadcastStatus }) => {
      await updateStatus({
        variables: {
          input: {
            id,
            status,
          },
        },
        onError: handleBroadcastError,
        onCompleted: refetchHandler,
      });
    },
    [handleBroadcastError, refetchHandler, updateStatus],
  );

  const getConfirmStatusLabel = useCallback(
    (status: BroadcastStatus) => {
      return match(status)
        .with('SCHEDULED', () => t('broadcast:pages.broadcast.broadcastStatus.scheduled'))
        .with('PAUSED', () => t('broadcast:pages.broadcast.broadcastStatus.paused'))
        .with(P.union('IN_PROGRESS_PREPARING', 'IN_PROGRESS_SENDING'), () =>
          t('broadcast:pages.broadcast.broadcastStatus.inProgress'),
        )
        .otherwise(() => '');
    },
    [t],
  );

  const archiveBroadcast = useCallback(
    async ({ status, id }: { status: BroadcastStatus; id: string }) => {
      const handleArchive = async () => {
        await updateArchiveStatus({
          variables: {
            input: {
              id,
              isArchived: true,
            },
          },
          onError: handleBroadcastError,
          onCompleted: refetchHandler,
        });
      };

      if (statusesWithConfirmationToArchive.includes(status)) {
        const statusLabel = getConfirmStatusLabel(status);

        showConfirmationModal({
          title: t(
            'broadcast:pages.broadcast.broadcastTable.archiveConfirmationModal.title',
            {
              statusLabel,
            },
          ),
          message: t(
            'broadcast:pages.broadcast.broadcastTable.archiveConfirmationModal.description',
            {
              statusLabel,
            },
          ),
          confirmButtonText: t(
            'broadcast:pages.broadcast.broadcastTable.archiveConfirmationModal.confirmText',
          ),
          confirmButtonType: 'delete',
          testId: 'archive-broadcast-confirm-message',
          onConfirm: async () => {
            await handleArchive();
          },
        });
      } else {
        await handleArchive();
      }
    },
    [
      getConfirmStatusLabel,
      handleBroadcastError,
      refetchHandler,
      showConfirmationModal,
      t,
      updateArchiveStatus,
    ],
  );

  const unarchiveBroadcast = useCallback(
    async (id: string) => {
      await updateArchiveStatus({
        variables: {
          input: {
            id,
            isArchived: false,
          },
        },
        onError: handleBroadcastError,
        onCompleted: refetchHandler,
      });
    },
    [handleBroadcastError, refetchHandler, updateArchiveStatus],
  );

  const getBroadcastMoreOptions = useCallback(
    ({
      status,
      id,
      isArchived,
    }: {
      status: BroadcastStatus;
      id: string;
      isArchived: boolean;
    }) => {
      const isInProgress = isBroadcastInProgress(status);
      const showCancelOption =
        !isArchived && (isInProgress || ['SCHEDULED', 'PAUSED'].includes(status));
      const showPauseOption = !isArchived && isInProgress;
      const showEditOption =
        includeEditOption && !isArchived && canBroadcastBeEdited(status);

      return [
        ...(showEditOption
          ? [
              {
                label: t('common:edit'),
                icon: 'edit',
                'data-lgg-id': 'broadcast-option-edit',
                onClick: async () => {
                  history.push(getEditBroadcastUrl(id));
                },
              },
            ]
          : []),
        ...(showCancelOption
          ? [
              {
                label: t('common:cancel'),
                icon: 'close',
                'data-lgg-id': 'broadcast-option-cancel',
                onClick: async () => {
                  await updateStatusHandler({
                    id,
                    status: 'CANCELED',
                  });
                },
              },
            ]
          : []),
        ...[
          isArchived
            ? {
                label: t('common:unarchive'),
                icon: 'unarchive',
                'data-lgg-id': 'broadcast-option-unarchive',
                onClick: async () => {
                  await unarchiveBroadcast(id);
                },
              }
            : {
                label: t('common:archive'),
                icon: 'archive',
                'data-lgg-id': 'broadcast-option-archive',
                onClick: async () => {
                  await archiveBroadcast({
                    id,
                    status,
                  });
                },
              },
        ],
        ...(status === 'PAUSED'
          ? [
              {
                label: t('common:resume'),
                icon: 'resumeBroadcast',
                'data-lgg-id': 'broadcast-option-resume',
                onClick: async () => {
                  await updateStatusHandler({
                    id,
                    status: 'UNPAUSED',
                  });
                },
              },
            ]
          : []),
        ...(showPauseOption
          ? [
              {
                label: t('common:pause'),
                icon: 'pauseBroadcast',
                'data-lgg-id': 'broadcast-option-pause',
                onClick: async () => {
                  await updateStatusHandler({
                    id,
                    status: 'PAUSED',
                  });
                },
              },
            ]
          : []),
      ];
    },
    [
      archiveBroadcast,
      getEditBroadcastUrl,
      history,
      includeEditOption,
      t,
      unarchiveBroadcast,
      updateStatusHandler,
    ],
  );

  return {
    getBroadcastMoreOptions,
  };
};

const BroadcastPreviewLineBreak = () => (
  <>
    <br />
    <br />
  </>
);

const getBroadcastMessagePreview = ({
  companyName,
  message,
  optOutMessage,
}: {
  companyName?: string | null;
  message?: string | null;
  optOutMessage?: string | null;
}) => {
  return message ? (
    <>
      {companyName ? (
        <>
          {companyName}
          <BroadcastPreviewLineBreak />
        </>
      ) : null}
      {message ? resolvePreviewWithContactPlaceholders({ message }) : null}
      {message && optOutMessage ? (
        <>
          <BroadcastPreviewLineBreak />
        </>
      ) : null}
      {optOutMessage ? optOutMessage : null}
    </>
  ) : undefined;
};

const MessagePreview = styled.div`
  background: ${({ theme }) => theme.colors.porcelain};
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  max-height: 300px;
  padding: 15px;
  white-space: pre-wrap;
`;

type BroadcastMessagePreviewProps = {
  companyName?: string | null;
  message?: string | null;
  optOutMessage?: string | null;
};

export const BroadcastMessagePreview = ({
  companyName,
  message,
  optOutMessage,
}: BroadcastMessagePreviewProps) => {
  const messagePreview = getBroadcastMessagePreview({
    companyName,
    message,
    optOutMessage,
  });

  return (
    <MessagePreview data-lgg-id="broadcast-message-preview">
      <Scrollbar>{messagePreview}</Scrollbar>
    </MessagePreview>
  );
};

export const useHandleBroadcastError = () => {
  const { t } = useTranslation(['broadcast']);
  const showNotification = useShowNotification();
  const handleGraphQLError = useHandleGraphQLError();

  const handleBroadcastError = (error: ApolloError) => {
    const [graphQlError] = error.graphQLErrors;

    match(graphQlError?.extensions?.code)
      .with('BUSINESS_RULE:NON_UPDATABLE_BROADCAST', () => {
        showNotification({
          type: 'error',
          title: t(
            'broadcast:pages.broadcastWizard.errorMessages.updatingInProgressBroadcast.title',
          ),
          message: t(
            'broadcast:pages.broadcastWizard.errorMessages.updatingInProgressBroadcast.message',
          ),
        });
      })
      .otherwise(() => {
        handleGraphQLError(error);
      });
  };

  return handleBroadcastError;
};

export const BroadcastInfoIcon = styled(Icon)`
  align-items: center;
  background: rgba(218, 224, 231, 1);
  border-radius: 50%;
  display: flex;
  height: 10px;
  justify-content: center;
  margin-left: 5px;
  width: 10px;
`;
