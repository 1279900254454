import React, { useCallback, useRef } from 'react';
import { useServiceSelector } from 'react-service-locator';
import { GroupedVirtuoso, GroupedVirtuosoHandle } from 'react-virtuoso';
import Collapse from 'antd/lib/collapse';
import c from 'classnames';
import styled from 'styled-components';
import { FiltersButton } from 'src/components/general/button/filters-button';
import { Icon } from 'src/components/general/icon';
import { FlexRow } from 'src/components/layout/flex-row';
import { CalendarItem } from 'src/components/pages/calendar/components/mobile/calendar-item';
import { CalendarListLoadingIndicator } from 'src/components/pages/calendar/components/mobile/calendar-list-loading-indicator';
import { useMobileCalendarView } from 'src/components/pages/calendar/components/mobile/hooks';
import { MobileCalendarViewContext } from 'src/components/pages/calendar/components/mobile/mobile-calendar-view-provider';
import { SwipableCalendar } from 'src/components/pages/calendar/components/mobile/swipable-calendar';
import { CalendarFilters } from 'src/components/pages/calendar/components/shared/calendar-filters';
import { useCalendarFilters } from 'src/components/pages/calendar/components/shared/hooks';
import { useFormatDate } from 'src/hooks/use-format-date';
import { useVisible } from 'src/hooks/use-visible';
import { CalendarService } from 'src/services/calendar.service';

const GroupHeader = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.porcelain};
  color: ${({ theme }) => theme.colors.flint};
  display: flex;
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  height: 34px;
  letter-spacing: 0.36px;
  line-height: normal;
  padding-left: 20px;
  text-align: left;
  text-transform: uppercase;
`;

const MainContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const TopContainer = styled(FlexRow)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.porcelain};
  padding: 13px 20px;

  .current-month {
    align-items: center;
    color: ${({ theme }) => theme.colors.smalt};
    cursor: pointer;
    display: flex;
    flex: 1;
    font-family: ${({ theme }) => theme.font.medium};
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.32px;
    line-height: 19px;
    text-align: left;
    text-transform: capitalize;

    span {
      margin-left: 10px;
      transform: rotate(0);
      transition: all 0.3s ease !important;

      path {
        fill: ${({ theme }) => theme.colors.flint};
      }
    }

    &.open {
      span {
        transform: rotate(180deg);
        transition: all 0.3s ease !important;
      }
    }
  }
`;

const StyledCollapse = styled(Collapse)`
  padding: 0;

  && .ant-collapse-item {
    border: none;
  }

  &&.ant-collapse .ant-collapse-header,
  &&.ant-collapse .ant-collapse-content-box {
    padding: 0;
  }

  .anticon.anticon-right.ant-collapse-arrow {
    display: none;
  }
`;

const CurrentDayButton = styled(Icon)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 #48484805;
  display: flex;
  height: 34px;
  justify-content: center;
  margin-left: 10px;
  width: 34px;
`;

const CalendarFiltersButton = styled(FiltersButton)`
  height: 34px;
  width: 34px;

  svg {
    height: 16px;
    width: 16px;
  }
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

const ListOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const MobileCalendarView = () => {
  const { formatDate } = useFormatDate();
  const filterVisibilityHandler = useVisible();
  const calendarVisibilityHandler = useVisible();
  const virtuosoHandleRef = useRef<GroupedVirtuosoHandle | null>(null);
  const virtuosoScrollerRef = useRef<HTMLElement | Window | null>(null);
  const [setSelectedDay] = useServiceSelector(
    CalendarService,
    (s) => [s.setSelectedDay] as const,
  );
  const { filters, hasActiveFilters } = useCalendarFilters();
  const isAutoScrolling = useRef(false);

  const {
    loading,
    firstItemIndex,
    handleLoadMoreDates,
    dates,
    groupCounts,
    events,
    today,
    groupIndexes,
    onEventDeleted,
    currentMonth,
    setCurrentMonth,
    initialTopMostItemIndex,
    initialFetch,
  } = useMobileCalendarView();

  const scrollToDay: (input: { date: string; offset?: number }) => void = useCallback(
    (input: { date: string; offset?: number }) => {
      const { date, offset = 0 } = input;
      const formattedMonth = formatDate(date, 'MM/DD/YY');
      const index = dates.findIndex((date) => date === formattedMonth);

      isAutoScrolling.current = true;
      virtuosoHandleRef.current?.scrollToIndex({ index, offset });

      setTimeout(() => {
        isAutoScrolling.current = false;
      }, 500);
    },
    [formatDate, dates],
  );

  const handleRangeForUpdatingDate = useCallback(
    (firstDay: string) => {
      const firstDate = new Date(firstDay);

      setSelectedDay(firstDate);
      setCurrentMonth(firstDate);
    },
    [setCurrentMonth, setSelectedDay],
  );

  return (
    <MobileCalendarViewContext.Provider
      value={{
        onEventDeleted,
        initialFetch,
        currentMonth,
        setCurrentMonth,
        events,
        scrollToDay,
      }}
    >
      <MainContainer>
        <StyledCollapse
          bordered={false}
          activeKey={calendarVisibilityHandler.visible ? '1' : '2'}
        >
          <Collapse.Panel
            header={
              <TopContainer>
                <span
                  className={c({
                    'current-month': true,
                    open: calendarVisibilityHandler.visible,
                  })}
                  onClick={() =>
                    calendarVisibilityHandler.setVisible(
                      !calendarVisibilityHandler.visible,
                    )
                  }
                >
                  {formatDate(currentMonth, 'MMMM YYYY')}
                  <Icon type="arrowdown" />
                </span>
                <CalendarFiltersButton
                  filtersVisibilityHandler={filterVisibilityHandler}
                  hasActiveFilters={hasActiveFilters}
                  data-lgg-id="calendar-page-filters-button"
                />
                <CurrentDayButton
                  type="scheduled"
                  onClick={() => {
                    scrollToDay({ date: today, offset: 25 });
                    setCurrentMonth(new Date(today));
                  }}
                />
                <CalendarFilters
                  visible={filterVisibilityHandler.visible}
                  onClose={filterVisibilityHandler.close}
                  filters={filters}
                />
              </TopContainer>
            }
            key="1"
          >
            <SwipableCalendar />
          </Collapse.Panel>
        </StyledCollapse>
        {loading ? (
          <CalendarListLoadingIndicator />
        ) : (
          <ListWrapper>
            <GroupedVirtuoso
              scrollerRef={(ref) => (virtuosoScrollerRef.current = ref)}
              ref={(ref) => (virtuosoHandleRef.current = ref)}
              initialTopMostItemIndex={{
                index: initialTopMostItemIndex,
                offset: 30,
              }}
              defaultItemHeight={57}
              startReached={() => {
                void handleLoadMoreDates('TOP');
              }}
              endReached={() => {
                void handleLoadMoreDates('BOTTOM');
              }}
              onScroll={(e) => {
                if (calendarVisibilityHandler.visible) {
                  return;
                }

                const list = e.target as HTMLDivElement;
                const item = (list.querySelector('.day-item') as HTMLDivElement | null)
                  ?.parentElement;

                if (!item || isAutoScrolling.current) {
                  return;
                }

                const { top, height } = item.getBoundingClientRect();

                const getDateFromElement = (element: HTMLElement | null) => {
                  return element?.dataset?.day ?? null;
                };

                const targetElement = (
                  top - height < 10 ? item.nextSibling?.firstChild : item?.firstChild
                ) as HTMLElement | null;

                const date = getDateFromElement(targetElement);

                if (!date) {
                  return;
                }

                handleRangeForUpdatingDate(date);
              }}
              firstItemIndex={firstItemIndex}
              groupCounts={groupCounts}
              groupContent={(index) => {
                const [month, year] = groupIndexes[index].split('/');

                return (
                  <GroupHeader>
                    {formatDate(new Date(`${month}/01/${year}`), 'MMMM YYYY')}
                  </GroupHeader>
                );
              }}
              computeItemKey={(index) => dates[index - firstItemIndex]}
              itemContent={(index) => {
                const date = dates[index - firstItemIndex];

                return (
                  <CalendarItem
                    key={date}
                    virtuosoScroller={virtuosoScrollerRef.current}
                    currentDateString={today}
                    dateString={date}
                    events={events[date]}
                  />
                );
              }}
              components={{
                // Disable sticky header of grouped virtuoso
                TopItemList: (props) => (
                  <div {...props} style={{ ...props.style, position: 'static' }} />
                ),
              }}
            />
            {calendarVisibilityHandler.visible && (
              <ListOverlay
                onClick={() => {
                  calendarVisibilityHandler.setVisible(false);
                }}
              />
            )}
          </ListWrapper>
        )}
      </MainContainer>
    </MobileCalendarViewContext.Provider>
  );
};
