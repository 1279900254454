import React, { memo } from 'react';
import styled from 'styled-components';
import { User } from '@lgg/isomorphic/types/__generated__/graphql';
import { Avatar } from 'src/components/general/display/avatar';

const AssigneeAvatar = styled(Avatar)<{ color: string }>`
  height: 28px;
  width: 28px;
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.52px;
  text-align: center;
  color: ${({ theme }) => theme.colors.geyser};
  border: solid 1px rgba(203, 212, 221, 0.6);
  background-color: ${({ color }) => color};
  cursor: pointer;
`;

type LggUserAvatarProps = {
  user: User;
};

export const LggUserAvatar = memo<LggUserAvatarProps>(({ user, ...rest }) => {
  return (
    <AssigneeAvatar color={user.avatar.color} src={user.avatar.url} {...rest}>
      {user.avatar.initials}
    </AssigneeAvatar>
  );
});
