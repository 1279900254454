import { match } from 'ts-pattern';

export const getBroadcastOptOutMessage = (language: string) => {
  return match(language)
    .with('EN', () => 'Reply STOP to unsubscribe. Msg&Data rates may apply.')
    .with(
      'ES',
      () =>
        'Responda STOP para darse de baja. Pueden aplicarse tarifas por mensajes y datos.',
    )
    .otherwise(() => '');
};
