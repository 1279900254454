import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import styled from 'styled-components';
import { User } from '@lgg/isomorphic/types/__generated__/graphql';
import { usePersonalInformationForm } from 'src/components/domain/users/components/profile-settings-modal/hooks/use-personal-information-form';
import { useUploadPhoto } from 'src/components/domain/users/components/profile-settings-modal/hooks/use-upload-photo';
import { BaseFormProps } from 'src/components/domain/users/components/profile-settings-modal/profile-settings-modal';
import { IconButtonV2 } from 'src/components/general/button/icon-button';
import { ButtonV2 } from 'src/components/general/button/lgg-button';
import { TextInput } from 'src/components/general/inputs/text-input';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 17px;
`;

const FormGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
`;

const AvatarUpload = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 3px;
`;

const AvatarCircle = styled.div<{ imageUrl?: string | null }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e9f6ef;
  border: 1px solid ${({ theme }) => theme.colors.koala};
  border-radius: 50%;
  width: 72px;
  height: 72px;
  color: ${({ theme }) => theme.colors.geyser};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 26px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1.04px;
  background-image: url(${(props) => props.imageUrl || ''});
  background-size: cover;
  background-position: center;
`;

const LoadingIconWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;

  button:nth-of-type(2) {
    span {
      margin-right: unset;
    }
  }
`;

type ProfileInformationFormProps = {
  currentUser: User;
} & BaseFormProps;

export const PersonalInformationForm: React.FC<ProfileInformationFormProps> = ({
  currentUser,
  formId,
}) => {
  const { form, onReset, onSubmit } = usePersonalInformationForm(currentUser);

  const {
    avatar: { initials, url },
    loading,
    uploadPhoto,
    removePhoto,
  } = useUploadPhoto(currentUser.avatar);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = form;

  const { t } = useTranslation(['user']);

  return (
    <StyledForm
      data-lgg-id="personal-information-form"
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
      onReset={onReset}
    >
      <AvatarUpload>
        <AvatarCircle data-lgg-id="avatar-circle" imageUrl={url}>
          {!url && !loading && initials}
          {loading && (
            <LoadingIconWrapper data-lgg-id="loading-icon-wrapper">
              <LoadingOutlined />
            </LoadingIconWrapper>
          )}
        </AvatarCircle>
        <ButtonGroup>
          <Upload name="avatar" showUploadList={false} customRequest={uploadPhoto}>
            <ButtonV2
              data-lgg-id="upload-photo-button"
              type="button"
              size="small"
              variant="defaultGhost"
              icon="upload"
            >
              {t(
                'user:profileSettings.sections.personalInformation.fields.photo.buttons.upload',
              )}
            </ButtonV2>
          </Upload>
          <IconButtonV2
            data-lgg-id="remove-photo-button"
            type="button"
            size="small"
            variant="defaultGhost"
            icon="deleteBin"
            onClick={removePhoto}
            disabled={!url}
          />
        </ButtonGroup>
      </AvatarUpload>
      <Controller
        name="firstName"
        control={control}
        render={({ field }) => (
          <TextInput
            data-lgg-id="field-firstName"
            label={t(
              'user:profileSettings.sections.personalInformation.fields.firstName.label',
            )}
            placeholder={t(
              'user:profileSettings.sections.personalInformation.fields.firstName.placeholder',
            )}
            error={errors.firstName?.message}
            reserveErrorArea={false}
            showDot={false}
            {...field}
          />
        )}
      />
      <Controller
        name="lastName"
        control={control}
        render={({ field }) => (
          <TextInput
            data-lgg-id="field-lastName"
            label={t(
              'user:profileSettings.sections.personalInformation.fields.lastName.label',
            )}
            placeholder={t(
              'user:profileSettings.sections.personalInformation.fields.lastName.placeholder',
            )}
            error={errors.lastName?.message}
            reserveErrorArea={false}
            showDot={false}
            {...field}
          />
        )}
      />
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <TextInput
            data-lgg-id="field-email"
            label={t(
              'user:profileSettings.sections.personalInformation.fields.email.label',
            )}
            placeholder={t(
              'user:profileSettings.sections.personalInformation.fields.email.placeholder',
            )}
            error={errors.email?.message}
            type="email"
            reserveErrorArea={false}
            showDot={false}
            {...field}
          />
        )}
      />
      <FormGroup>
        <Controller
          name="phone"
          control={control}
          render={({ field }) => (
            <TextInput
              data-lgg-id="field-phone"
              label={t(
                'user:profileSettings.sections.personalInformation.fields.phone.label',
              )}
              placeholder={t(
                'user:profileSettings.sections.personalInformation.fields.phone.placeholder',
              )}
              error={errors.phone?.message}
              reserveErrorArea={false}
              showDot={false}
              containerStyles={{
                flex: 1,
              }}
              {...field}
            />
          )}
        />
        <Controller
          name="phoneExt"
          control={control}
          render={({ field }) => (
            <TextInput
              data-lgg-id="field-phoneExt"
              label={t(
                'user:profileSettings.sections.personalInformation.fields.phoneExt.label',
              )}
              placeholder={t(
                'user:profileSettings.sections.personalInformation.fields.phoneExt.placeholder',
              )}
              error={errors.phoneExt?.message}
              reserveErrorArea={false}
              showDot={false}
              containerStyles={{
                maxWidth: '26.47%',
              }}
              {...field}
              value={field.value || ''}
            />
          )}
        />
      </FormGroup>
      <FormGroup>
        <Controller
          name="phone2"
          control={control}
          render={({ field }) => (
            <TextInput
              data-lgg-id="field-phone2"
              label={t(
                'user:profileSettings.sections.personalInformation.fields.alternativePhone.label',
              )}
              placeholder={t(
                'user:profileSettings.sections.personalInformation.fields.alternativePhone.placeholder',
              )}
              error={errors.phone2?.message}
              reserveErrorArea={false}
              showDot={false}
              containerStyles={{
                flex: 1,
              }}
              {...field}
            />
          )}
        />
        <Controller
          name="phone2Ext"
          control={control}
          render={({ field }) => (
            <TextInput
              data-lgg-id="field-phone2Ext"
              label={t(
                'user:profileSettings.sections.personalInformation.fields.alternativePhoneExt.label',
              )}
              placeholder={t(
                'user:profileSettings.sections.personalInformation.fields.alternativePhoneExt.placeholder',
              )}
              error={errors.phone2Ext?.message}
              reserveErrorArea={false}
              showDot={false}
              containerStyles={{
                maxWidth: '26.47%',
              }}
              {...field}
              value={field.value || ''}
            />
          )}
        />
      </FormGroup>
    </StyledForm>
  );
};
