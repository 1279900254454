import { useEffect, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export const useBlockNavigation = ({
  shouldBlock = false,
  onBlock,
}: {
  shouldBlock: boolean;
  onBlock: (setBlockNavigation: (value: boolean) => void, pathname: string) => void;
}) => {
  const { t } = useTranslation(['common']);
  const history = useHistory();
  const blockNavigationRef = useRef<boolean>(shouldBlock);

  const setBlockNavigation = useCallback((value: boolean) => {
    blockNavigationRef.current = value;
  }, []);

  useEffect(() => {
    const unblockNavigation = history.block(({ pathname }) => {
      if (blockNavigationRef.current) {
        if (onBlock) {
          onBlock(setBlockNavigation, pathname);
          return false;
        } else {
          return t('common:leavePageWithChangesConfirmMessage');
        }
      } else {
        unblockNavigation();
        history.push(pathname);
      }
    });

    return unblockNavigation;
  }, [history, onBlock, setBlockNavigation, t]);

  useEffect(() => {
    const beforeUnloadHandler = (event: BeforeUnloadEvent) => {
      if (blockNavigationRef.current) {
        event.preventDefault();
        return (event.returnValue = '');
      }
    };

    window.addEventListener('beforeunload', beforeUnloadHandler);

    return () => window.removeEventListener('beforeunload', beforeUnloadHandler);
  }, []);

  return {
    setBlockNavigation,
  };
};
