import { useMemo } from 'react';
import {
  ContactInteractionChannelSlug,
  ConversationOrderByInput,
  ConversationWhereInput,
  QueryConversationsArgs,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { CONVERSATIONS_QUERY_PAGE_SIZE } from 'src/components/pages/conversations/components/conversations/conversation-section/conversations-section';
import { useAuthorization } from 'src/hooks/use-authorization';
import { ConversationsFilters } from 'src/services/conversation.service';

export const useConversationQueryVariables = (options: {
  filters: ConversationsFilters;
  institutionId: number;
  userId: number;
}): Partial<QueryConversationsArgs> => {
  const { filters, institutionId, userId } = options;
  const { getFeatureFlag } = useAuthorization();

  return useMemo(() => {
    const where: ConversationWhereInput[] = [{ lastContactInteraction: { _ne: null } }];

    if (filters.keyword) {
      where.push({
        matchKeywords: {
          _all: filters.keyword,
        },
      });
    }

    if (filters.contactTagsIds.length) {
      where.push({
        contact: {
          tags: {
            id: { _in: filters.contactTagsIds },
          },
        },
      });
    }

    if (filters.contactStatusesIds.length) {
      where.push({
        contact: {
          status: {
            id: { _in: filters.contactStatusesIds },
          },
        },
      });
    } else if (filters.contactStagesIds.length) {
      where.push({
        contact: {
          stage: {
            id: { _in: filters.contactStagesIds },
          },
        },
      });
    }

    switch (filters.assigneeType) {
      case 'only_me':
        where.push({
          contact: {
            assignee: {
              id: { _in: [userId] },
            },
          },
        });
        break;
      case 'unassigned':
        where.push({
          contact: {
            assignee: { _eq: null },
          },
        });
        break;
      case 'custom':
        where.push({
          contact: {
            assignee: {
              id: { _in: filters.assignees },
            },
          },
        });
        break;
    }

    if (filters.channelsSlugs.length) {
      where.push({
        lastContactInteraction: {
          channel: {
            _or: filters.channelsSlugs.map((slug) => ({
              slug: { _eq: slug as ContactInteractionChannelSlug },
            })),
          },
        },
      });
    }

    switch (filters.conversationStatus) {
      case 'IS_MARKED':
        where.push({
          isMarked: { _eq: true },
        });
        break;
      case 'IS_SNOOZED':
        where.push({
          isSnoozed: { _eq: true },
        });
        break;
      case 'IS_REPLY_PENDING':
        where.push({
          isReplyPending: { _eq: true },
          isOpen: { _eq: true },
          isSnoozed: { _eq: false },
        });
        break;
      case 'IS_CLOSED':
        where.push({
          isOpen: { _eq: false },
          isSnoozed: { _eq: false },
        });
        break;
      case 'IS_OPEN':
        where.push({
          isOpen: { _eq: true },
          isSnoozed: { _eq: false },
        });
        break;
    }

    if (filters.departmentIds.length) {
      where.push({ department: { id: { _in: filters.departmentIds } } });
    }

    if (filters.contactCampaignsIds.length) {
      where.push({
        contact: { campaign: { id: { _in: filters.contactCampaignsIds } } },
      });
    }

    const orderByField: keyof ConversationOrderByInput = getFeatureFlag(
      'temp_lggdev-2414_conversations-sorted-by-relevant-update-at',
    )
      ? 'relevantUpdateAt'
      : 'lastContactInteractionAt';

    return {
      institutionId,
      where: { _and: where },
      first: CONVERSATIONS_QUERY_PAGE_SIZE,
      orderBy: { [orderByField]: 'DESC' },
    };
  }, [
    filters.keyword,
    filters.contactTagsIds,
    filters.contactStatusesIds,
    filters.contactStagesIds,
    filters.assigneeType,
    filters.channelsSlugs,
    filters.conversationStatus,
    filters.departmentIds,
    filters.contactCampaignsIds,
    filters.assignees,
    getFeatureFlag,
    institutionId,
    userId,
  ]);
};
