import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useServiceSelector } from 'react-service-locator';
import { useLazyQuery } from '@apollo/client';
import qs from 'qs';
import {
  Mutation,
  Query,
  QueryCalendarEventsArgs,
  Schedule,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { getFiltersCount } from 'src/components/filters/react-filters';
import { useShowConfirmationModal } from 'src/components/general/feedback/hooks/use-show-confirmation-modal';
import { CalendarFiltersFormValues } from 'src/components/pages/calendar/components/shared/calendar-filters';
import {
  CALENDAR_EVENTS_QUERY,
  TaskEvent,
} from 'src/components/pages/calendar/components/shared/shared';
import { useDeleteAppointment } from 'src/hooks/gql/use-delete-appointment';
import { useDeleteTask } from 'src/hooks/gql/use-delete-task';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { useDateHelpers } from 'src/hooks/use-date-helpers';
import { useFormatDate } from 'src/hooks/use-format-date';
import { useUrls } from 'src/hooks/use-urls';
import { CalendarService } from 'src/services/calendar.service';

export const useGetCalendarEvents = () => {
  return useLazyQuery<Pick<Query, 'calendarEvents'>, Partial<QueryCalendarEventsArgs>>(
    CALENDAR_EVENTS_QUERY,
  );
};

export const useCalendarFilters = () => {
  const { id: currentInstitutionId } = useCurrentInstitution();
  const location = useLocation();
  const filters = useMemo(() => {
    const parsedQs = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      parseArrays: true,
      plainObjects: true,
      comma: true,
    });

    return {
      eventType: parsedQs.eventType || [],
      owner: parsedQs.owner || [],
    } as CalendarFiltersFormValues;
  }, [location.search]);
  const { startOfDay, endOfDay } = useDateHelpers();

  const computeFiltersVariables = useCallback(
    ({
      start,
      end,
      filters,
      scheduleId,
      taskId,
    }: {
      start?: Date;
      end?: Date;
      scheduleId?: number;
      taskId?: number;
      filters: CalendarFiltersFormValues;
    }) => {
      const { eventType, owner } = filters;

      let where: Partial<QueryCalendarEventsArgs['where']> = {};

      if (start) {
        where = {
          ...where,
          eventDateStart: startOfDay(start),
        };
      }

      if (end) {
        where = {
          ...where,
          eventDateEnd: endOfDay(end),
        };
      }

      if (eventType?.length) {
        where = {
          ...where,
          eventType: eventType,
        };
      }

      if (owner?.length) {
        where = {
          ...where,
          userIds: owner.map((id) => Number(id)),
        };
      }

      if (scheduleId) {
        where = {
          ...where,
          scheduleId,
        };
      }

      if (taskId) {
        where = {
          ...where,
          taskId,
        };
      }

      return {
        institutionId: currentInstitutionId,
        where,
      };
    },
    [currentInstitutionId, endOfDay, startOfDay],
  );

  return {
    filters,
    hasActiveFilters: getFiltersCount(filters) > 0,
    computeFiltersVariables,
  };
};

export const useEventDateFormatter = () => {
  const { formatSimpleTimeRange, formatEventDate } = useFormatDate();

  return {
    task: (task: TaskEvent) =>
      `${formatEventDate(task.dueAt)} · ${formatSimpleTimeRange(task.dueAt)}`,
    appointment: (schedule: Schedule) =>
      `${formatEventDate(schedule.startAt)} · ${formatSimpleTimeRange(
        schedule.startAt,
        schedule.endAt,
      )}`,
  };
};

export const useHandleScheduleActions = () => {
  const { t } = useTranslation(['appointments', 'calendar']);
  const { getScheduleModalUrl } = useUrls();
  const history = useHistory();
  const showConfirmationModal = useShowConfirmationModal();
  const [deleteAppointment] = useDeleteAppointment();

  const handleEdit = useCallback(
    (schedule: Schedule) => {
      history.push(getScheduleModalUrl(schedule.id));
    },
    [getScheduleModalUrl, history],
  );

  const handleDelete = useCallback(
    (
      schedule: Schedule,
      onCompleted?: (data: Pick<Mutation, 'deleteSchedule'>) => void,
    ) => {
      showConfirmationModal({
        title: t('appointments:deleteModal.title'),
        message: t('appointments:deleteModal.body'),
        confirmButtonText: t('appointments:deleteModal.okButton'),
        confirmButtonType: 'delete',
        testId: 'notifications-delete-appointment-confirmation-modal',
        onConfirm: async () => {
          void deleteAppointment({
            variables: {
              input: {
                scheduleId: schedule.id,
                institutionId: schedule.company.id,
              },
            },
            onCompleted,
            update(cache) {
              const normalizedId = cache.identify({
                id: schedule.id,
                __typename: 'Schedule',
              });
              cache.evict({ id: normalizedId });
              cache.gc();
            },
          });
        },
      });
    },
    [deleteAppointment, showConfirmationModal, t],
  );

  return { handleDelete, handleEdit };
};

export const useHandleTaskActions = () => {
  const { t } = useTranslation(['tasks', 'calendar']);
  const { getTaskModalUrl } = useUrls();
  const history = useHistory();
  const showConfirmationModal = useShowConfirmationModal();
  const [deleteTask] = useDeleteTask();

  const handleEdit = useCallback(
    (task: TaskEvent) => {
      history.push(getTaskModalUrl(task.id));
    },
    [getTaskModalUrl, history],
  );

  const handleDelete = useCallback(
    (task: TaskEvent, onCompleted?: (data: Pick<Mutation, 'deleteTask'>) => void) => {
      showConfirmationModal({
        title: t('tasks:deleteModal.title'),
        message: t('tasks:deleteModal.body'),
        confirmButtonText: t('tasks:deleteModal.okButton'),
        confirmButtonType: 'delete',
        testId: 'notifications-delete-task-confirmation-modal',
        onConfirm: async () => {
          void deleteTask({
            variables: {
              input: {
                taskId: task.id,
                institutionId: task.company.id,
              },
            },
            onCompleted,
            update(cache) {
              const normalizedId = cache.identify({
                id: task.id,
                __typename: 'Task',
              });
              cache.evict({ id: normalizedId });
              cache.gc();
            },
          });
        },
      });
    },
    [deleteTask, showConfirmationModal, t],
  );

  return { handleDelete, handleEdit };
};

export const useCalendarSelectedDay = () => {
  const [setSelectedDay, clearSelectedDay] = useServiceSelector(
    CalendarService,
    (s) => [s.setSelectedDay, s.clearSelectedDay] as const,
  );

  useEffect(() => {
    setSelectedDay(new Date());

    return () => {
      clearSelectedDay();
    };
  }, [clearSelectedDay, setSelectedDay]);
};
