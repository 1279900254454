import { useCallback } from 'react';
import { gql, useQuery } from '@apollo/client';
import { isNumber } from 'lodash';
import { match } from 'ts-pattern';
import {
  ContactStage,
  ContactWhereInput,
  Query,
} from '@lgg/isomorphic/types/__generated__/graphql';
import {
  AdvancedDateFilterType,
  matchAdvancedDateInputTypeExhaustive,
  matchValidAdvancedDateInputKey,
} from '@lgg/isomorphic/utils/match-advanced-date-input';
import {
  BirthdayInputDateType,
  matchGroupedInputBirthdayTypeExhaustive,
  matchValidBirthdayInputTypeKey,
} from '@lgg/isomorphic/utils/match-birthday-input';
import { useFilterHelpers } from 'src/components/general/filter/use-filter-helpers';
import { BasePageQueryParams, TableSortData } from 'src/components/general/table-helpers';
import { ContactFiltersFormValues } from 'src/components/pages/contacts/components/contact-filters';
import {
  CONTACT_BLOCKING_FIELDS,
  CORE_PAGE_INFO_FIELDS,
} from 'src/components/providers/apollo-provider-provider';
import { useDateHelpers } from 'src/hooks/use-date-helpers';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';
import {
  LEGACY_DATE_PARAM_FORMAT,
  viewCodeQueryParamKey,
} from 'src/hooks/use-legacy-params-for-dashboard-query';
import { ensureArray } from 'src/utils/ensure-array';

export const CONTACTS = gql`
  ${CORE_PAGE_INFO_FIELDS}
  ${CONTACT_BLOCKING_FIELDS}
  query GetContacts(
    $institutionId: Int!
    $first: Int
    $after: String
    $last: Int
    $before: String
    $orderBy: ContactOrderByInput
    $where: ContactWhereInput
  ) {
    contacts(
      institutionId: $institutionId
      first: $first
      last: $last
      before: $before
      after: $after
      orderBy: $orderBy
      where: $where
    ) {
      totalCount
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        cursor
        node {
          id
          label
          primaryEmail
          ...ContactBlockingFieldsFragment
          primaryPhone {
            national
            e164
          }
          lastContactInteraction {
            id
            occurredAt
          }
          tags {
            id
            name
            isActive
          }
          interest
          assignee {
            id
            fullName
            avatar {
              url(transform: { width: 180, height: 180 })
              color
              initials
            }
            role {
              id
              name
            }
          }
          status {
            id
            name
          }
          stage {
            id
            name
            slug
          }
        }
      }
    }
  }
`;

export const CONTACTS_QUERY_PAGE_SIZE = 50;

export const sortInputResolver = (sortData: TableSortData) => {
  const { key, direction = 'DESC' } = sortData;

  switch (key) {
    case 'lastContactInteraction': {
      return {
        lastContactInteraction: {
          occurredAt: direction,
        },
      };
    }
    case 'assignee': {
      return {
        assignee: {
          firstName: direction,
        },
      };
    }
    case 'status': {
      return {
        stage: {
          id: direction,
        },
      };
    }
    default: {
      return {
        [key]: direction,
      };
    }
  }
};

export type ContactsPageAdvanceQueryParams = {
  interest?: string;
  city?: string;
  assigned_unassigned?: string;
  contact?: string;
  agent?: string;
  date_from?: string;
  date_to?: string;
  last_interaction?: number | 'NEVER';
  lead_stage?: string[];
  lead_status?: string[];
  campaign?: string;
  tag?: string[];
  source?: string;
  last_interaction_at?: {
    type: AdvancedDateFilterType;
    time_unit?: string;
    include_nulls?: string;
    from?: string;
    to?: string;
  };
  birthday?: {
    type?: BirthdayInputDateType;
    month?: string;
    day?: string;
  };
  blocked?: string;
};

export type ContactsPageQueryParams = BasePageQueryParams<
  'custom' | 'default',
  ContactsPageAdvanceQueryParams
>;

export const useViewCodeResolver = (
  viewCode: ContactsPageQueryParams['view-code'],
  contactStages: ContactStage[],
) => {
  return (rawQueryParams: ContactsPageQueryParams): ContactsPageQueryParams => {
    const viewCode = rawQueryParams[viewCodeQueryParamKey];

    if (viewCode === 'default') {
      const defaultContactStatusIds = contactStages.flatMap((stage) =>
        stage.statuses.filter((s) => s.winPercentage !== 0).map((s) => s.id),
      );

      return {
        ...rawQueryParams,
        q: {
          advanced: {
            lead_status: defaultContactStatusIds,
          },
        },
      };
    }

    return rawQueryParams;
  };
};

export const useConvertFilterParamsToWhereInput = () => {
  const { subHours, startOfDay, endOfDay, format, parseISO } = useDateHelpers();
  const { decodeFilterDate } = useFilterHelpers();

  return (
    rawParams: ContactFiltersFormValues | ContactsPageAdvanceQueryParams,
    decodeDates: boolean = false,
  ) => {
    if (!rawParams) {
      return undefined;
    }

    const parseStringDate = (dateString: string) => {
      return decodeDates ? decodeFilterDate(dateString) : parseISO(dateString);
    };

    const whereClauses: ContactWhereInput[] = [];

    if (rawParams.interest) {
      whereClauses.push({ interest: { _contains: rawParams.interest } });
    }

    if (rawParams.city) {
      whereClauses.push({ city: { _contains: rawParams.city } });
    }

    if (
      rawParams.assigned_unassigned &&
      isNumber(Number(rawParams.assigned_unassigned))
    ) {
      const assignedUnassignedValue = Number(rawParams.assigned_unassigned);

      if (assignedUnassignedValue === 1) {
        whereClauses.push({ assignee: { _eq: null } });
      } else if (assignedUnassignedValue === 0) {
        whereClauses.push({ assignee: { _ne: null } });
      }
    }

    if (rawParams.contact) {
      whereClauses.push({
        matchKeywords: {
          _all: rawParams.contact,
        },
      });
    }

    if (rawParams.date_from) {
      whereClauses.push({
        createdAt: { _gte: startOfDay(parseStringDate(rawParams.date_from)) },
      });
    }

    if (rawParams.date_to) {
      whereClauses.push({
        createdAt: { _lte: endOfDay(parseStringDate(rawParams.date_to)) },
      });
    }

    if (rawParams.agent) {
      whereClauses.push({
        assignee: { id: { _eq: parseInt(rawParams.agent.toString()) } },
      });
    }

    if (rawParams.last_interaction) {
      const lastInteractionFilterValue = rawParams.last_interaction;

      if (lastInteractionFilterValue === 'NEVER') {
        whereClauses.push({
          lastContactInteraction: {
            createdAt: {
              _eq: null,
            },
          },
        });
      } else {
        whereClauses.push({
          lastContactInteraction: {
            createdAt: {
              _lte: format(
                subHours(new Date(), lastInteractionFilterValue),
                LEGACY_DATE_PARAM_FORMAT,
              ),
            },
          },
        });
      }
    }

    if (rawParams.campaign) {
      whereClauses.push({
        campaign: { id: { _eq: parseInt(rawParams.campaign.toString()) } },
      });
    }

    if (rawParams.source) {
      whereClauses.push({
        source: { id: { _eq: parseInt(rawParams.source.toString()) } },
      });
    }

    if (rawParams.lead_stage) {
      whereClauses.push({
        stage: {
          id: {
            _in: ensureArray<string>(rawParams.lead_stage),
          },
        },
      });
    }

    if (rawParams.lead_status) {
      whereClauses.push({
        status: {
          id: {
            _in: ensureArray<string>(rawParams.lead_status),
          },
        },
      });
    }

    if (rawParams.tag) {
      whereClauses.push({
        tags: {
          id: {
            _in: ensureArray<string>(rawParams.tag).map((val) => parseInt(val)),
          },
        },
      });
    }

    if (rawParams.last_interaction_at?.type) {
      const {
        type: filterValue,
        from: fromDate,
        to: toDate,
        time_unit: timeUnit,
        include_nulls: includeNulls,
      } = rawParams.last_interaction_at;

      matchAdvancedDateInputTypeExhaustive(filterValue, {
        range: () => {
          if (fromDate && toDate) {
            whereClauses.push({
              lastContactInteraction: {
                createdAt: {
                  _isBetween: {
                    from: startOfDay(parseStringDate(fromDate)),
                    to: endOfDay(parseStringDate(toDate)),
                  },
                },
              },
            });
          }
        },
        exact: () => {
          if (fromDate) {
            whereClauses.push({
              lastContactInteraction: {
                createdAt: {
                  _isExactDate: parseStringDate(fromDate),
                },
              },
            });
          }
        },
        relativeWithUnitInTheLast: (value) => {
          if (timeUnit) {
            whereClauses.push({
              lastContactInteraction: {
                createdAt: {
                  [value]: Number(timeUnit),
                },
              },
            });
          }
        },
        relativeWithUnitOver: (value) => {
          const shouldIncludeNulls = Number(includeNulls);

          if (timeUnit) {
            whereClauses.push({
              lastContactInteraction: {
                createdAt: {
                  [value]: {
                    timeUnit: Number(timeUnit),
                    includeNulls: Boolean(shouldIncludeNulls),
                  },
                },
              },
            });
          }
        },
        relative: (value) => {
          whereClauses.push({
            lastContactInteraction: {
              createdAt: {
                [value]: true,
              },
            },
          });
        },
        empty: () => {},
      });
    }

    if (rawParams.birthday?.type) {
      const { type: filterValue, day: exactDay, month: exactMonth } = rawParams.birthday;

      match(filterValue)
        .with('_isExactDay', () => {
          if (exactDay && exactMonth) {
            whereClauses.push({
              birthdate: {
                _isExactDay: {
                  day: Number(exactDay),
                  month: Number(exactMonth),
                },
              },
            });
          }
        })
        .with('_isExactMonth', () => {
          if (exactMonth) {
            whereClauses.push({
              birthdate: {
                _isExactMonth: Number(exactMonth),
              },
            });
          }
        })
        .otherwise((type) => {
          whereClauses.push({
            birthdate: {
              [type]: true,
            },
          });
        });
    }

    if (rawParams.blocked && isNumber(Number(rawParams.blocked))) {
      const blockedValue = Number(rawParams.blocked);

      if (blockedValue === 1) {
        whereClauses.push({ isBlocked: { _eq: true } });
      } else if (blockedValue === 0) {
        whereClauses.push({ isBlocked: { _eq: false } });
      }
    }

    return whereClauses.length ? { _and: whereClauses } : undefined;
  };
};

export const useConvertWhereInputToFilterParams = () => {
  const { parseISO } = useDateHelpers();
  const { encodeStartFilterDate, encodeFilterEndDate } = useFilterHelpers();

  const convertWhereInputToFilterParams = useCallback(
    (criteria: ContactWhereInput): ContactFiltersFormValues | undefined => {
      const filters: ContactFiltersFormValues = {};

      if (!criteria) {
        return undefined;
      }

      if (criteria._and) {
        criteria._and.forEach((value) => {
          if (value?.createdAt?._gte) {
            filters.date_from = encodeStartFilterDate(
              parseISO(value.createdAt._gte),
              true,
            );
          }

          if (value?.createdAt?._lte) {
            filters.date_to = encodeFilterEndDate(parseISO(value.createdAt._lte), true);
          }

          if (value.matchKeywords?._all) {
            filters.contact = value.matchKeywords?._all;
          }

          if (value.stage?.id?._in) {
            filters.lead_stage = value.stage?.id?._in;
          }

          if (value.status?.id?._in) {
            filters.lead_status = value.status?.id?._in;
          }

          if (value.assignee?.id?._eq) {
            filters.agent = value.assignee?.id?._eq;
          }

          if (value.assignee?._ne === null) {
            filters.assigned_unassigned = 0;
          }

          if (value.assignee?._eq === null) {
            filters.assigned_unassigned = 1;
          }

          if (value?.interest?._contains) {
            filters.interest = value.interest._contains;
          }

          if (value?.tags?.id?._in) {
            filters.tag = value.tags.id._in.map((tagId) => tagId.toString());
          }

          if (value.lastContactInteraction?.createdAt) {
            const lastInteractionValues = value.lastContactInteraction.createdAt;

            const targetKey =
              Object.keys(value.lastContactInteraction.createdAt).find((key) =>
                Boolean(lastInteractionValues[key]),
              ) ?? null;

            matchValidAdvancedDateInputKey(targetKey, {
              valid: (key) => {
                matchAdvancedDateInputTypeExhaustive(key, {
                  range: (type) => {
                    const rangeData = value.lastContactInteraction?.createdAt?._isBetween;
                    const fromDate = rangeData?.from;
                    const toDate = rangeData?.to;

                    if (fromDate && toDate) {
                      filters.last_interaction_at = {
                        type,
                        from: encodeStartFilterDate(parseISO(fromDate), true),
                        to: encodeFilterEndDate(parseISO(toDate), true),
                      };
                    }
                  },
                  exact: (type) => {
                    const exactDateValue =
                      value.lastContactInteraction?.createdAt?._isExactDate;

                    if (exactDateValue) {
                      filters.last_interaction_at = {
                        type,
                        from: encodeStartFilterDate(parseISO(exactDateValue), true),
                      };
                    }
                  },
                  relativeWithUnitInTheLast: (filterKey) => {
                    const timeUnit =
                      value?.lastContactInteraction?.createdAt?.[filterKey];

                    if (timeUnit) {
                      filters.last_interaction_at = {
                        type: filterKey,
                        time_unit: timeUnit,
                      };
                    }
                  },
                  relativeWithUnitOver: (filterKey) => {
                    const timeUnit =
                      value?.lastContactInteraction?.createdAt?.[filterKey];

                    if (timeUnit) {
                      filters.last_interaction_at = {
                        type: filterKey,
                        time_unit: timeUnit.timeUnit,
                        include_nulls: timeUnit.includeNulls ? 1 : 0,
                      };
                    }
                  },
                  relative: (filterKey) => {
                    filters.last_interaction_at = {
                      type: filterKey,
                    };
                  },
                  empty: () => {},
                });
              },
              notValid: () => {},
            });
          }

          if (value.birthdate) {
            const birthdayValues = value.birthdate;

            const targetKey =
              Object.keys(value.birthdate).find((key) => Boolean(birthdayValues[key])) ??
              null;

            matchValidBirthdayInputTypeKey(targetKey, {
              valid: (birthdayValidKey) => {
                matchGroupedInputBirthdayTypeExhaustive(birthdayValidKey, {
                  relative: (type) => {
                    filters.birthday = {
                      type,
                    };
                  },
                  exactDay: (type) => {
                    const exactDateValues = birthdayValues._isExactDay;
                    const day = exactDateValues?.day;
                    const month = exactDateValues?.month;

                    if (day && month) {
                      filters.birthday = {
                        type,
                        day,
                        month,
                      };
                    }
                  },
                  exactMonth: (type) => {
                    const month = birthdayValues._isExactMonth;

                    if (month) {
                      filters.birthday = {
                        type,
                        month,
                      };
                    }
                  },
                });
              },
              notValid: () => {},
            });
          }

          if (value.campaign?.id?._eq) {
            filters.campaign = value.campaign.id._eq;
          }

          if (value.source?.id?._eq) {
            filters.source = value.source.id._eq;
          }

          if (value.city?._contains) {
            filters.city = value.city._contains;
          }

          if (value.isBlocked) {
            filters.blocked = value.isBlocked._eq ? 1 : 0;
          }
        });
      }

      return filters;
    },
    [encodeFilterEndDate, encodeStartFilterDate, parseISO],
  );

  return {
    convertWhereInputToFilterParams,
  };
};

export const useWhereInputResolver = () => {
  const convertFilterParamsToWhereInput = useConvertFilterParamsToWhereInput();

  return (params: ContactsPageQueryParams) => {
    if (!params.q?.advanced) {
      return undefined;
    }

    const { advanced } = params.q;

    return convertFilterParamsToWhereInput(advanced);
  };
};

export const filtersStateResolver = (
  queryParams: ContactsPageQueryParams,
): Partial<ContactFiltersFormValues> | undefined => {
  if (!queryParams.q?.advanced) {
    return undefined;
  }

  const { advanced } = queryParams.q;
  const values: Partial<ContactFiltersFormValues> = {};

  if (advanced.interest) {
    values.interest = advanced.interest;
  }

  if (advanced.city) {
    values.city = advanced.city;
  }

  if (advanced.assigned_unassigned) {
    values.assigned_unassigned = parseInt(advanced.assigned_unassigned);
  }

  if (advanced.agent) {
    values.agent = parseInt(advanced.agent);
  }

  if (advanced.date_from) {
    values.date_from = advanced.date_from;
  }

  if (advanced.date_to) {
    values.date_to = advanced.date_to;
  }

  if (advanced.contact) {
    values.contact = advanced.contact;
  }

  if (advanced.last_interaction) {
    values.last_interaction =
      advanced.last_interaction === 'NEVER'
        ? advanced.last_interaction
        : Number(advanced.last_interaction);
  }

  if (advanced.campaign) {
    values.campaign = parseInt(advanced.campaign);
  }

  if (advanced.source) {
    values.source = parseInt(advanced.source);
  }

  if (advanced.lead_stage) {
    values.lead_stage = [...advanced.lead_stage];
  }

  if (advanced.lead_status) {
    values.lead_status = [...advanced.lead_status];
  }

  if (advanced.tag) {
    values.tag = [...advanced.tag];
  }

  if (advanced.last_interaction_at) {
    values.last_interaction_at = {
      type: advanced.last_interaction_at.type,
    };

    if (advanced.last_interaction_at.time_unit) {
      values.last_interaction_at.time_unit = Number(
        advanced.last_interaction_at.time_unit,
      );
    }

    if (advanced.last_interaction_at.from) {
      values.last_interaction_at.from = advanced.last_interaction_at.from;
    }

    if (advanced.last_interaction_at.to) {
      values.last_interaction_at.to = advanced.last_interaction_at.to;
    }

    if (advanced.last_interaction_at.include_nulls) {
      values.last_interaction_at.include_nulls = parseInt(
        advanced.last_interaction_at.include_nulls,
      );
    }
  }

  if (advanced.birthday) {
    values.birthday = {
      type: advanced.birthday.type,
    };

    if (advanced.birthday.day) {
      values.birthday.day = Number(advanced.birthday.day);
    }

    if (advanced.birthday.month) {
      values.birthday.month = Number(advanced.birthday.month);
    }
  }

  if (advanced.blocked) {
    values.blocked = parseInt(advanced.blocked);
  }

  return values;
};

export const CONTACT_STAGES = gql`
  query GetContactStagesForContactPage {
    contactStages {
      id
      name
      slug
      statuses {
        id
        name
        winPercentage
      }
    }
  }
`;

export const useContactStages = () => {
  const handleGraphQLError = useHandleGraphQLError();

  return useQuery<Pick<Query, 'contactStages'>>(CONTACT_STAGES, {
    onError: handleGraphQLError,
  });
};
