import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import styled from 'styled-components';
import { match, P } from 'ts-pattern';
import { Broadcast, BroadcastStatus } from '@lgg/isomorphic/types/__generated__/graphql';
import { LggOptionsDropdownButtonWithCustomTriggerControlled } from 'src/components/general/button/dropdown-button';
import { Table, TableColumns, TableProps } from 'src/components/general/display/table';
import {
  getDefaultSortOrder,
  MoreOptionsIcon,
  TableSortData,
} from 'src/components/general/table-helpers';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { BroadcastDetailsModal } from 'src/components/pages/broadcast/components/broadcast-details-modal';
import { BroadcastSentBreakDownPopover } from 'src/components/pages/broadcast/components/broadcast-sent-breakdown-popover/broadcast-sent-breakdown-popover';
import {
  getBroadcastPercentageValue,
  getPercentageDisplayValue,
} from 'src/components/pages/broadcast/components/broadcast-wizard/utils';
import {
  BroadcastArchivedBadge,
  BroadcastStatusTag,
  useBroadcastMoreOptions,
} from 'src/components/pages/broadcast/components/shared';
import { useFormatDate } from 'src/hooks/use-format-date';

const BroadcastDescriptionContainer = styled(FlexColumn)`
  width: max-content;
`;

const BroadcastTitle = styled.p`
  color: ${({ theme }) => theme.colors.smalt};
  cursor: pointer;
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 13px;
  line-height: 16px;
  margin: 0;
  text-align: left;
`;

const TableBoldText = styled.span`
  color: ${({ theme }) => theme.colors.raven};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-transform: capitalize;
  text-align: right;

  &.success {
    color: ${({ theme }) => theme.colors.secondaryMintDark};
  }

  &.good {
    color: ${({ theme }) => theme.colors.secondaryGoldDark};
  }

  &.bad {
    color: ${({ theme }) => theme.colors.secondaryCoralDark};
  }
`;

const TableLightText = styled.span`
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: ${({ theme }) => theme.colors.flint};
  text-align: right;
`;

const RowInfoContainer = styled(FlexColumn)`
  ${TableBoldText} {
    margin-bottom: 4px;
  }
`;

const ColumnHeaderText = styled.span`
  -ms-user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
  user-select: none;
  display: block;
  text-align: right;
`;

const shouldShowBroadcastDetail = (status: BroadcastStatus) => {
  return match(status)
    .with(
      P.union(
        'IN_PROGRESS_PREPARING',
        'IN_PROGRESS_SENDING',
        'CANCELED',
        'SENT',
        'PAUSED',
      ),
      () => true,
    )
    .otherwise(() => false);
};

const emptyPlaceholder = <TableLightText>–</TableLightText>;

const getPercentageLabel = (
  total: number,
  value: number,
  addValueColor: boolean = false,
) => {
  const percentage = getBroadcastPercentageValue(total, value);

  const className = addValueColor
    ? match(percentage)
        .when(
          (percentage) => percentage === 0,
          () => undefined,
        )
        .when(
          (percentage) => percentage < 3,
          () => 'success',
        )
        .when(
          (percentage) => percentage < 5,
          () => 'good',
        )
        .when(
          (percentage) => percentage >= 5,
          () => 'bad',
        )
        .otherwise(() => undefined)
    : undefined;

  return (
    <TableBoldText
      className={className ? c({ [className]: true }) : ''}
      data-lgg-id="percentage-value"
    >
      {getPercentageDisplayValue(percentage)}
    </TableBoldText>
  );
};

const SentInfoContainer = styled(FlexRow)`
  align-items: center;
  justify-content: flex-end;
`;

const StyledTable = styled(Table)`
  .ant-table-thead {
    tr {
      th:not(:first-child) {
        .ant-table-column-sorters {
          display: flex;
          justify-content: right;
        }
      }
    }
  }

  .controlled-width-container {
    text-align: right;
  }
` as typeof Table;

type BroadcastTableProps = {
  data: Broadcast[];
  sortData: TableSortData;
  onChange: TableProps<Broadcast>['onChange'];
  refetchHandler: VoidFunction;
};

export const BroadcastTable = memo<BroadcastTableProps>(
  ({
    data,
    sortData = { key: undefined, direction: undefined },
    onChange,
    refetchHandler,
  }) => {
    const { t } = useTranslation(['common', 'broadcast']);
    const { key: sortKey, direction: sortDirection } = sortData;
    const { formatSimpleRelativeDate, formatSimpleTime } = useFormatDate();
    const [selectedBroadcastId, setSelectedBroadcastId] = useState<string | null>(null);
    const { getBroadcastMoreOptions } = useBroadcastMoreOptions({ refetchHandler });

    const columns: TableColumns<Broadcast>[] = [
      {
        title: (
          <ColumnHeaderText data-lgg-id="broadcast-table-column-header-broadcast">
            {t('broadcast:pages.broadcast.broadcastTable.columnHeaders.broadcast')}
          </ColumnHeaderText>
        ),
        key: 'name',
        wrapContent: true,
        showSorterTooltip: false,
        sorter: true,
        defaultSortOrder: getDefaultSortOrder(sortKey === 'name', sortDirection),
        render: (_, broadcast: Broadcast) => {
          const { name, status, isArchived, id } = broadcast;

          return (
            <BroadcastDescriptionContainer data-lgg-id="broadcast-table-cell-name-and-status">
              <BroadcastTitle
                onClick={() => {
                  setSelectedBroadcastId(id);
                }}
                data-lgg-id="broadcast-name"
              >
                {name}
              </BroadcastTitle>
              <FlexRow>
                <BroadcastStatusTag status={status} />
                {isArchived ? <BroadcastArchivedBadge /> : null}
              </FlexRow>
            </BroadcastDescriptionContainer>
          );
        },
      },
      {
        title: (
          <ColumnHeaderText data-lgg-id="broadcast-table-column-header-start-date">
            {t('broadcast:pages.broadcast.broadcastTable.columnHeaders.startDate')}
          </ColumnHeaderText>
        ),
        key: 'startedAt',
        dataIndex: 'startedAt',
        width: '122px',
        showSorterTooltip: false,
        sorter: true,
        defaultSortOrder: getDefaultSortOrder(sortKey === 'startedAt', sortDirection),
        render: (startedAt: Date | null) => {
          return (
            <RowInfoContainer data-lgg-id="broadcast-table-cell-started-at">
              {startedAt ? (
                <>
                  <TableBoldText>{formatSimpleRelativeDate(startedAt)}</TableBoldText>
                  <TableLightText>{formatSimpleTime(startedAt)}</TableLightText>
                </>
              ) : (
                <></>
              )}
            </RowInfoContainer>
          );
        },
      },
      {
        title: (
          <ColumnHeaderText data-lgg-id="broadcast-table-column-header-audience">
            {t('broadcast:pages.broadcast.broadcastTable.columnHeaders.audience')}
          </ColumnHeaderText>
        ),
        key: 'audience',
        dataIndex: 'audience',
        width: '121px',
        showSorterTooltip: false,
        sorter: true,
        defaultSortOrder: getDefaultSortOrder(sortKey === 'audience', sortDirection),
        render: (audience: number) => (
          <TableLightText data-lgg-id="broadcast-table-cell-audience">
            {audience ? audience.toLocaleString() : 0}
          </TableLightText>
        ),
      },
      {
        title: (
          <ColumnHeaderText data-lgg-id="broadcast-table-column-header-sent">
            {t('broadcast:pages.broadcast.broadcastTable.columnHeaders.sent')}
          </ColumnHeaderText>
        ),
        key: 'sent',
        dataIndex: 'sent',
        width: '81px',
        showSorterTooltip: false,
        render: (value: number, { status, discardedContacts, sent, audience }) => {
          return shouldShowBroadcastDetail(status) ? (
            <SentInfoContainer>
              <TableLightText data-lgg-id="broadcast-table-cell-sent">
                {value}
              </TableLightText>
              {discardedContacts && (
                <BroadcastSentBreakDownPopover
                  iconSize="small"
                  popoverPlacement="right"
                  discardedContacts={discardedContacts}
                  audience={audience ?? 0}
                  sent={sent ?? 0}
                />
              )}
            </SentInfoContainer>
          ) : (
            emptyPlaceholder
          );
        },
      },
      {
        title: (
          <ColumnHeaderText data-lgg-id="broadcast-table-column-header-replies">
            {t('broadcast:pages.broadcast.broadcastTable.columnHeaders.replies')}
          </ColumnHeaderText>
        ),
        key: 'replies',
        dataIndex: 'replies',
        width: '112px',
        showSorterTooltip: false,
        render: (value: number, { status, sent }) => {
          return shouldShowBroadcastDetail(status) ? (
            <RowInfoContainer data-lgg-id="broadcast-table-cell-replies">
              {getPercentageLabel(sent, value)}
              <TableLightText data-lgg-id="numeric-value">{value}</TableLightText>
            </RowInfoContainer>
          ) : (
            emptyPlaceholder
          );
        },
      },
      {
        title: (
          <ColumnHeaderText data-lgg-id="broadcast-table-column-header-opted-out">
            {t('broadcast:pages.broadcast.broadcastTable.columnHeaders.optedOut')}
          </ColumnHeaderText>
        ),
        key: 'optOuts',
        dataIndex: 'optOuts',
        width: '136px',
        showSorterTooltip: false,
        render: (optOuts: number, { status, sent }) => {
          return shouldShowBroadcastDetail(status) ? (
            <RowInfoContainer data-lgg-id="broadcast-table-cell-opt-outs">
              {getPercentageLabel(sent, optOuts, true)}
              <TableLightText data-lgg-id="numeric-value">{optOuts}</TableLightText>
            </RowInfoContainer>
          ) : (
            emptyPlaceholder
          );
        },
      },
      {
        key: 'more-options',
        showSorterTooltip: false,
        width: '62px',
        wrapContent: true,
        render: (_, { status, id, isArchived }) => {
          return (
            <LggOptionsDropdownButtonWithCustomTriggerControlled
              customTrigger={
                <MoreOptionsIcon type="moreOptions" lggTestId="table-cell-more-options" />
              }
              customDropdownProps={{
                getPopupContainer: (trigger) => trigger,
              }}
              options={getBroadcastMoreOptions({
                status,
                id,
                isArchived,
              })}
            />
          );
        },
      },
    ];

    return (
      <>
        <StyledTable
          rowKey={({ id }) => id}
          data-lgg-id="broadcast-table"
          columns={columns}
          tableLayout="fixed"
          loading={false}
          dataSource={data}
          pagination={false}
          showHeader={true}
          scroll={{ x: 1069 }}
          onChange={onChange}
          rowClassName={(record, index) =>
            `broadcast-table-row broadcast-row-${record.id} broadcast-row-position-${
              index + 1
            }`
          }
        />
        {selectedBroadcastId && (
          <BroadcastDetailsModal
            broadcastId={selectedBroadcastId}
            onClose={() => setSelectedBroadcastId(null)}
            refetchHandler={refetchHandler}
          />
        )}
      </>
    );
  },
);
