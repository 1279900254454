import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useServiceSelector } from 'react-service-locator';
import { Carousel } from 'antd';
import datePickerLocaleEn from 'antd/es/date-picker/locale/en_US';
import datePickerLocaleEs from 'antd/es/date-picker/locale/es_ES';
import { CarouselRef } from 'antd/lib/carousel';
import c from 'classnames';
import { range } from 'lodash';
import styled from 'styled-components';
import { match } from 'ts-pattern';
import { dayjs } from '@lgg/isomorphic/dayjs';
import { Schedule } from '@lgg/isomorphic/types/__generated__/graphql';
import Calendar from 'src/components/general/calendar/calendar';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { MobileCalendarViewContext } from 'src/components/pages/calendar/components/mobile/mobile-calendar-view-provider';
import { TaskEvent } from 'src/components/pages/calendar/components/shared/shared';
import { useDateHelpers } from 'src/hooks/use-date-helpers';
import { useFormatDate } from 'src/hooks/use-format-date';
import { CalendarService } from 'src/services/calendar.service';

const StyledCarousel = styled(Carousel)`
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 20px 40px 0 #5b657033;
  z-index: 2;

  &.visible {
    height: auto;
  }
`;

const StyledCalendar = styled(Calendar)`
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  .ant-picker-panel {
    border-radius: inherit;
  }

  .ant-picker-content > thead tr {
    text-transform: capitalize;
  }

  .ant-picker-panel .ant-picker-body {
    padding: 0;
  }

  .day-cell {
    border-radius: 50%;
    color: ${({ theme }) => theme.colors.casper};
    color: ${({ theme }) => theme.colors.casper};
    font-family: ${({ theme }) => theme.font.regular};
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    height: 32px;
    letter-spacing: -0.15px;
    line-height: 18px;
    margin: 0 auto 0;
    padding: 7px 0 0;
    text-align: center;
    width: 32px;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  }

  .active-day {
    color: ${({ theme }) => theme.colors.smalt};
  }

  .today {
    background-color: ${({ theme }) => theme.colors.gogo};
    color: ${({ theme }) => theme.colors.white};
  }

  .selected-day {
    background-color: ${({ theme }) => theme.colors.gogo + 40};
    color: ${({ theme }) => theme.colors.smalt};
  }

  .dots-container {
    align-items: center;
    justify-content: center;

    div {
      border-radius: 50%;
      height: 4px;
      width: 4px;
    }

    div + div {
      margin-left: 1px;
    }

    .task-dot {
      background-color: ${({ theme }) => theme.colors.secondaryMint80};
    }

    .appointment-dot {
      background-color: ${({ theme }) => theme.colors.secondaryPeriwinkle80};
    }
  }

  thead tr {
    border-bottom: 1px solid ${({ theme }) => theme.colors.koala};
    height: 37px;
  }

  thead tr th {
    color: ${({ theme }) => theme.colors.carbon};
    font-family: ${({ theme }) => theme.font.medium};
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.14px;
    line-height: normal;
    text-align: center;
  }
`;

const DayCell = (props: {
  date: Date;
  events: (Schedule | TaskEvent)[];
  selectedDay: Date | null;
  currentMonth: Date;
  onMonthChanged: (direction: string) => void;
  carouselRef: CarouselRef | null;
  setSelectedDay: (date: Date) => void;
}) => {
  const {
    date,
    selectedDay,
    setSelectedDay,
    events,
    currentMonth,
    carouselRef,
    onMonthChanged,
  } = props;
  const { formatDate } = useFormatDate();
  const dayNumber = formatDate(date, 'D');
  const ref = useRef<HTMLDivElement>(null);
  const { isSameDay, isSameMonth } = useDateHelpers();
  const isPreviousMonthDay = date.getMonth() < currentMonth.getMonth();
  const isNextMonthDay = date.getMonth() > currentMonth.getMonth();
  const isCurrentMontDay = isSameMonth(date, currentMonth);
  const isToday = isSameDay(date, new Date());
  const { initialFetch } = useContext(MobileCalendarViewContext);
  const hasTask = events.find((event) => event.__typename === 'Task');
  const hasAppointment = events.find((event) => event.__typename === 'Schedule');

  useEffect(() => {
    const onChanged = () => {
      setSelectedDay(date);

      if (isPreviousMonthDay) {
        onMonthChanged('right');
        void initialFetch({ initialDate: date });
      } else if (isNextMonthDay) {
        onMonthChanged('left');
        void initialFetch({ initialDate: date });
      }
    };

    const parent = ref.current?.parentElement;

    if (parent) {
      parent.addEventListener('click', onChanged);

      return () => parent.removeEventListener('click', onChanged);
    }
  }, [
    carouselRef,
    date,
    isNextMonthDay,
    isPreviousMonthDay,
    initialFetch,
    onMonthChanged,
    setSelectedDay,
  ]);

  return (
    <FlexColumn
      ref={ref}
      className={c({
        'day-cell': true,
        today: isToday,
        'selected-day': !isToday && selectedDay && isSameDay(date, selectedDay),
        'active-day': isCurrentMontDay,
      })}
    >
      {dayNumber}
      <FlexRow className="dots-container">
        {hasTask && <div className="task-dot" />}
        {hasAppointment && <div className="appointment-dot" />}
      </FlexRow>
    </FlexColumn>
  );
};

export const SwipableCalendar = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const { formatDate } = useFormatDate();
  const { currentMonth, setCurrentMonth, initialFetch, events, scrollToDay } = useContext(
    MobileCalendarViewContext,
  );
  const carouselRef = useRef<CarouselRef | null>(null);
  const [selectedDay, setSelectedDay] = useServiceSelector(
    CalendarService,
    (s) => [s.state.selectedDay, s.setSelectedDay] as const,
  );
  const { addMonths, startOfMonth, subMonths } = useDateHelpers();

  const onMonthChanged = useCallback(
    (direction: string) => {
      const newMonth = match(direction)
        .with('right', () => subMonths(currentMonth, 1))
        .otherwise(() => addMonths(currentMonth, 1));

      setCurrentMonth(newMonth);

      return newMonth;
    },
    [addMonths, currentMonth, setCurrentMonth, subMonths],
  );

  const datePickerLocale = useMemo(() => {
    if (language.startsWith('es')) {
      return datePickerLocaleEs;
    }

    return datePickerLocaleEn;
  }, [language]);

  return (
    <StyledCarousel
      key={formatDate(currentMonth, 'MMMM YYYY')}
      dots={false}
      ref={(ref) => (carouselRef.current = ref)}
      initialSlide={1}
      onSwipe={async (direction) => {
        const newMonth = onMonthChanged(direction);
        const startOfMonthDate = startOfMonth(newMonth);
        setSelectedDay(startOfMonthDate);
        await initialFetch({ initialDate: startOfMonthDate });
      }}
    >
      {range(3).map((index) => {
        const resolveMonth = () => {
          const prevMonth = subMonths(currentMonth, 1);
          const nextMonth = addMonths(currentMonth, 1);

          if (index === 0) {
            return prevMonth;
          } else if (index === 1) {
            return currentMonth;
          } else {
            return nextMonth;
          }
        };

        const month = resolveMonth();

        return (
          <StyledCalendar
            key={formatDate(month, 'MMMM YYYY')}
            value={dayjs(month)}
            fullscreen={false}
            locale={datePickerLocale}
            dateFullCellRender={(date) => {
              const formattedDate = formatDate(date.toDate(), 'MM/DD/YY');

              return (
                <DayCell
                  currentMonth={month}
                  events={events[formattedDate] ?? []}
                  date={date.toDate()}
                  onMonthChanged={(direction) => onMonthChanged(direction)}
                  carouselRef={carouselRef.current}
                  selectedDay={selectedDay}
                  setSelectedDay={(date) => {
                    setSelectedDay(date);
                    scrollToDay({
                      date: formattedDate,
                      offset: 40,
                    });
                  }}
                />
              );
            }}
            headerRender={() => null}
          />
        );
      })}
    </StyledCarousel>
  );
};
