import React, { memo, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import Spin from 'antd/lib/spin';
import styled from 'styled-components';
import { Center } from 'src/components/layout/center';
import { Expand } from 'src/components/layout/expand';

const StyledExpand = styled(Expand)`
  height: 100%;
`;

export const LoadingIndicator = memo(({ ...rest }) => {
  const { t } = useTranslation(['common']);

  return (
    <StyledExpand {...rest}>
      <Center>
        <Spin tip={`${t('common:loading')}...`} />
      </Center>
    </StyledExpand>
  );
});

export const LoadingFallback = memo(({ children }) => {
  return <Suspense fallback={<LoadingIndicator />}>{children}</Suspense>;
});
