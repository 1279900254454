import React, { memo, useCallback, useLayoutEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Filters } from 'src/components/filters';
import { useLegacyFiltersFromUrl } from 'src/components/pages/legacy/hooks/use-legacy-filters-from-url';

const LegacyFiltersContent = memo<{ content: HTMLDivElement }>(({ content }) => {
  const contentRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const targetEl = contentRef.current;
    if (targetEl) {
      content.style.display = 'block';
      targetEl.appendChild(content);
      YAMMON.WidgetManager.reload(); //Reload yammon widgets javascripts
    }
    return () => {
      if (targetEl) {
        targetEl.removeChild(content);
      }
    };
  }, [content]);

  return <div ref={contentRef} />;
});

const REPORT_PAGE_FILTER_KEY = 'report_page';

export const LegacyFilters = memo<{ content: HTMLDivElement }>(({ content }) => {
  const filters = useLegacyFiltersFromUrl();
  const history = useHistory();

  const onSave = useCallback(() => {
    const form = content.getElementsByTagName('form')[0];

    if (form) {
      const submitHandler = (e) => {
        e.preventDefault();

        const formData = new FormData(form);
        const formValues: string[][] = [];

        Object.entries(Object.fromEntries(formData)).forEach(([key]) => {
          const allValues = formData.getAll(key);

          allValues.forEach((value) => {
            formValues.push([key, value.toString()]);
          });
        });

        const formTargetUrl = form.getAttribute('action');

        if (formTargetUrl) {
          const parsedUrl = new URL(formTargetUrl);
          const searchParams = new URLSearchParams(formValues);

          searchParams.delete(REPORT_PAGE_FILTER_KEY);

          const newUrl = `${parsedUrl.pathname}?${decodeURI(searchParams.toString())}`;

          history.push(newUrl);
        }
      };

      form.removeEventListener('submit', submitHandler);
      form.addEventListener('submit', submitHandler);
    }

    form.requestSubmit();
  }, [content, history]);

  const onReset = useCallback(() => {
    const clearAnchor = content.getElementsByClassName('search-clear-button')[0];
    const url = clearAnchor?.getAttribute('href');

    if (url) {
      const parsedUrl = new URL(url, new URL(window.location.href).origin);
      const urlParams = new URLSearchParams(parsedUrl.search);

      urlParams.delete(REPORT_PAGE_FILTER_KEY);

      const path = `${parsedUrl.pathname}?${urlParams.toString()}`;
      history.push(path);
    }
  }, [content, history]);

  return (
    <Filters filters={filters} onSave={onSave} onReset={onReset}>
      <LegacyFiltersContent content={content} />
    </Filters>
  );
});
