import React, { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { match } from 'ts-pattern';
import {
  Query,
  QueryConversationsArgs,
  Conversation,
  Mutation,
  ConversationBulkSetClosedInput,
  ConversationBulkSetMarkedInput,
  ConversationBulkSetSnoozedInput,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { useShowConfirmationModal } from 'src/components/general/feedback/hooks/use-show-confirmation-modal';
import { useShowNotification } from 'src/components/general/feedback/hooks/use-show-notification';
import { Skeleton } from 'src/components/general/feedback/skeleton';
import { ConversationModal } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-modal';
import { useConversationQueryVariables } from 'src/components/pages/conversations/components/conversations/conversation-section/use-conversation-query-variables';
import { ConversationsList } from 'src/components/pages/conversations/components/conversations/conversations-list';
import { sortConversations } from 'src/components/pages/conversations/components/conversations/helpers/sort-conversations';
import { FiltersContainer } from 'src/components/pages/conversations/components/filters/filters-container';
import { useConversation } from 'src/components/pages/conversations/hooks/use-conversation';
import {
  CONTACT_INTERACTION_PARTICIPATING_ENTITY_CORE_FIELDS,
  CORE_ATTACHMENTS_FIELDS_WITH_REACTIONS_AND_RELATED,
  CORE_CHAT_MESSAGES_FIELDS_WITH_REACTIONS_AND_RELATED,
} from 'src/components/providers/apollo-provider-provider';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { useCurrentUserId } from 'src/hooks/use-current-user';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';
import { useInfiniteListQuery } from 'src/hooks/use-infinite-list-query';
import { useInstitutionUrl } from 'src/hooks/use-institution-url';

const CONVERSATIONS_TOTAL_COUNT = gql`
  query GetConversationsTotalCount($institutionId: Int!, $where: ConversationWhereInput) {
    conversations(institutionId: $institutionId, where: $where) {
      totalCount
    }
  }
`;

const CONVERSATIONS = gql`
  ${CORE_ATTACHMENTS_FIELDS_WITH_REACTIONS_AND_RELATED}
  ${CORE_CHAT_MESSAGES_FIELDS_WITH_REACTIONS_AND_RELATED}
  ${CONTACT_INTERACTION_PARTICIPATING_ENTITY_CORE_FIELDS}
  query GetConversations(
    $institutionId: Int!
    $where: ConversationWhereInput
    $first: Int
    $after: String
    $before: String
    $last: Int
    $orderBy: ConversationOrderByInput
  ) {
    conversations(
      institutionId: $institutionId
      where: $where
      first: $first
      after: $after
      before: $before
      last: $last
      orderBy: $orderBy
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        nextCursor
      }
      edges {
        cursor
        node {
          id
          isMarked
          isOpen
          isReplyPending
          isSnoozed
          updatedAt
          relevantUpdateAt
          contact {
            id
            label
            stage {
              id
              name
              slug
            }
            status {
              id
              name
            }
            assignee {
              id
            }
          }
          lastContactInteraction {
            ... on ContactInteraction {
              id
              description
              isMasked
              participatingEntity {
                ...ContactInteractionParticipatingEntityFragment
              }
              registrationType
              occurredAt
            }
            ... on ContactInteractionWithDirection {
              direction
            }
            ... on ContactInteractionPhoneCall {
              dialStatus
            }
            ... on ContactInteractionChatMessage {
              ...ContactInteractionChatMessagesFragmentWithReactionsAndRelated
            }
            ... on ContactInteractionWithAttachments {
              ...ContactInteractionAttachmentsFragmentWithReactionsAndRelated
            }
            ... on ContactInteractionWhatsapp {
              template {
                id
                text
              }
            }
            ... on ContactInteractionPhoneCall {
              callDetail {
                isInProgress
              }
            }
          }
        }
      }
    }
  }
`;

const CONVERSATION_BULK_SET_CLOSED_MUTATION = gql`
  mutation ConversationsBulkSetClosed($input: ConversationBulkSetClosedInput!) {
    conversationBulkSetClosed(input: $input) {
      success
    }
  }
`;

const CONVERSATION_BULK_SET_MARKED_MUTATION = gql`
  mutation ConversationsBulkSetMarked($input: ConversationBulkSetMarkedInput!) {
    conversationBulkSetMarked(input: $input) {
      success
    }
  }
`;

const CONVERSATION_BULK_SET_SNOOZED_MUTATION = gql`
  mutation ConversationsBulkSetSnoozed($input: ConversationBulkSetSnoozedInput!) {
    conversationBulkSetSnoozed(input: $input) {
      success
    }
  }
`;

const FilterSkeleton = styled(Skeleton)`
  padding: 22px 30px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.koala};

  .ant-skeleton-title {
    height: 20px;
    margin: 0;
  }
`;

export const CONVERSATIONS_QUERY_PAGE_SIZE = 30;

export type BulkOperationType =
  | 'open'
  | 'close'
  | 'mark'
  | 'unmark'
  | 'snooze'
  | 'discardSnooze';

type ConversationsSectionProps = {
  contactInteractionId?: string;
};

export const ConversationsSection = memo<ConversationsSectionProps>(
  ({ contactInteractionId }) => {
    const history = useHistory();
    const institutionUrl = useInstitutionUrl();
    const currentInstitution = useCurrentInstitution();
    const breakpointUpMd = useBreakpoint(up('md'));
    const handleGraphQLError = useHandleGraphQLError();
    const {
      filters,
      setFilters,
      selectedConversationId,
      selectConversation,
      bulkSelectionEnabled,
      bulkSelectionMap,
      toggleBulkSelectionItemId,
      bulkSelectAllEnabled,
      toggleBulkSelectionMode,
    } = useConversation();
    const userId = useCurrentUserId();
    const variables = useConversationQueryVariables({
      userId,
      institutionId: currentInstitution.id,
      filters,
    });

    const { t } = useTranslation(['conversations', 'common']);
    const showNotification = useShowNotification();
    const showConfirmationModal = useShowConfirmationModal();
    const [
      isPerformingSelectedAllBulkOperation,
      setIsPerformingSelectedAllBulkOperation,
    ] = useState(false);
    const { getFeatureFlag } = useAuthorization();

    const baseBulkOperationVariables = {
      institutionId: currentInstitution.id,
      ...(bulkSelectAllEnabled
        ? { where: variables.where }
        : {
            conversationsId: [...bulkSelectionMap.keys()],
          }),
    };

    const [fetchTotalCount, { data: totalCountQuery, fetchMore: fetchMoreTotalCount }] =
      useLazyQuery<Pick<Query, 'conversations'>, Partial<QueryConversationsArgs>>(
        CONVERSATIONS_TOTAL_COUNT,
      );

    useEffect(() => {
      const handleLoadTotalCount = async () => {
        if (bulkSelectionEnabled) {
          if (totalCountQuery?.conversations.totalCount === undefined) {
            await fetchTotalCount({ variables });
          } else {
            await fetchMoreTotalCount({ variables });
          }
        }
      };

      void handleLoadTotalCount();
    }, [
      bulkSelectionEnabled,
      fetchTotalCount,
      fetchMoreTotalCount,
      totalCountQuery,
      variables,
    ]);

    const {
      loading,
      nodes,
      loadingMoreBottom,
      handleLoadBottom,
      previousData,
      firstItemIndex,
      hasNewItem,
      clearHasNewItem,
      refetch,
      fetchMore,
    } = useInfiniteListQuery<
      Pick<Query, 'conversations'>,
      Partial<QueryConversationsArgs>,
      Conversation,
      'CONVERSATION_UPDATED'
    >({
      queryOptions: {
        getNodeIdCallback: (conversation) => conversation?.id,
        query: CONVERSATIONS,
        variables,
        getEdgesCallback: (data) => data?.conversations.edges ?? [],
        getPageInfoCallback: (data) => data?.conversations.pageInfo,
        sortNodesCallback: (conversations) =>
          sortConversations(
            conversations,
            getFeatureFlag('temp_lggdev-2414_conversations-sorted-by-relevant-update-at'),
          ),
        queryPageSize: CONVERSATIONS_QUERY_PAGE_SIZE,
        onError: handleGraphQLError,
      },
      pollingOptions: {
        interval: 30000,
      },
      updateHandlerOptions: {
        updateHandlerTopic: 'CONVERSATION_UPDATED',
        computeUpdateHandlerWhere: (variables, eventData) => {
          return {
            ...variables,
            first: null,
            last: 1,
            where: {
              _and: [...(variables.where?._and ?? [])],
              id: { _eq: eventData.id },
            },
          };
        },
      },
    });

    const handleOnLoadConversation = useCallback(
      (conversation: Conversation) => {
        if (selectedConversationId) {
          selectConversation({
            conversationId: conversation.id,
            contactId: conversation.contact.id,
          });
        }
      },
      [selectConversation, selectedConversationId],
    );

    const bulkOperationSideEffectsHandler = async () => {
      if (bulkSelectAllEnabled) {
        setIsPerformingSelectedAllBulkOperation(true);
        await refetch();
        setIsPerformingSelectedAllBulkOperation(false);
      } else {
        await fetchMore({
          variables: {
            ...variables,
            where: {
              id: { _in: [...bulkSelectionMap.keys()] },
              ...variables.where,
            },
          },
        });
      }

      toggleBulkSelectionMode();
    };

    const bulkOperationTotalCount = bulkSelectAllEnabled
      ? totalCountQuery?.conversations.totalCount || 0
      : bulkSelectionMap.size;

    const showBulkOperationSuccessMessage = ({
      message,
      icon,
    }: {
      message: string;
      icon: string;
    }) => {
      showNotification({
        type: 'success',
        title: message,
        customIcon: icon,
      });
    };

    const [bulkSetOpened] = useMutation<
      Pick<Mutation, 'conversationBulkSetClosed'>,
      { input: Partial<ConversationBulkSetClosedInput> }
    >(CONVERSATION_BULK_SET_CLOSED_MUTATION, {
      variables: {
        input: {
          ...baseBulkOperationVariables,
          isClosed: false,
        },
      },
      onCompleted: () => {
        showBulkOperationSuccessMessage({
          message: t('conversations:conversationsBulkActions.successMessages.open', {
            count: bulkOperationTotalCount,
          }),
          icon: 'chatOpen',
        });
      },
      onError: handleGraphQLError,
    });

    const [bulkSetClosed] = useMutation<
      Pick<Mutation, 'conversationBulkSetClosed'>,
      { input: Partial<ConversationBulkSetClosedInput> }
    >(CONVERSATION_BULK_SET_CLOSED_MUTATION, {
      variables: {
        input: {
          ...baseBulkOperationVariables,
          isClosed: true,
        },
      },
      onCompleted: () => {
        showBulkOperationSuccessMessage({
          message: t('conversations:conversationsBulkActions.successMessages.close', {
            count: bulkOperationTotalCount,
          }),
          icon: 'circularCheck',
        });
      },
      onError: handleGraphQLError,
    });

    const [bulkSetMarked] = useMutation<
      Pick<Mutation, 'conversationBulkSetMarked'>,
      { input: Partial<ConversationBulkSetMarkedInput> }
    >(CONVERSATION_BULK_SET_MARKED_MUTATION, {
      variables: {
        input: {
          ...baseBulkOperationVariables,
          isMarked: true,
        },
      },
      onCompleted: () => {
        showBulkOperationSuccessMessage({
          message: t('conversations:conversationsBulkActions.successMessages.mark', {
            count: bulkOperationTotalCount,
          }),
          icon: 'star',
        });
      },
      onError: handleGraphQLError,
    });

    const [bulkSetUnmarked] = useMutation<
      Pick<Mutation, 'conversationBulkSetMarked'>,
      { input: Partial<ConversationBulkSetMarkedInput> }
    >(CONVERSATION_BULK_SET_MARKED_MUTATION, {
      variables: {
        input: {
          ...baseBulkOperationVariables,
          isMarked: false,
        },
      },
      onCompleted: () => {
        showBulkOperationSuccessMessage({
          message: t('conversations:conversationsBulkActions.successMessages.unmark', {
            count: bulkOperationTotalCount,
          }),
          icon: 'unmark',
        });
      },
      onError: handleGraphQLError,
    });

    const [bulkSetSnoozed] = useMutation<
      Pick<Mutation, 'conversationBulkSetSnoozed'>,
      { input: Partial<ConversationBulkSetSnoozedInput> }
    >(CONVERSATION_BULK_SET_SNOOZED_MUTATION, {
      onCompleted: () => {
        showBulkOperationSuccessMessage({
          message: t('conversations:conversationsBulkActions.successMessages.snooze', {
            count: bulkOperationTotalCount,
          }),
          icon: 'time',
        });
      },
      onError: handleGraphQLError,
    });

    const [bulkSetUnsnoozed] = useMutation<
      Pick<Mutation, 'conversationBulkSetSnoozed'>,
      { input: Partial<ConversationBulkSetSnoozedInput> }
    >(CONVERSATION_BULK_SET_SNOOZED_MUTATION, {
      variables: {
        input: {
          ...baseBulkOperationVariables,
          date: null,
        },
      },
      onCompleted: () => {
        showBulkOperationSuccessMessage({
          message: t('conversations:conversationsBulkActions.successMessages.unsnooze', {
            count: bulkOperationTotalCount,
          }),
          icon: 'discardSnooze',
        });
      },
      onError: handleGraphQLError,
    });

    const bulkOperationResolver = (
      type: BulkOperationType,
      date?: Date,
    ): VoidFunction => {
      // eslint-disable-next-line custom-rules/require-try-catch-for-exhaustive
      const { bulkOperation, modalTitle, operationLabel } = match(type)
        .with('close', () => ({
          bulkOperation: bulkSetClosed,
          operationLabel: t('conversations:conversationsBulkActions.operations.close'),
          modalTitle: t(
            'conversations:conversationsBulkActions.confirmationModal.title.close',
          ),
        }))
        .with('open', () => ({
          bulkOperation: bulkSetOpened,
          operationLabel: t('conversations:conversationsBulkActions.operations.open'),
          modalTitle: t(
            'conversations:conversationsBulkActions.confirmationModal.title.open',
          ),
        }))
        .with('mark', () => ({
          bulkOperation: bulkSetMarked,
          operationLabel: t('conversations:conversationsBulkActions.operations.mark'),
          modalTitle: t(
            'conversations:conversationsBulkActions.confirmationModal.title.mark',
          ),
        }))
        .with('unmark', () => ({
          bulkOperation: bulkSetUnmarked,
          operationLabel: t('conversations:conversationsBulkActions.operations.unmark'),
          modalTitle: t(
            'conversations:conversationsBulkActions.confirmationModal.title.unmark',
          ),
        }))
        .with('snooze', () => ({
          bulkOperation: async () => {
            await bulkSetSnoozed({
              variables: {
                input: {
                  ...baseBulkOperationVariables,
                  date,
                },
              },
            });
          },
          operationLabel: t('conversations:conversationsBulkActions.operations.snooze'),
          modalTitle: t(
            'conversations:conversationsBulkActions.confirmationModal.title.snooze',
          ),
        }))
        .with('discardSnooze', () => ({
          bulkOperation: bulkSetUnsnoozed,
          operationLabel: t('conversations:conversationsBulkActions.operations.unsnooze'),
          modalTitle: t(
            'conversations:conversationsBulkActions.confirmationModal.title.discardSnooze',
          ),
        }))
        .exhaustive();

      const bulkOperationWithSideEffects = async () => {
        await bulkOperation();
        await bulkOperationSideEffectsHandler();
      };

      return bulkOperationTotalCount <= 9
        ? bulkOperationWithSideEffects
        : () => {
            showConfirmationModal({
              testId: 'conversation-bulk-operation-confirmation-dialog',
              confirmButtonText: operationLabel,
              message: t(
                'conversations:conversationsBulkActions.confirmationModal.message',
                {
                  itemCount: bulkOperationTotalCount.toLocaleString(),
                  operation: operationLabel,
                },
              ),
              title: modalTitle,
              onConfirm: bulkOperationWithSideEffects,
            });
          };
    };

    return (
      <>
        <FilterSkeleton loading={loading && !previousData} active paragraph={false} round>
          <FiltersContainer
            filters={filters}
            onFiltersChange={setFilters}
            queryTotalCount={totalCountQuery?.conversations.totalCount}
            bulkOperationResolver={bulkOperationResolver}
          />
        </FilterSkeleton>
        <ConversationsList
          filters={filters}
          loading={loading || isPerformingSelectedAllBulkOperation}
          onNewConversationsBubbleClick={clearHasNewItem}
          hasNewConversations={hasNewItem}
          firstItemIndex={firstItemIndex}
          loadingMore={loadingMoreBottom}
          onLoadMore={handleLoadBottom}
          conversations={nodes}
          selectConversation={selectConversation}
          selectedConversationId={selectedConversationId}
          bulkSelectionEnabled={bulkSelectionEnabled}
          bulkSelectionMap={bulkSelectionMap}
          toggleBulkSelectionItemId={toggleBulkSelectionItemId}
          bulkSelectAllEnabled={bulkSelectAllEnabled}
        />
        {!breakpointUpMd && (
          <ConversationModal
            visible={!!selectedConversationId}
            close={() => history.push(`${institutionUrl}conversations/`)}
            selectedConversationId={selectedConversationId}
            contactInteractionId={contactInteractionId}
            handleOnLoadConversation={handleOnLoadConversation}
          />
        )}
      </>
    );
  },
);
