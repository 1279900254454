import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { match } from 'ts-pattern';
import { Query, QueryBroadcastArgs } from '@lgg/isomorphic/types/__generated__/graphql';
import { getBroadcastOptOutMessage } from '@lgg/isomorphic/utils/get-broadcast-opt-out-message';
import { LggOptionsDropdownButtonWithCustomTrigger } from 'src/components/general/button/dropdown-button';
import { ButtonV2 } from 'src/components/general/button/lgg-button';
import { Icon } from 'src/components/general/icon';
import { LoadingSpinner } from 'src/components/general/inputs/shared';
import { LggModal } from 'src/components/general/modals/lgg-modal';
import { ModalCloseIcon } from 'src/components/general/modals/modals.shared';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { BroadcastSentBreakDownPopover } from 'src/components/pages/broadcast/components/broadcast-sent-breakdown-popover/broadcast-sent-breakdown-popover';
import { BroadcastStatistics } from 'src/components/pages/broadcast/components/broadcast-statistics/broadcast-statistics';
import { BroadcastSummary } from 'src/components/pages/broadcast/components/broadcast-summary/broadcast-summary';
import { getBroadcastMessageLanguage } from 'src/components/pages/broadcast/components/broadcast-wizard/utils';
import {
  BroadcastArchivedBadge,
  BroadcastMessagePreview,
  BroadcastStatusTag,
  useBroadcastMoreOptions,
} from 'src/components/pages/broadcast/components/shared';
import { canBroadcastBeEdited } from 'src/components/pages/broadcast/helpers';
import { GET_BROADCAST_MODAL_DETAILS } from 'src/components/pages/broadcast/queries';
import { resolvePreviewWithContactPlaceholders } from 'src/components/pages/conversations/components/general/lexical-editor/plugins/insert-contact-placeholder-plugin';
import { useUrls } from 'src/hooks/use-urls';
import { useVisible } from 'src/hooks/use-visible';

const StyledModal = styled(LggModal)`
  padding: 20px 20px;

  &,
  .ant-modal-content {
    height: 100%;
    max-height: 840px;
    max-width: 950px;
    width: 100%;

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

  .ant-modal-close {
    display: none;
  }
`;

const ModalHeader = styled(FlexRow)`
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};
  height: 90px;
  justify-content: space-between;
  min-height: 90px;
  padding: 23px 15px 25px 20px;
  width: 100%;
`;

const ModalBody = styled.div`
  background-color: ${(props) => props.theme.colors.porcelain};
  height: 100%;
  max-height: 710px;
  padding: 20px;
  width: 100%;
`;

const ModalContent = styled(FlexRow)`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 6px;
  height: 100%;
  width: 100%;
`;

const BroadcastName = styled.span`
  color: ${({ theme }) => theme.colors.carbon};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 24px;
  font-weight: 400;
  letter-spacing: -0.47px;
  line-height: 30px;
  text-align: left;
`;

const LeftRail = styled(FlexColumn)`
  border-right: 1px solid ${({ theme }) => theme.colors.koala};
  max-width: 571px;
  padding: 30px;
  width: 100%;
`;

const RightRail = styled(FlexColumn)`
  max-width: 298px;
  padding: 30px;
  width: 100%;
`;

const SectionTitle = styled.span`
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 10px;
`;

const BroadcastDetailsContainer = styled.div`
  margin-bottom: 30px;
`;

const EditButton = styled(ButtonV2)`
  margin-right: 15px;
  line-height: 18px;
`;

const MoreOptionsIcon = styled(Icon)`
  cursor: pointer;
  margin-right: 10px;
  padding: 5px;
  with: 18px;
`;

const StyledCloseIcon = styled(ModalCloseIcon)`
  padding: 5px;
`;

const HeaderRightRailContainer = styled(FlexRow)`
  align-items: center;
`;

const LoadingContainer = styled(FlexColumn)`
  align-items: center;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const BroadcastModalContent = styled(FlexColumn)`
  height: 100%;
`;

type BroadcastDetailsModalProps = {
  broadcastId: string;
  onClose: VoidFunction;
  refetchHandler: VoidFunction;
};

export const BroadcastDetailsModal = memo<BroadcastDetailsModalProps>(
  ({ onClose, broadcastId, refetchHandler }) => {
    const { t } = useTranslation(['common', 'broadcast']);
    const { getBroadcastMoreOptions } = useBroadcastMoreOptions({
      refetchHandler,
      includeEditOption: false,
    });
    const visibilityHandler = useVisible(true);
    const moreOptionsVisibilityHandler = useVisible();
    const { getEditBroadcastUrl } = useUrls();
    const history = useHistory();

    const { data, loading } = useQuery<Pick<Query, 'broadcast'>, QueryBroadcastArgs>(
      GET_BROADCAST_MODAL_DETAILS,
      {
        variables: {
          id: broadcastId,
        },
        onError: () => {
          visibilityHandler.close();
          onClose();
        },
      },
    );

    const broadcast = data?.broadcast;

    const broadcastChannelConfiguration = useMemo(
      () =>
        match(broadcast?.channelsConfiguration?.[0])
          .with(
            { __typename: 'BroadcastChannelSmsConfiguration' },
            ({ content, resource, optOutMessage, companyName }) => {
              const messagePreview = resolvePreviewWithContactPlaceholders({
                message: (content ?? [])
                  .filter((value) => value.type === 'MESSAGE')
                  .map((value) => value.message)
                  .join(''),
              });

              const sender = resource?.phoneNumber?.national;

              return {
                companyName,
                messagePreview,
                optOutMessage,
                sender,
              };
            },
          )
          .otherwise(() => {
            return {
              companyName: '',
              messagePreview: '',
              sender: '',
              optOutMessage: '',
            };
          }),
      [broadcast?.channelsConfiguration],
    );

    if (!broadcast || loading) {
      return null;
    }

    const {
      id,
      audience = 0,
      sent = 0,
      replies = 0,
      optOuts = 0,
      status,
      name,
      startedAt,
      isArchived,
      averageResponseTimeInSeconds = 0,
      discardedContacts,
    } = broadcast;

    const {
      sender,
      messagePreview,
      optOutMessage: optOutMessageLanguage,
      companyName,
    } = broadcastChannelConfiguration;

    const optOutMessage = getBroadcastMessageLanguage(optOutMessageLanguage);

    return (
      <StyledModal
        data-lgg-id="broadcast-details-modal"
        visible={visibilityHandler.visible}
        width="100%"
        closeIcon={null}
        afterClose={onClose}
        onClose={visibilityHandler.close}
        destroyOnClose
      >
        {!broadcast || loading ? (
          <LoadingContainer>
            <LoadingSpinner size="40px" />
          </LoadingContainer>
        ) : (
          <BroadcastModalContent data-lgg-id="broadcast-modal-content">
            <ModalHeader>
              <FlexColumn>
                <BroadcastName data-lgg-id="broadcast-modal-name">{name}</BroadcastName>
                <FlexRow>
                  <BroadcastStatusTag
                    data-lgg-id="broadcast-modal-status-badge"
                    status={status}
                  />
                  {isArchived ? <BroadcastArchivedBadge /> : null}
                </FlexRow>
              </FlexColumn>
              <HeaderRightRailContainer>
                {!isArchived && canBroadcastBeEdited(broadcast.status) ? (
                  <EditButton
                    icon="edit"
                    data-lgg-id="edit-broadcast-button"
                    size="regular"
                    variant="default"
                    onClick={() => {
                      history.push(getEditBroadcastUrl(id));
                    }}
                  >
                    {t('common:edit')}
                  </EditButton>
                ) : null}
                <LggOptionsDropdownButtonWithCustomTrigger
                  options={getBroadcastMoreOptions({
                    id,
                    status,
                    isArchived,
                  })}
                  customDropdownProps={{
                    getPopupContainer: (trigger) => trigger,
                  }}
                  visibilityHandler={moreOptionsVisibilityHandler}
                  customTrigger={
                    <MoreOptionsIcon
                      type="moreOptionsWithPadding"
                      data-lgg-id="broadcast-details-modal-more-options"
                    />
                  }
                />
                <StyledCloseIcon
                  type="close"
                  onClick={visibilityHandler.close}
                  data-lgg-id="broadcast-modal-close-icon"
                />
              </HeaderRightRailContainer>
            </ModalHeader>
            <ModalBody>
              <ModalContent>
                <LeftRail>
                  <SectionTitle>
                    {t(
                      'broadcast:pages.broadcast.broadcastDetailsModal.broadcastDetails.title',
                    )}
                  </SectionTitle>
                  <BroadcastDetailsContainer>
                    <BroadcastSummary
                      uniqueContacts={audience}
                      sender={sender}
                      messages={sent}
                      date={startedAt}
                      status={status}
                      variant="BROADCAST-DETAILS"
                      messageInfoExtraContent={
                        discardedContacts ? (
                          <BroadcastSentBreakDownPopover
                            iconSize="regular"
                            popoverPlacement="left"
                            discardedContacts={discardedContacts}
                            audience={audience ?? 0}
                            sent={sent ?? 0}
                          />
                        ) : null
                      }
                    />
                  </BroadcastDetailsContainer>
                  <SectionTitle>
                    {t(
                      'broadcast:pages.broadcast.broadcastDetailsModal.statistics.title',
                    )}
                  </SectionTitle>
                  <BroadcastStatistics
                    sent={sent}
                    averageResponseTimeInSeconds={averageResponseTimeInSeconds ?? 0}
                    optOuts={optOuts}
                    replies={replies}
                  />
                </LeftRail>
                <RightRail>
                  <SectionTitle>
                    {t(
                      'broadcast:pages.broadcast.broadcastDetailsModal.messagePreview.title',
                    )}
                  </SectionTitle>
                  {messagePreview && optOutMessage ? (
                    <BroadcastMessagePreview
                      message={messagePreview}
                      optOutMessage={getBroadcastOptOutMessage(optOutMessage)}
                      companyName={companyName}
                    />
                  ) : null}
                </RightRail>
              </ModalContent>
            </ModalBody>
          </BroadcastModalContent>
        )}
      </StyledModal>
    );
  },
);
