import React, { useCallback, useEffect, useRef, useState } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import { Virtuoso, VirtuosoHandle, VirtuosoProps } from 'react-virtuoso';
import { Capacitor } from '@capacitor/core';
import c from 'classnames';
import { debounce, invert, ValueIteratee } from 'lodash';
import styled from 'styled-components';
import {
  GroupHeader,
  InlineGroupHeader,
} from 'src/components/pages/conversations/components/contact-interactions/contact-interaction-list/contact-interactions-lists-group-header';
import { useAdjustScrollOnHeightChange } from 'src/components/pages/conversations/components/contact-interactions/contact-interaction-list/hooks/use-adjust-scroll-on-height-change';
import { useContactInteractionsDateGroupHeader } from 'src/components/pages/conversations/components/contact-interactions/contact-interaction-list/hooks/use-contact-interactions-date-group-header';
import { useInvertScrolling } from 'src/components/pages/conversations/components/contact-interactions/contact-interaction-list/hooks/use-invert-scrolling';

const StickyHeaderContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 6;
`;

const ItemWrapper = styled.div`
  padding: 0 20px 0;
  position: relative;
  transform: scaleY(-1);

  &.showing-ci-details {
    z-index: 10;
  }

  &.bottom-list-item {
    margin-top: 15px;
  }
`;

const InvertedVirtuosoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

type InvertedVirtuosoProps<ItemData, Context> = {
  itemContent: (index: number, data: ItemData, context: Context) => React.ReactNode;
  firstItemIndex: number;
  getItemId: (item: ItemData) => string | number;
  scrollToBottomRef?: (callback: VoidFunction) => void;
  virtuosoRef?: (ref: VirtuosoHandle | null) => void;
  groupResolver: ValueIteratee<ItemData>;
  data: ItemData[];
} & Pick<
  VirtuosoProps<ItemData, Context>,
  | 'components'
  | 'atTopStateChange'
  | 'initialTopMostItemIndex'
  | 'startReached'
  | 'endReached'
  | 'itemsRendered'
  | 'increaseViewportBy'
  | 'scrollerRef'
>;

export const useOutstandingItem = ({ visible }: { visible: boolean }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const timer = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (!containerRef.current) return;

    // This is a workaround to make sure the current item popover is visible
    // even with the inverted list, we need to target the Virtuoso list item in
    // order to work
    const itemWrapper = containerRef.current.closest('.inverted-list-item');

    if (!itemWrapper) {
      return;
    }

    if (visible) {
      if (timer.current) {
        // in the case a popover is closed and quickly reopened, remove the timer execution
        clearTimeout(timer.current);
        timer.current = undefined;
      }
      itemWrapper.classList.add('showing-ci-details');
    } else {
      timer.current = setTimeout(() => {
        itemWrapper.classList.remove('showing-ci-details');
      }, 200);
    }
  }, [visible]);

  return { containerRef };
};

export const InvertedVirtuoso = <ItemData, Context>(
  props: InvertedVirtuosoProps<ItemData, Context>,
) => {
  const {
    firstItemIndex,
    itemContent,
    getItemId,
    components,
    atTopStateChange,
    initialTopMostItemIndex,
    endReached,
    startReached,
    increaseViewportBy,
    data,
    itemsRendered,
    scrollerRef,
    scrollToBottomRef,
    groupResolver,
    virtuosoRef,
  } = props;
  const virtuosoScrollerRef = useRef<HTMLElement | Window | null>(null);
  const internalVirtuosoHandleRef = useRef<VirtuosoHandle | null>(null);
  const [isScrollerRefAvailable, setIsScrollerRefAvailable] = useState(false);
  const isScrollingRef = useRef(false);
  const groupOffsetsRef = useRef<{ [group: string]: number }>({});
  const { groups, groupsIndexes } = useContactInteractionsDateGroupHeader({
    data,
    getItemId,
    groupResolver,
  });
  const [currentGroup, setCurrentGroup] = useState<string | null>(groups[0] ?? null);

  // When the list is at the bottom and a new CI is added, we have this to preserve
  // the scroll position
  useAdjustScrollOnHeightChange({
    scrollerRef: virtuosoScrollerRef,
    firstItemIndex,
    isScrollingRef: isScrollingRef,
  });

  // As we are transforming the list with scaleY, we need to invert the
  // scrolling direction
  useInvertScrolling({
    scrollerElement: virtuosoScrollerRef.current,
    isScrollerAvailable: isScrollerRefAvailable,
  });

  const updateGroupOffsets = useCallback(() => {
    const scroller = virtuosoScrollerRef.current as HTMLDivElement;

    if (!scroller) {
      return;
    }

    const groupHeaders = scroller.querySelectorAll('.inverted-virtuoso-group-header');

    [...groupHeaders].forEach((groupHeader) => {
      // Skip negative values due to renders out of viewport
      if (groupHeader.getBoundingClientRect().top < 0) {
        return;
      }

      // Get the virtuoso item list relative to the group header
      const virtuosoItemInList = groupHeader.closest(
        'div[data-item-index]',
      ) as HTMLDivElement;

      if (!virtuosoItemInList) {
        return;
      }

      // Use offset parent instead of `scroller.scrollTop` to avoid gaps in the calculation
      const offsetParent = virtuosoItemInList.offsetParent;

      if (!offsetParent) {
        return;
      }

      const offset =
        offsetParent.getBoundingClientRect().top -
        virtuosoItemInList.getBoundingClientRect().top;
      const group = groupHeader.getAttribute('data-group') ?? '';

      groupOffsetsRef.current[group] = offset;
    });
  }, []);

  const updateCurrentGroup = useCallback(
    (scroller?: HTMLElement | Window | null) => {
      if (!scroller || scroller instanceof Window) {
        return;
      }

      const setCurrentGroupOnlyIfChanged = (group: string | null) => {
        if (currentGroup !== group) {
          setCurrentGroup(group);
        }
      };

      // If the list is not scrollable, we don't need to show the floating group header
      // as the inline groups are enough
      if (scroller.scrollHeight === scroller.clientHeight) {
        setCurrentGroupOnlyIfChanged(null);
        return;
      }

      const groupOffsets = Object.values(groupOffsetsRef.current).sort((a, b) => a - b);
      const matchingGroupOffset = groupOffsets.find(
        (offset) => scroller.scrollTop <= offset,
      );
      const offsetsLookup = invert(groupOffsetsRef.current);

      // If we have a matching group offset based on the `scroller.scrollTop`, we can use it to find the group
      if (matchingGroupOffset) {
        const matchingGroup = offsetsLookup[matchingGroupOffset];

        setCurrentGroupOnlyIfChanged(matchingGroup);
      } else {
        for (const group of groups) {
          if (!groupOffsetsRef.current[group]) {
            setCurrentGroupOnlyIfChanged(group);
            break;
          }
        }
      }
    },
    [currentGroup, groups],
  );

  const scrollListToBottom = useCallback(() => {
    internalVirtuosoHandleRef.current?.scrollToIndex(0);
  }, []);

  useEffect(() => {
    if (scrollToBottomRef) {
      scrollToBottomRef(scrollListToBottom);
    }
  }, [scrollListToBottom, scrollToBottomRef]);

  // This is a workaround for iOS and Android, as it has a bug that prevent scrolling at the start or the end of the list when using transform: scaleY(-1)
  useEffect(() => {
    const scroller = virtuosoScrollerRef.current;

    // We need to check if the scroller is available and if it's a div, as it can be a window
    if (!scroller || !(scroller instanceof HTMLDivElement) || !scroller?.parentElement) {
      return;
    }

    // Workaround only for iOS and Android
    if (!['ios', 'android'].includes(Capacitor.getPlatform()) && !isIOS && !isAndroid) {
      return;
    }

    // Preventing overscroll bouncing, this help reducing some incorrect scroll events calculations on iOS
    scroller.style.overscrollBehavior = 'none';

    const preventScrollOnListEnds = debounce(() => {
      // There is no scrollable content
      if (scroller.scrollHeight <= scroller.clientHeight) {
        return;
      }

      // Prevent the scroll being at the bottom, as it will disable the scroll because of the transform: scaleY(-1)
      if (scroller.scrollTop <= 0) {
        scroller.scrollTo({
          top: 1,
          behavior: 'auto',
        });
      }

      // Prevent the scroll being at the top, as it will disable the scroll because of the transform: scaleY(-1)
      else if (scroller.scrollHeight - scroller.clientHeight - scroller.scrollTop <= 0) {
        scroller.scrollTo({
          top: scroller.scrollHeight - scroller.clientHeight - 1,
          behavior: 'auto',
        });
      }
    }, 10);

    // Fix scroll position when the list is scrolled
    scroller.addEventListener('scroll', preventScrollOnListEnds, false);

    // Fix scroll position when the list height changes (new items added, device orientation change, mobile keyboard open, etc.)
    const listHeightObserver = new ResizeObserver(preventScrollOnListEnds);
    listHeightObserver.observe(scroller.parentElement);

    // We need to call it once when the component is mounted to make sure the scroll is not at the top or bottom
    requestAnimationFrame(preventScrollOnListEnds);

    return () => {
      if (scroller) {
        scroller.removeEventListener('scroll', preventScrollOnListEnds);

        if (scroller.parentElement) {
          listHeightObserver.unobserve(scroller.parentElement);
        }
      }
    };
  }, [virtuosoScrollerRef]);

  useEffect(() => {
    const scroller = virtuosoScrollerRef.current as HTMLDivElement;

    if (!scroller) {
      return;
    }

    const onScroll = () => {
      updateGroupOffsets();
      updateCurrentGroup(scroller);
    };

    scroller.addEventListener('scroll', onScroll);

    return () => {
      scroller.removeEventListener('scroll', onScroll);
    };
  }, [
    currentGroup,
    groups,
    isScrollerRefAvailable,
    setCurrentGroup,
    updateCurrentGroup,
    updateGroupOffsets,
  ]);

  // Handle resize event to keep current group updated
  useEffect(() => {
    const scroller = virtuosoScrollerRef.current;

    if (!scroller || scroller instanceof Window || !scroller?.parentElement) {
      return;
    }

    const onResize = () => {
      updateGroupOffsets();
      updateCurrentGroup(scroller);
    };

    const observer = new ResizeObserver(onResize);
    observer.observe(scroller.parentElement);

    return () => {
      if (scroller.parentElement) {
        observer.unobserve(scroller.parentElement);
      }
    };
  }, [updateCurrentGroup, isScrollerRefAvailable, updateGroupOffsets]);

  return (
    <InvertedVirtuosoWrapper>
      {currentGroup && (
        <StickyHeaderContainer>
          <GroupHeader>{currentGroup}</GroupHeader>
        </StickyHeaderContainer>
      )}
      <Virtuoso
        ref={(ref) => {
          internalVirtuosoHandleRef.current = ref;
          virtuosoRef?.(ref);
        }}
        scrollerRef={(ref) => {
          scrollerRef?.(ref);
          virtuosoScrollerRef.current = ref;
          setIsScrollerRefAvailable(true);
        }}
        firstItemIndex={firstItemIndex}
        initialTopMostItemIndex={initialTopMostItemIndex}
        increaseViewportBy={increaseViewportBy}
        startReached={startReached}
        endReached={endReached}
        data={data}
        components={components}
        atTopStateChange={atTopStateChange}
        isScrolling={(isScrolling) => {
          isScrollingRef.current = isScrolling;
        }}
        totalListHeightChanged={() => {
          updateGroupOffsets();
          updateCurrentGroup(virtuosoScrollerRef.current);
        }}
        style={{
          transform: 'scaleY(-1) translateZ(0)',
          position: 'relative',
          WebkitOverflowScrolling: 'touch',
          overscrollBehavior: 'contain',
        }}
        itemsRendered={itemsRendered}
        itemContent={(index, internalData, context) => {
          const itemIndex = index - firstItemIndex;
          const item = itemContent(itemIndex, internalData, context);
          const itemId = data ? getItemId(data[itemIndex]) : '';
          const group = groupsIndexes[itemId];

          return (
            <ItemWrapper
              key={itemId}
              className={c({
                'date-group-item-container inverted-list-item': true,
                'bottom-list-item': itemIndex === 0,
              })}
              data-lgg-id={`inverted-list-item-${itemId}`}
            >
              {group && (
                <InlineGroupHeader visible={group !== currentGroup}>
                  {group}
                </InlineGroupHeader>
              )}
              {item}
            </ItemWrapper>
          );
        }}
      />
    </InvertedVirtuosoWrapper>
  );
};
