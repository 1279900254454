import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import {
  Mutation,
  MutationSendTestBroadcastMessageArgs,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { getBroadcastOptOutMessage } from '@lgg/isomorphic/utils/get-broadcast-opt-out-message';
import { CompanyUsersSelect } from 'src/components/domain/users/components/company-users-select';
import { LggButton } from 'src/components/general/button/lgg-button';
import { useShowNotification } from 'src/components/general/feedback/hooks/use-show-notification';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { BroadcastSummary } from 'src/components/pages/broadcast/components/broadcast-summary/broadcast-summary';
import { BroadcastWizardStepProps } from 'src/components/pages/broadcast/components/broadcast-wizard/broadcast-wizard';
import {
  WizardStepSectionDivider,
  WizardStepSectionTitle,
} from 'src/components/pages/broadcast/components/broadcast-wizard/shared';
import { getBroadcastSmsCounterValue } from 'src/components/pages/broadcast/components/broadcast-wizard/utils';
import { SEND_BROADCAST_TEST_MESSAGE_MUTATION } from 'src/components/pages/broadcast/queries';
import { resolvePreviewWithContactPlaceholders } from 'src/components/pages/conversations/components/general/lexical-editor/plugins/insert-contact-placeholder-plugin';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { useDateHelpers } from 'src/hooks/use-date-helpers';

const OverviewMessage = styled.span`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 12px;
  text-align: left;
`;

const TestYourBroadcastContainer = styled(FlexColumn)`
  margin-top: 24px;
`;

const SummaryContainer = styled(FlexColumn)`
  margin-bottom: 25px;
  max-width: 544px;
`;

const UserSelectContainer = styled.div`
  margin-right: 10px;
  max-width: 300px;
  width: 100%;
`;

const TestBroadcastFormContainer = styled(FlexRow)`
  align-items: flex-end;
  margin-top: 16px;
`;

const SendBroadcastButton = styled(LggButton)`
  width: 130px;
`;

export const OverviewStep = ({ form }: BroadcastWizardStepProps) => {
  const { t } = useTranslation(['broadcast', 'common']);
  const [testUserId, setTestUserId] = useState<number | undefined>(undefined);
  const { parseISO, set, startOfDay } = useDateHelpers();
  const { scheduledStartAt, uniqueContacts, sender, companyName } = form.getValues();
  const { id: institutionId } = useCurrentInstitution();
  const showNotification = useShowNotification();

  const [sendBroadcastTestMessage, { loading: sendingBroadcastTestMessage }] =
    useMutation<
      Pick<Mutation, 'sendTestBroadcastMessage'>,
      MutationSendTestBroadcastMessageArgs
    >(SEND_BROADCAST_TEST_MESSAGE_MUTATION);

  const handleSendTestMessage = useCallback(async () => {
    const { message, optOutMessageLanguage, sender } = form.getValues();

    const showSentMessageFailedNotification = () => {
      showNotification({
        type: 'error',
        title: t(
          'broadcast:pages.broadcastWizard.steps.overview.testBroadcast.notification.error.title',
        ),
        message: t(
          'broadcast:pages.broadcastWizard.steps.overview.testBroadcast.notification.error.message',
        ),
      });
    };

    if (testUserId && sender.id && optOutMessageLanguage) {
      const getPreviewPlaceholderValue = (value: string) => `[${value.toUpperCase()}]`;

      const fullMessage = [
        companyName,
        resolvePreviewWithContactPlaceholders({
          message: message ?? '',
          previewContactData: {
            firstName: getPreviewPlaceholderValue(
              t(
                'broadcast:pages.broadcastWizard.steps.overview.testBroadcast.messagePlaceholders.firstName',
              ),
            ),
            fullName: getPreviewPlaceholderValue(
              t(
                'broadcast:pages.broadcastWizard.steps.overview.testBroadcast.messagePlaceholders.fullName',
              ),
            ),
            interest: getPreviewPlaceholderValue(
              t(
                'broadcast:pages.broadcastWizard.steps.overview.testBroadcast.messagePlaceholders.interest',
              ),
            ),
            lastName: getPreviewPlaceholderValue(
              t(
                'broadcast:pages.broadcastWizard.steps.overview.testBroadcast.messagePlaceholders.lastName',
              ),
            ),
          },
        }),
        getBroadcastOptOutMessage(optOutMessageLanguage),
      ].join('\n\n');

      await sendBroadcastTestMessage({
        variables: {
          input: {
            institutionId,
            message: fullMessage,
            resourceId: Number(sender.id),
            userId: testUserId,
          },
        },
        onCompleted: ({ sendTestBroadcastMessage }) => {
          const wasSuccess = sendTestBroadcastMessage.success;

          if (wasSuccess) {
            showNotification({
              type: 'success',
              title: t(
                'broadcast:pages.broadcastWizard.steps.overview.testBroadcast.notification.success.title',
              ),
              message: t(
                'broadcast:pages.broadcastWizard.steps.overview.testBroadcast.notification.success.message',
              ),
            });
          } else {
            showSentMessageFailedNotification();
          }
        },
        onError: showSentMessageFailedNotification,
      });
    }
  }, [
    companyName,
    form,
    institutionId,
    sendBroadcastTestMessage,
    showNotification,
    t,
    testUserId,
  ]);

  const scheduledStartAtDate: Date | null = useMemo(() => {
    if (scheduledStartAt?.date) {
      const computedDate = set(startOfDay(parseISO(scheduledStartAt.date)), {
        minutes: Number(scheduledStartAt.timeInMinutes ?? 0),
      });

      return computedDate;
    }

    return null;
  }, [
    parseISO,
    startOfDay,
    scheduledStartAt?.date,
    scheduledStartAt?.timeInMinutes,
    set,
  ]);

  const messageCount = useMemo(() => {
    const {
      message,
      optOutMessageLanguage,
      uniqueContacts = 0,
      companyName = '',
    } = form.getValues();

    return uniqueContacts
      ? getBroadcastSmsCounterValue({
          message,
          language: optOutMessageLanguage,
          companyName,
        }) * uniqueContacts
      : 0;
  }, [form]);

  return (
    <FlexColumn data-lgg-id="broadcast-wizard-step-overview">
      <OverviewMessage>
        {t('broadcast:pages.broadcastWizard.steps.overview.summary.title')}
      </OverviewMessage>
      <SummaryContainer>
        <BroadcastSummary
          uniqueContacts={uniqueContacts}
          sender={sender.label}
          messages={messageCount}
          date={scheduledStartAtDate}
          status="DRAFT"
          variant="BROADCAST-WIZARD"
          messageInfoExtraContent={null}
        />
      </SummaryContainer>
      <WizardStepSectionDivider />
      <TestYourBroadcastContainer>
        <WizardStepSectionTitle>
          {t('broadcast:pages.broadcastWizard.steps.overview.testBroadcast.title')}
        </WizardStepSectionTitle>
        <TestBroadcastFormContainer>
          <UserSelectContainer>
            <CompanyUsersSelect
              selectedUsersIds={testUserId ? [testUserId] : []}
              isMulti={false}
              onChange={(option) => {
                setTestUserId(option?.value);
              }}
              isClearable
              forceCaret
              name="test-broadcast-recipient-select"
              label={t(
                'broadcast:pages.broadcastWizard.steps.overview.testBroadcast.form.selectRecipient',
              )}
            />
          </UserSelectContainer>
          <SendBroadcastButton
            variant="ctaGhost"
            loading={sendingBroadcastTestMessage}
            disabled={!testUserId}
            onClick={handleSendTestMessage}
            type="button"
            data-lgg-id="test-broadcast-button"
          >
            {t(
              'broadcast:pages.broadcastWizard.steps.overview.testBroadcast.form.sendMessage',
            )}
          </SendBroadcastButton>
        </TestBroadcastFormContainer>
      </TestYourBroadcastContainer>
    </FlexColumn>
  );
};
