import { useCallback } from 'react';
import { gql, useQuery, ApolloQueryResult } from '@apollo/client';
import { last } from 'lodash';
import {
  Query,
  QueryUsersArgs,
  User,
  UserWhereInput,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';
import { getNodesFromConnection } from 'src/utils/graphql/get-nodes-from-connection';

export const COMPANY_USERS = gql`
  query GetCompanyUsers(
    $institutionId: Int!
    $where: UserWhereInput
    $after: String
    $first: Int
  ) {
    users(institutionId: $institutionId, where: $where, after: $after, first: $first) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        nextCursor
        previousCursor
        startCursor
      }
      edges {
        cursor
        node {
          id
          fullName
          isActive
          role {
            id
          }
          avatar {
            url(transform: { width: 180, height: 180 })
            color
            initials
          }
        }
      }
    }
  }
`;

export type UsersRefetchType = (
  variables?: Partial<Partial<QueryUsersArgs>> | undefined,
) => Promise<ApolloQueryResult<Pick<Query, 'users'>>>;

export type UseCompanyUsersReturn = {
  loading: boolean;
  users: User[];
  refetch: UsersRefetchType;
  loadMore: (variables?: Partial<Partial<QueryUsersArgs>>) => Promise<void>;
  hasNextPage?: boolean;
};

export const useCompanyUsers = (where?: UserWhereInput): UseCompanyUsersReturn => {
  const currentInstitution = useCurrentInstitution();
  const handleGraphQLError = useHandleGraphQLError();
  const { data, loading, refetch, fetchMore } = useQuery<
    Pick<Query, 'users'>,
    Partial<QueryUsersArgs>
  >(COMPANY_USERS, {
    variables: {
      institutionId: currentInstitution.id,
      first: 100,
      where,
    },
    onError: handleGraphQLError,
  });

  const loadMore = useCallback(async () => {
    const lastCursor = last(data?.users.edges)?.cursor;

    if (!lastCursor) {
      return;
    }

    await fetchMore({
      variables: {
        after: lastCursor,
        first: 100,
        where,
      },
    });
  }, [data?.users.edges, fetchMore, where]);

  return {
    users: getNodesFromConnection(data?.users),
    loading,
    refetch,
    loadMore,
    hasNextPage: data?.users?.pageInfo.hasNextPage,
  };
};
