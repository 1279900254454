import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { Schedule } from '@lgg/isomorphic/types/__generated__/graphql';
import { AppointmentStatusSelector } from 'src/components/domain/appointments/appointment-status-selector';
import {
  ItemContainer,
  ItemCreationDate,
  ItemDescription,
  ItemIcon,
  ItemMoreOptions,
  ItemMoreOptionsOption,
  ItemSecondaryInfoLight,
  ItemSecondaryInfoStrong,
  ItemTitle,
} from 'src/components/domain/contacts/contact-modal/tabs/contact-modal-item.shared';
import {
  DropdownButton,
  LggDropdownButtonWithoutOverlayLabel,
} from 'src/components/general/button/dropdown-button';
import { BaseButtonIcon } from 'src/components/general/button/lgg-button';
import { useShowConfirmationModal } from 'src/components/general/feedback/hooks/use-show-confirmation-modal';
import { Icon } from 'src/components/general/icon';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useFormatDate } from 'src/hooks/use-format-date';
import { useUrls } from 'src/hooks/use-urls';
import { useVisible } from 'src/hooks/use-visible';

const ContentColumn = styled(FlexColumn)`
  align-items: flex-start;
  flex: 1;
`;

const AttendeesContainer = styled(FlexRow)`
  flex-wrap: wrap;

  svg {
    height: 14px;
    margin-right: 8px;
    transform: scale(1.5, 1.5);
    width: 14px;

    path {
      fill: ${({ theme }) => theme.colors.flint};
    }
  }
`;

const Attendees = styled(ItemSecondaryInfoLight)`
  font-family: ${({ theme }) => theme.font.medium};
  line-height: 14px;
`;

const StartDateRow = styled(FlexRow)`
  margin-top: 10px;

  ${up('md')} {
    margin-top: 0;
  }
`;

const StatusSelectorContainer = styled.div`
  margin-top: 10px;

  ${up('md')} {
    margin-right: 10px;
    margin-top: 0;

    ${DropdownButton} {
      height: 24px;
      min-width: 120px;
      padding: 0 10px;
    }

    ${LggDropdownButtonWithoutOverlayLabel} {
      font-size: 11px;
      line-height: 13px;
    }

    ${BaseButtonIcon} {
      svg {
        height: 14px;
        width: 14px;
      }
    }
  }
`;

const DesktopBottomRow = styled(FlexRow)`
  align-items: center;
  margin-top: 10px;
  margin-left: 56px;
`;

type AppointmentItemProps = {
  appointment: Schedule;
  onAppointmentDelete: (params: { appointmentId: number }) => void;
};

export const AppointmentItem = memo<AppointmentItemProps>(
  ({ appointment, onAppointmentDelete }) => {
    const { formatLogDate, formatDueDate } = useFormatDate();
    const { getScheduleModalUrl } = useUrls();
    const history = useHistory();
    const [isHovered, setHovered] = useState(false);
    const showConfirmationModal = useShowConfirmationModal();
    const breakpointUpMd = useBreakpoint(up('md'));
    const statusVisibility = useVisible();
    const { t } = useTranslation(['appointments', 'common']);

    const handleEdit = useCallback(() => {
      history.push(getScheduleModalUrl(appointment.id));
    }, [appointment.id, getScheduleModalUrl, history]);

    const handleDelete = useCallback(() => {
      showConfirmationModal({
        title: t('appointments:deleteModal.title'),
        message: t('appointments:deleteModal.body'),
        confirmButtonText: t('appointments:deleteModal.okButton'),
        confirmButtonType: 'delete',
        testId: 'notifications-delete-appointment-confirmation-modal',
        onConfirm: async () => {
          await onAppointmentDelete({ appointmentId: appointment.id });
        },
      });
    }, [onAppointmentDelete, showConfirmationModal, t, appointment.id]);

    const appointmentOptions: ItemMoreOptionsOption[] = useMemo(() => {
      return [
        {
          type: 'edit',
          icon: 'edit',
          'data-lgg-id': 'appointment-item-edit-option',
          label: t('common:edit'),
          onClick: handleEdit,
        },
        {
          type: 'delete',
          icon: 'deleteBin',
          'data-lgg-id': 'appointment-item-delete-option',
          label: t('common:delete'),
          onClick: handleDelete,
        },
      ];
    }, [handleDelete, handleEdit, t]);

    const startDate = useMemo(() => {
      return (
        <StartDateRow>
          <ItemSecondaryInfoStrong data-lgg-id="start-at-label">
            Date:
          </ItemSecondaryInfoStrong>
          <ItemSecondaryInfoLight data-lgg-id="start-at-date">
            {formatDueDate(appointment.startAt)}
          </ItemSecondaryInfoLight>
        </StartDateRow>
      );
    }, [appointment.startAt, formatDueDate]);

    const statusSelector = useMemo(() => {
      return (
        <StatusSelectorContainer data-lgg-id="cta-button">
          <AppointmentStatusSelector
            desktop={{ buttonVariant: 'default' }}
            visibilityHandler={statusVisibility}
            appointment={appointment}
          />
        </StatusSelectorContainer>
      );
    }, [appointment, statusVisibility]);

    return (
      <ItemContainer
        data-lgg-id="contact-modal-appointment-item"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <FlexRow>
          <ItemIcon type="appointment" />
          <ContentColumn>
            <ItemTitle data-lgg-id="appointment-title">{appointment.title}</ItemTitle>
            {appointment.description && (
              <ItemDescription data-lgg-id="appointment-description">
                {appointment.description}
              </ItemDescription>
            )}
            <ItemCreationDate data-lgg-id="creation-date">
              {formatLogDate(appointment.createdAt)}
            </ItemCreationDate>
            <AttendeesContainer data-lgg-id="attendees">
              <Icon type="contacts" key={0} />
              {appointment.attendees.map((attendee, index, array) => {
                const isLast = index === array.length - 1;

                return (
                  <Attendees key={attendee.id}>
                    {'contact' in attendee
                      ? attendee.contact.label
                      : 'user' in attendee
                      ? attendee.user.fullName
                      : attendee.email}
                    {!isLast && ','}
                    &nbsp;
                  </Attendees>
                );
              })}
            </AttendeesContainer>
            {!breakpointUpMd && (
              <>
                {startDate}
                {statusSelector}
              </>
            )}
          </ContentColumn>
          <ItemMoreOptions
            itemId={appointment.id}
            title={t('appointments:appointmentOptions.title')}
            options={appointmentOptions}
            isHovered={isHovered}
          />
        </FlexRow>
        {breakpointUpMd && (
          <DesktopBottomRow>
            {statusSelector}
            {startDate}
          </DesktopBottomRow>
        )}
      </ItemContainer>
    );
  },
);
