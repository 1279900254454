import React, { useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import { $getRoot } from 'lexical';
import styled from 'styled-components';
import { getBroadcastOptOutMessage } from '@lgg/isomorphic/utils/get-broadcast-opt-out-message';
import {
  DropdownButtonIcon,
  LggSelectableOptionsDropdownButtonWithCustomTrigger,
} from 'src/components/general/button/dropdown-button';
import { Scrollbar } from 'src/components/general/display/scrollbar';
import { Icon } from 'src/components/general/icon';
import { TextInput } from 'src/components/general/inputs/text-input';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { BroadcastWizardStepProps } from 'src/components/pages/broadcast/components/broadcast-wizard/broadcast-wizard';
import {
  WizardStepMessage,
  WizardStepSectionTitle,
} from 'src/components/pages/broadcast/components/broadcast-wizard/shared';
import {
  getBroadcastMessageLanguage,
  getBroadcastSmsCounterValue,
  getBroadcastSmsValue,
  OptOutMessageLanguage,
} from 'src/components/pages/broadcast/components/broadcast-wizard/utils';
import { BroadcastMessagePreview } from 'src/components/pages/broadcast/components/shared';
import { SmsCounter } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/channels/sms/sms-counter';
import { EmojiPickerPlugin } from 'src/components/pages/conversations/components/general/lexical-editor/plugins/emoji-picker-plugin';
import { InsertContactPlaceholderPlugin } from 'src/components/pages/conversations/components/general/lexical-editor/plugins/insert-contact-placeholder-plugin';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { useVisible } from 'src/hooks/use-visible';

const lexicalEditorConfig = {
  namespace: 'BroadcastMessageComposeEditor',
  theme: {
    placeholder: 'editor-placeholder',
    paragraph: 'editor-paragraph',
  },
  onError(error) {
    throw error;
  },
};

const EditorBottomBar = styled(FlexRow)`
  border-top: 1px solid rgba(152, 169, 188, 0.251);
  height: 32px;
  justify-content: space-between;
  padding: 8px 10px;
`;

const BaseInput = styled(FlexColumn)<{ hasError: boolean }>`
  border: 1px solid;
  border-color: ${({ theme, hasError }) =>
    hasError ? theme.colors.complementaryColor7 : 'rgba(152, 169, 188, 0.25)'};
  border-radius: 4px;
`;

const EditorContainer = styled(BaseInput)`
  margin-bottom: 17px;
  max-width: 448px;
  width: 100%;
`;

const TextInputContainer = styled.div`
  margin: 10px 12px;
  min-height: 173px;
  position: relative;

  .editor-input {
    max-height: 200px;
    outline: none;
  }

  .editor-paragraph {
    color: ${({ theme }) => theme.colors.smalt};
    font-family: ${({ theme }) => theme.font.regular};
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
`;

const PlaceholderText = styled.span`
  color: ${({ theme }) => theme.colors.geyser};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  pointer-events: none;
  position: absolute;
  text-align: left;
  top: 0;
`;

const PluginsContainer = styled(FlexRow)`
  & > * {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`;

const InputLabel = styled.span`
  color: ${({ theme }) => theme.colors.raven};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 11px;
  line-height: 14px;
  margin-bottom: 1px;
  text-align: left;
`;

const CompanyNameInput = styled(TextInput)`
  margin-bottom: 17px;
`;

const MessagePreviewSectionTitle = styled(WizardStepSectionTitle)`
  margin: 10px 0px 0px;
`;

const MessagePreviewContainer = styled(FlexColumn)`
  width: 238px;

  ${MessagePreviewSectionTitle} {
    margin-bottom: 0 0 10px;
  }
`;

const ComposeMessageStepContainer = styled(FlexRow)`
  justify-content: space-between;
`;

const UnsubscribeMessageInputContainer = styled(BaseInput)`
  align-items: center;
  flex-direction: row;
  min-height: 38px;
  margin-bottom: 5px;
  padding: 0 10px 0 9px;
`;

const OptOutLanguageSelectorButton = styled(
  LggSelectableOptionsDropdownButtonWithCustomTrigger,
)`
  &,
  &:hover,
  &:active {
    background: transparent;
    border: none;
    outline: none;
  }

  height: 20px;
  padding: 5px;
`;

const StyledFlagIcon = styled(Icon)`
  margin-right: 0;

  svg {
    height: 14px;
    width: 14px;
  }
`;

const MessageInputContainer = styled.div`
  position: relative;
  height: 100%;

  .editor-input {
    height: 173px;
  }
`;

const LanguageSelectorContainer = styled(FlexRow)`
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  padding: 5px;

  &:hover {
    background: ${({ theme }) => theme.colors.porcelain};
  }
`;

const LanguageSelectorDropdownIcon = styled(DropdownButtonIcon)<{ $active: boolean }>`
  margin-left: 5px;
  transform: ${({ $active }) => ($active ? 'rotate(180deg) !important' : 'none')};

  svg {
    path {
      fill: ${({ theme }) => theme.colors.casper};
    }
  }
`;

const FormContainer = styled(FlexColumn)`
  margin-right: 20px;
  max-width: 448px;
  width: 100%;
`;

const SmsCountMessageStrongText = styled.span`
  font-family: ${({ theme }) => theme.font.medium};
`;

const BroadcastOptOutMessage = styled.div`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 13px;
  line-height: 16px;
  margin: 10px 0;
  width: 100%;
`;

export const MessageComposeStep = ({ form }: BroadcastWizardStepProps) => {
  const { t } = useTranslation(['common', 'broadcast']);
  const {
    message,
    optOutMessageLanguage: formOptOutMessageLanguage,
    companyName: formCompanyName,
  } = form.getValues();
  const { name: currentCompanyName } = useCurrentInstitution();
  const [messageContent, setMessageContent] = useState<string | null>(message);
  const [optOutMessageLanguage, setOptOutMessageLanguage] =
    useState<OptOutMessageLanguage>(
      getBroadcastMessageLanguage(formOptOutMessageLanguage) ?? OptOutMessageLanguage.EN,
    );
  const [companyName, setCompanyName] = useState<string | null>(
    formCompanyName ?? currentCompanyName,
  );
  const [smsCount, setSmsCount] = useState<number>(
    getBroadcastSmsCounterValue({
      message,
      language: formOptOutMessageLanguage,
      companyName: formCompanyName,
    }),
  );
  const optOutMessageOptionsVisibilityHandler = useVisible();

  useEffect(() => {
    if (!form.getValues().optOutMessageLanguage) {
      form.setValue('optOutMessageLanguage', OptOutMessageLanguage.EN);
    }

    if (!form.getValues().companyName) {
      form.setValue('companyName', currentCompanyName);
    }
  });

  useEffect(() => {
    const formSubscription = form.watch((formValues) => {
      setSmsCount(
        getBroadcastSmsCounterValue({
          language: formValues.optOutMessageLanguage,
          companyName: formValues.companyName,
          message,
        }),
      );

      if (messageContent !== formValues.message) {
        setMessageContent(formValues.message ?? null);
      }

      if (formValues.companyName !== companyName) {
        setCompanyName(formValues.companyName ?? null);
      }
    });

    return () => formSubscription.unsubscribe();
  }, [companyName, currentCompanyName, form, message, messageContent]);

  const initialEditorState = useMemo(() => {
    const message = form.getValues().message;

    if (!message) {
      return undefined;
    }

    const editorState = {
      root: {
        children: [
          {
            children: [
              {
                detail: 0,
                format: 0,
                mode: 'normal',
                style: '',
                text: message,
                type: 'text',
                version: 1,
              },
            ],
            direction: 'ltr',
            format: '',
            indent: 0,
            type: 'paragraph',
            version: 1,
          },
        ],
        direction: 'ltr',
        format: '',
        indent: 0,
        type: 'root',
        version: 1,
      },
    };

    return JSON.stringify(editorState);
  }, [form]);

  return (
    <ComposeMessageStepContainer data-lgg-id="broadcast-wizard-step-compose-sms">
      <FormContainer>
        <InputLabel>
          {t('broadcast:pages.broadcastWizard.steps.smsCompose.fields.textMessage.title')}
        </InputLabel>
        <Controller
          control={form.control}
          name="message"
          rules={{
            required: true,
          }}
          key="broadcast-wizard-message-input"
          render={({ field, fieldState }) => {
            return (
              <EditorContainer hasError={Boolean(fieldState.error)}>
                <LexicalComposer
                  initialConfig={{
                    ...lexicalEditorConfig,
                    editorState: initialEditorState,
                  }}
                >
                  <TextInputContainer>
                    <PlainTextPlugin
                      contentEditable={
                        <MessageInputContainer data-lgg-id="broadcast-wizard-message-input">
                          <Scrollbar>
                            <ContentEditable className="editor-input" />
                          </Scrollbar>
                        </MessageInputContainer>
                      }
                      placeholder={
                        <PlaceholderText>
                          {t(
                            'broadcast:pages.broadcastWizard.steps.smsCompose.fields.textMessage.placeholder',
                          )}
                        </PlaceholderText>
                      }
                      ErrorBoundary={LexicalErrorBoundary}
                    />
                    <OnChangePlugin
                      onChange={(editorState) => {
                        editorState.read(() => {
                          const root = $getRoot();

                          form.setValue('message', root.getTextContent());

                          if (fieldState.error) {
                            form.clearErrors('message');
                          }
                        });
                      }}
                    />
                  </TextInputContainer>
                  <EditorBottomBar>
                    <PluginsContainer>
                      <EmojiPickerPlugin />
                      <InsertContactPlaceholderPlugin />
                    </PluginsContainer>
                    {field.value ? (
                      <SmsCounter
                        message={getBroadcastSmsValue({
                          companyName,
                          language: optOutMessageLanguage,
                          message: field.value,
                        })}
                      />
                    ) : null}
                  </EditorBottomBar>
                </LexicalComposer>
              </EditorContainer>
            );
          }}
        />
        <Controller
          control={form.control}
          name="companyName"
          rules={{
            required: true,
          }}
          render={({ field, fieldState }) => {
            return (
              <CompanyNameInput
                {...field}
                data-lgg-id="broadcast-wizard-company-name-input"
                error={Boolean(fieldState.error)}
                label={t(
                  'broadcast:pages.broadcastWizard.steps.smsCompose.fields.companyName.title',
                )}
                autoComplete="off"
                reserveErrorArea={false}
                showErrorMessage={false}
                showDot={false}
                value={field.value ?? ''}
              />
            );
          }}
        />
        <InputLabel>
          {t(
            'broadcast:pages.broadcastWizard.steps.smsCompose.fields.outOutMessage.title',
          )}
        </InputLabel>
        <Controller
          control={form.control}
          name="optOutMessageLanguage"
          rules={{
            required: true,
          }}
          render={({ field, formState }) => {
            return (
              <UnsubscribeMessageInputContainer
                hasError={Boolean(formState.errors.optOutMessageLanguage)}
              >
                <BroadcastOptOutMessage data-lgg-id="broadcast-wizard-opt-out-message">
                  {field.value ? getBroadcastOptOutMessage(field.value) : undefined}
                </BroadcastOptOutMessage>
                <OptOutLanguageSelectorButton
                  onClick={optOutMessageOptionsVisibilityHandler.show}
                  size="small"
                  variant="default"
                  options={[
                    {
                      label: t('common:languages.english'),
                      value: OptOutMessageLanguage.EN,
                      icon: <Icon type="flagUsa" />,
                      onClick: () => {
                        form.setValue('optOutMessageLanguage', OptOutMessageLanguage.EN);
                        setOptOutMessageLanguage(OptOutMessageLanguage.EN);
                      },
                      'data-lgg-id': 'opt-out-message-language-option-en',
                    },
                    {
                      label: t('common:languages.spanish'),
                      value: OptOutMessageLanguage.ES,
                      icon: <Icon type="flagSpain" />,
                      onClick: () => {
                        form.setValue('optOutMessageLanguage', OptOutMessageLanguage.ES);
                        setOptOutMessageLanguage(OptOutMessageLanguage.ES);
                      },
                      'data-lgg-id': 'opt-out-message-language-option-es',
                    },
                  ]}
                  selectedValue={field.value ?? null}
                  visibilityHandler={optOutMessageOptionsVisibilityHandler}
                >
                  <LanguageSelectorContainer data-lgg-id="broadcast-wizard-opt-out-message-language-dropdown">
                    <StyledFlagIcon
                      lggTestId="opt-out-language-icon"
                      type={
                        field.value === OptOutMessageLanguage.EN ? 'flagUsa' : 'flagSpain'
                      }
                    />
                    <LanguageSelectorDropdownIcon
                      type="arrowdown"
                      $active={optOutMessageOptionsVisibilityHandler.visible}
                    />
                  </LanguageSelectorContainer>
                </OptOutLanguageSelectorButton>
              </UnsubscribeMessageInputContainer>
            );
          }}
        />
        {messageContent ? (
          <WizardStepMessage
            title={
              <Trans
                i18nKey="broadcast:pages.broadcastWizard.steps.smsCompose.smsCountMessage"
                values={{ count: smsCount }}
                components={{ strong: <SmsCountMessageStrongText /> }}
              />
            }
            type="info"
            data-lgg-id="broadcast-wizard-sms-count-message"
          />
        ) : null}
      </FormContainer>
      {messageContent ? (
        <MessagePreviewContainer>
          <MessagePreviewSectionTitle>
            {t('broadcast:pages.broadcastWizard.steps.smsCompose.messagePreview')}
          </MessagePreviewSectionTitle>
          <BroadcastMessagePreview
            message={messageContent}
            optOutMessage={getBroadcastOptOutMessage(optOutMessageLanguage)}
            companyName={companyName}
          />
        </MessagePreviewContainer>
      ) : null}
    </ComposeMessageStepContainer>
  );
};
