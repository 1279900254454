import React, { memo } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { ProfileSettingsModal } from 'src/components/domain/users/components/profile-settings-modal/profile-settings-modal';
import { AuthorizedLayout } from 'src/components/layout/authenticated-layout/authorized-layout';
import { AgencyPage } from 'src/components/pages/agency/agency.page';
import { CompanyPage } from 'src/components/pages/company/company.page';
import { useSetLggConfig } from 'src/components/pages/legacy/hooks/use-set-lgg-config';
import { OrganizationPage } from 'src/components/pages/organization/organization.page';
import { useOpenNotification } from 'src/hooks/push-notifications/use-open-notification';
import { useInitializeAuthorization } from 'src/services/authorization.service';

export const IndustrySwitch = memo(() => {
  const { institutionId: strId } = useParams<{
    institutionType: string;
    institutionId: string;
  }>();

  const institutionId = Number(strId);

  useInitializeAuthorization(institutionId);

  useSetLggConfig();
  useOpenNotification();

  return (
    <AuthorizedLayout>
      <Switch>
        <Route path="/i/company/:institutionId">
          <CompanyPage />
        </Route>
        <Route path="/i/agency/:institutionId">
          <AgencyPage />
        </Route>
        <Route path="/i/organization/:institutionId">
          <OrganizationPage />
        </Route>
      </Switch>
      <ProfileSettingsModal />
    </AuthorizedLayout>
  );
});
