import { RewriteFrames as RewriteFramesIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { Event, EventHint } from '@sentry/react';
import { excludeGraphQLFetch } from 'apollo-link-sentry';
import { sentryIdSymbol } from 'src/services/error.service';
import { AuthenticationError } from 'src/utils/errors/authentication-error';
import { AuthorizationError } from 'src/utils/errors/authorization-error';
import { NetworkError } from 'src/utils/errors/network-error';
import { NotFoundError } from 'src/utils/errors/not-found-error';

Sentry.init({
  dsn: 'https://b75154858f32415ab2fe384dbaaebcdb@o503960.ingest.sentry.io/5954269',
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  beforeBreadcrumb: excludeGraphQLFetch,
  integrations: (defaultIntegrations) => {
    return [
      ...defaultIntegrations,
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: false,
      }),
      new RewriteFramesIntegration({
        iteratee: (frame) => {
          if (frame.filename) {
            try {
              // use try/catch since new URL will fail with filename === <anonymous> or other
              const { origin, pathname } = new URL(frame.filename);
              if (origin === window.location.origin && pathname.startsWith('/assets/')) {
                frame.filename = `app:///user-web-app/${pathname.split('/assets/')[1]}`;
              }
            } catch {}
          }
          return frame;
        },
      }),
    ];
  },

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,
  // https://github.com/getsentry/sentry-javascript/issues/2539
  // https://github.com/getsentry/sentry-javascript/issues/1964#issuecomment-625353135
  normalizeDepth: 15,
  maxValueLength: 2_000, // (cortaba los mensajes de error) https://docs.sentry.io/platforms/node/configuration/options/#max-value-length
  enabled: import.meta.env.MODE === 'production',
  environment: import.meta.env.VITE_DEPLOYMENT_ENVIRONMENT,
  release: import.meta.env.VITE_BUILD_VERSION,
  beforeSend: (event: Event, hint?: EventHint) => {
    const originalException = hint?.originalException as undefined | Error;

    if (
      originalException instanceof AuthenticationError ||
      originalException instanceof AuthorizationError ||
      originalException instanceof NetworkError ||
      originalException instanceof NotFoundError
    ) {
      return null;
    }

    if (originalException?.[sentryIdSymbol]) {
      return null;
    }

    // Ignore any error that matches the following conditions
    for (const exception of event.exception?.values || []) {
      const message = exception.value || '';

      // This is caused by ResizeObserver can not handle all the observations in one single frame.
      // This error is safe to ignore and Chrome not throw this error in console unless add global error listener.
      // https://github.com/ant-design/ant-design/issues/15075, https://github.com/petyosi/react-virtuoso/issues/254
      if (message.startsWith('ResizeObserver loop')) {
        return null;
      }

      // OpenRelay error
      // https://github.com/openreplay/openreplay/blob/9fff165e9d62e6bfd6ec48adb0740d9fc8a2c914/tracker/tracker/src/webworker/index.ts#L108
      if (message.match(/WebWorker: (writer|sender) not initialised\./i)) {
        return null;
      }
    }

    //Send the event to Sentry
    return event;
  },
});
