import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { match } from 'ts-pattern';
import {
  Mutation,
  MutationActivateInstagramResourceArgs,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { useShowNotification } from 'src/components/general/feedback/hooks/use-show-notification';
import { ACTIVATE_INSTAGRAM_RESOURCE_MUTATION } from 'src/components/pages/instagram-channel/shared/queries';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { useCurrentUserId } from 'src/hooks/use-current-user';
import { openPopup } from 'src/utils/open-popup';

export const useActivateInstagramResource = () => {
  return useMutation<
    Pick<Mutation, 'activateInstagramResource'>,
    MutationActivateInstagramResourceArgs
  >(ACTIVATE_INSTAGRAM_RESOURCE_MUTATION, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });
};

export const useInstagramResourceActivation = () => {
  const { t } = useTranslation(['instagramChannel']);
  const { id: companyId } = useCurrentInstitution();
  const userId = useCurrentUserId();
  const showNotification = useShowNotification();

  const [activateInstagramResource, { loading: isActivatingResource }] =
    useActivateInstagramResource();

  const handleActivateInstagramResource = useCallback(
    (code: string) => {
      showNotification({
        title: t('instagramChannel:activateResource.warning.title'),
        message: t('instagramChannel:activateResource.warning.message'),
        type: 'warning',
      });

      void activateInstagramResource({
        variables: { code, companyId, userId },
        onCompleted: async () => {
          showNotification({
            title: t('instagramChannel:activateResource.success.title'),
            message: t('instagramChannel:activateResource.success.message'),
            type: 'success',
          });
        },
        onError: (error) => {
          const firstError = error.graphQLErrors[0];

          const description = match(firstError)
            .with(
              {
                extensions: {
                  detail: {
                    http: {
                      response: {
                        data: {
                          meta: {
                            errorMeta: {
                              code: 'RESOURCE_INSTAGRAM_COMPANY_CONFLICT',
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
              () => t('instagramChannel:activateResource.error.companyConflict'),
            )
            .otherwise(() => t('instagramChannel:activateResource.error.general'));

          showNotification({
            title: t('instagramChannel:activateResource.error.title'),
            message: description,
            type: 'error',
          });
        },
      });
    },
    [showNotification, t, activateInstagramResource, companyId, userId],
  );

  const history = useHistory();
  const location = useLocation();

  const processInstagramAuthentication = useCallback(
    (code?: string, error?: { message: string; description: string; reason: string }) => {
      if (code) {
        history.push(`${location.pathname}?code=${code}`);
        handleActivateInstagramResource(code);
      } else if (error) {
        const { message, description, reason } = error;

        showNotification({
          title: message,
          message: reason + ': ' + description,
          type: 'error',
        });
      }
    },
    [handleActivateInstagramResource, history, showNotification, location.pathname],
  );

  useEffect(() => {
    const channel = new BroadcastChannel('instagram_auth');

    channel.onmessage = (event) => {
      const code = event.data?.code;
      const error = event.data?.error;

      if (event.data?.type === 'instagram_auth' && (code || error)) {
        processInstagramAuthentication(code, error);
      }
    };

    return () => channel.close(); // Clean up the channel
  }, [processInstagramAuthentication]);

  // Function to handle Instagram permission granting workflow
  const launchInstagramPermissionGrant = useCallback(async () => {
    openPopup({
      width: 600,
      height: 600,
      url: `https://www.instagram.com/oauth/authorize?client_id=${
        import.meta.env.VITE_INSTAGRAM_APP_ID
      }&redirect_uri=${
        window.location.origin
        // https://developers.facebook.com/docs/instagram-platform/instagram-api-with-instagram-login/business-login
      }/instagram/auth/&response_type=code&scope=instagram_business_basic%2Cinstagram_business_manage_messages`,
      name: 'instagram',
    });
  }, []);

  return { launchInstagramPermissionGrant, isActivatingResource };
};
