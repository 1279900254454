import React, { memo, useCallback, useMemo, ReactNode } from 'react';
import notification from 'antd/lib/notification';
import { up } from 'styled-breakpoints';
import styled, {
  DefaultTheme,
  createGlobalStyle,
  ThemeProvider,
  useTheme,
} from 'styled-components';
import { ColorPaletteItem } from '@lgg/isomorphic';
import { AlertType, getAlertIconByType } from 'src/components/general/feedback/shared';
import { Icon } from 'src/components/general/icon';
import { notifications as notificationStylesOverwrite } from 'src/theme/globals/antd4-override-global-style';

const Title = styled.span`
  color: ${({ theme }) => theme.colors.carbonBlue};
  display: block;
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;

  ${up('md')} {
    font-size: 14px;
  }
`;

const Message = styled.p`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 5px;
  text-align: left;

  ${up('md')} {
    font-size: 13px;
  }
`;

const ActionElements = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  width: 100%;
  font-family: ${({ theme }) => theme.font.medium};
`;

export const NotificationStyle = createGlobalStyle<{ theme: DefaultTheme }>`
  ${notificationStylesOverwrite}
`;

const CloseIcon = styled(Icon)`
  svg {
    height: 14px;
    width: 14px;
  }

  path + path {
    fill: rgba(152, 169, 188, 0.5);
  }
`;

const IconContainer = styled.div<{ color?: ColorPaletteItem }>`
  svg path {
    ${({ color, theme }) => color && `fill: ${theme.colors[color]};`};
  }
`;

export type ShowNotificationProps = {
  customIcon?: string;
  iconColor?: ColorPaletteItem;
  key?: string;
  message?: ReactNode;
  title: ReactNode;
  type: AlertType;
  duration?: number | null;
  actionElements?: ReactNode;
};

export const useShowNotification = () => {
  const theme = useTheme();

  return useCallback(
    ({
      type,
      title,
      message,
      key,
      customIcon,
      duration = 4.5,
      iconColor,
      actionElements,
    }: ShowNotificationProps) => {
      notification.open({
        key,
        message: (
          <ThemeProvider theme={theme}>
            <Title data-lgg-id="notification-title">{title}</Title>
            <NotificationStyle />
          </ThemeProvider>
        ),
        duration,
        description:
          message || actionElements ? (
            <ThemeProvider theme={theme}>
              {message ? (
                <Message data-lgg-id="notification-message">{message}</Message>
              ) : null}
              {actionElements ? <ActionElements>{actionElements}</ActionElements> : null}
            </ThemeProvider>
          ) : null,
        closeIcon: (
          <CloseIcon type="close" data-lgg-id="notification-message-close-icon" />
        ),
        placement: 'bottomRight',
        className: `lgg-${type}-notification`,
        icon: (
          <ThemeProvider theme={theme}>
            <IconContainer color={iconColor}>
              <Icon type={customIcon ?? getAlertIconByType(type)} />
            </IconContainer>
          </ThemeProvider>
        ),
      });
    },
    [theme],
  );
};

export const NotificationContext = React.createContext<{
  openNotification: (options: ShowNotificationProps) => void;
  closeNotification: (key: string) => void;
}>({ openNotification: () => {}, closeNotification: () => {} });

export const NotificationProvider = memo(({ children }) => {
  const [notificationApi, contextHolder] = notification.useNotification();

  const openNotification = useCallback(
    ({
      type,
      title,
      message,
      key,
      customIcon,
      duration = 4.5,
      iconColor,
      actionElements,
    }: ShowNotificationProps) => {
      notificationApi.open({
        key,
        message: <Title data-lgg-id="notification-title">{title}</Title>,
        duration,
        description:
          message || actionElements ? (
            <>
              {message ? (
                <Message data-lgg-id="notification-message">{message}</Message>
              ) : null}
              {actionElements ? <ActionElements>{actionElements}</ActionElements> : null}
            </>
          ) : null,
        closeIcon: <CloseIcon type="close" />,
        placement: 'bottomRight',
        className: `lgg-${type}-notification`,
        icon: (
          <>
            <IconContainer color={iconColor}>
              <Icon type={customIcon ?? getAlertIconByType(type)} />
            </IconContainer>
          </>
        ),
      });
    },
    [notificationApi],
  );

  const closeNotification = useCallback((key: string) => {
    notification.close(key);
  }, []);

  const context = useMemo(
    () => ({ openNotification, closeNotification }),
    [closeNotification, openNotification],
  );

  return (
    <NotificationContext.Provider value={context}>
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
});
