import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { isEmpty, omit } from 'lodash';
import QueryString from 'qs';
import { TableSortData } from 'src/components/general/table-helpers';
import { useQueryParams } from 'src/hooks/use-query-params';
import { stringifyQueryParams } from 'src/utils/stringify-query-params';

type BaseTablePageProps = {
  table?: string;
  after?: string;
  before?: string;
};

type PaginationProps = {
  after?: string;
  before?: string;
};

export const useTableLayoutQueryParams = <ResultantWhere, ExpectedFilters>(props: {
  defaultSortData: TableSortData;
  defaultFilters?: Partial<ExpectedFilters>;
  variablesResolver: (
    filters: ExpectedFilters & PaginationProps,
    sortData: TableSortData,
    paginationData?: ExpectedFilters,
  ) => ResultantWhere;
  filtersResolver: (params: QueryString.ParsedQs) => ExpectedFilters;
}): {
  sortData: TableSortData;
  handleSortChange: (params: { key?: string; direction?: SortDirection }) => void;
  handleNextPage: (params: { endCursor: string }) => void;
  handlePreviousPage: (params: { startCursor: string }) => void;
  variables: ResultantWhere;
  filters: ExpectedFilters;
  hasActiveFilters: boolean;
} => {
  const { defaultSortData, variablesResolver, filtersResolver, defaultFilters } = props;
  const history = useHistory();
  const location = useLocation();
  const queryParams: BaseTablePageProps = useQueryParams();

  const sortData = useMemo(() => {
    const tableQueryParam = queryParams.table;

    if (tableQueryParam && typeof tableQueryParam === 'string') {
      const sortTokens = tableQueryParam.split('|');
      const key = sortTokens[1];
      const direction = sortTokens[3] as SortDirection;

      return {
        key,
        direction,
      };
    }

    return defaultSortData;
  }, [defaultSortData, queryParams.table]);

  const filters = useMemo(
    () => ({
      ...defaultFilters,
      ...filtersResolver(queryParams),
    }),
    [defaultFilters, filtersResolver, queryParams],
  );

  const where = useMemo(
    () => variablesResolver({ ...filters, ...queryParams }, sortData),
    [filters, queryParams, sortData, variablesResolver],
  );

  const handleSortChange = useCallback(
    (params: { key?: string; direction?: SortDirection }) => {
      const { key, direction } = params;
      const baseExcludeSortParams = ['before', 'after', 'table'];

      const cleanQueryParams = omit(queryParams, baseExcludeSortParams);

      if (key && direction) {
        const newUrl = stringifyQueryParams({
          ...cleanQueryParams,
          table: `sort|${key}|direction|${direction}`,
        });

        history.push(`${location.pathname}?${newUrl}`);
      } else {
        history.push(`${location.pathname}?${stringifyQueryParams(cleanQueryParams)}`);
      }
    },
    [history, location.pathname, queryParams],
  );

  const handleNextPage = useCallback(
    (params: { endCursor: string }) => {
      const { endCursor } = params;
      const baseExcludePaginationParams = ['before', 'after'];
      const cleanQueryParams = omit(queryParams, baseExcludePaginationParams);
      history.push(
        `${location.pathname}?${stringifyQueryParams({
          ...cleanQueryParams,
          after: endCursor,
        })}`,
      );
    },
    [history, location.pathname, queryParams],
  );

  const handlePreviousPage = useCallback(
    (params: { startCursor: string }) => {
      const { startCursor } = params;
      const baseExcludePaginationParams = ['before', 'after'];
      const cleanQueryParams = omit(queryParams, baseExcludePaginationParams);
      history.push(
        `${location.pathname}?${stringifyQueryParams({
          ...cleanQueryParams,
          before: startCursor,
        })}`,
      );
    },
    [history, location.pathname, queryParams],
  );

  return {
    sortData,
    handleSortChange,
    handleNextPage,
    handlePreviousPage,
    variables: where,
    filters,
    hasActiveFilters: !isEmpty(filters),
  };
};
