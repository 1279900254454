import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { Task, TaskStatus } from '@lgg/isomorphic/types/__generated__/graphql';
import {
  ItemContainer,
  ItemCreationDate,
  ItemDescription,
  ItemIcon,
  ItemMoreOptions,
  ItemMoreOptionsOption,
  ItemSecondaryInfoLight,
  ItemSecondaryInfoStrong,
  ItemTitle,
} from 'src/components/domain/contacts/contact-modal/tabs/contact-modal-item.shared';
import { ButtonV2, ButtonV2Props } from 'src/components/general/button/lgg-button';
import { useShowConfirmationModal } from 'src/components/general/feedback/hooks/use-show-confirmation-modal';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useFormatDate } from 'src/hooks/use-format-date';
import { useUrls } from 'src/hooks/use-urls';

const Divider = styled(ItemSecondaryInfoLight)`
  margin: 0 6px;
`;

const AssignedToRow = styled(FlexRow)`
  margin-top: 7px;

  ${up('md')} {
    margin-top: 0;
  }
`;

const ContentColumn = styled(FlexColumn)`
  align-items: flex-start;
  flex: 1;
`;

const DesktopBottomRow = styled(FlexRow)`
  align-items: center;
  margin-left: 56px;
`;

const CtaButton = styled(ButtonV2)`
  font-size: 11px;
  height: 24px;
  margin-top: 10px;
  width: unset;

  && svg {
    height: 14px;
    width: 14px;
  }

  ${up('md')} {
    margin-right: 10px;
    margin-top: 0;
  }
`;

const MarkAsDoneButton = styled(CtaButton)`
  && svg path {
    fill: ${({ theme }) => theme.colors.cosmo};
  }
`;

type TaskItemProps = {
  task: Task;
  onTaskStatusChange: (params: { taskId: number; taskStatus: TaskStatus }) => void;
  onTaskDelete: (params: { taskId: number }) => void;
};

export const TaskItem = memo<TaskItemProps>(
  ({ task, onTaskStatusChange, onTaskDelete }) => {
    const { formatLogDate, formatDueDate } = useFormatDate();
    const { getTaskModalUrl } = useUrls();
    const history = useHistory();
    const [isHovered, setHovered] = useState(false);
    const { getFeatureFlag } = useAuthorization();
    const showConfirmationModal = useShowConfirmationModal();
    const breakpointUpMd = useBreakpoint(up('md'));
    const { t } = useTranslation(['tasks', 'common']);

    const handleEdit = useCallback(() => {
      history.push(getTaskModalUrl(task.id));
    }, [getTaskModalUrl, history, task.id]);

    const handleDelete = useCallback(() => {
      showConfirmationModal({
        title: t('tasks:deleteModal.title'),
        message: t('tasks:deleteModal.body'),
        confirmButtonText: t('tasks:deleteModal.okButton'),
        confirmButtonType: 'delete',
        testId: 'notifications-delete-task-confirmation-modal',
        onConfirm: async () => {
          await onTaskDelete({ taskId: task.id });
        },
      });
    }, [onTaskDelete, showConfirmationModal, t, task.id]);

    const statusLabel = useMemo(() => {
      if (task.status === 'COMPLETED' && task.completedAt) {
        return (
          <FlexRow>
            <ItemSecondaryInfoStrong data-lgg-id="status-label">
              {t('tasks:status.completed')}:
            </ItemSecondaryInfoStrong>{' '}
            <ItemSecondaryInfoLight data-lgg-id="status-date">
              {formatDueDate(task.completedAt)}
            </ItemSecondaryInfoLight>
          </FlexRow>
        );
      } else {
        return (
          <FlexRow>
            <ItemSecondaryInfoStrong data-lgg-id="status-label">
              {t('tasks:tableTitles.due')}:
            </ItemSecondaryInfoStrong>
            <ItemSecondaryInfoLight data-lgg-id="status-date">
              {formatDueDate(task.dueAt)}
            </ItemSecondaryInfoLight>
          </FlexRow>
        );
      }
    }, [formatDueDate, t, task.completedAt, task.dueAt, task.status]);

    const assignedToLabel = useMemo(() => {
      if (task.assignedTo) {
        return (
          <AssignedToRow>
            <ItemSecondaryInfoStrong>
              {t('tasks:tableTitles.assignedTo')}:
            </ItemSecondaryInfoStrong>
            <ItemSecondaryInfoLight data-lgg-id="assignee-full-name">
              {task.assignedTo.fullName}
            </ItemSecondaryInfoLight>
          </AssignedToRow>
        );
      }
    }, [t, task.assignedTo]);

    const cta = useMemo(() => {
      const hasCrmFeature = getFeatureFlag('crm');
      const sharedProps: Pick<ButtonV2Props, 'disabled' | 'icon' | 'size'> & {
        'data-lgg-id': string;
      } = {
        disabled: !hasCrmFeature,
        icon: 'taskCompleted',
        size: 'small',
        'data-lgg-id': 'cta-button',
      };

      if (task.status === 'COMPLETED') {
        return (
          <CtaButton
            {...sharedProps}
            variant="cta"
            onClick={() => {
              onTaskStatusChange({ taskId: task.id, taskStatus: 'YET_TO_START' });
            }}
          >
            {t('tasks:status.completed')}
          </CtaButton>
        );
      } else {
        return (
          <MarkAsDoneButton
            {...sharedProps}
            variant="defaultGhost"
            onClick={() => {
              onTaskStatusChange({ taskId: task.id, taskStatus: 'COMPLETED' });
            }}
          >
            {t('tasks:actions.markAsDone')}
          </MarkAsDoneButton>
        );
      }
    }, [getFeatureFlag, onTaskStatusChange, t, task.id, task.status]);

    const taskOptions: ItemMoreOptionsOption[] = useMemo(() => {
      return [
        {
          type: 'edit',
          icon: 'edit',
          'data-lgg-id': 'task-item-edit-option',
          label: t('common:edit'),
          onClick: handleEdit,
        },
        {
          type: 'delete',
          icon: 'deleteBin',
          'data-lgg-id': 'task-item-delete-option',
          label: t('common:delete'),
          onClick: handleDelete,
        },
      ];
    }, [handleDelete, handleEdit, t]);

    return (
      <ItemContainer
        data-lgg-id="contact-modal-task-item"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <FlexRow>
          <ItemIcon type="task" />
          <ContentColumn>
            <ItemTitle data-lgg-id="task-title">{task.title}</ItemTitle>
            {task.description && (
              <ItemDescription data-lgg-id="task-description">
                {task.description}
              </ItemDescription>
            )}
            <ItemCreationDate data-lgg-id="creation-date">
              {formatLogDate(task.createdAt)}
            </ItemCreationDate>
            {!breakpointUpMd && (
              <>
                {statusLabel}
                {assignedToLabel}
                {cta}
              </>
            )}
          </ContentColumn>
          <ItemMoreOptions
            itemId={task.id}
            options={taskOptions}
            title={t('tasks:taskOptions.title')}
            isHovered={isHovered}
          />
        </FlexRow>
        {breakpointUpMd && (
          <DesktopBottomRow>
            {cta}
            {statusLabel}
            {assignedToLabel && (
              <>
                <Divider>/</Divider>
                {assignedToLabel}
              </>
            )}
          </DesktopBottomRow>
        )}
      </ItemContainer>
    );
  },
);
