import React, { memo, useEffect } from 'react';
import { up } from 'styled-breakpoints';
import { useErrorHandling } from 'src/components/error-boundary/error-boundary';
import { LoadingIndicator } from 'src/components/loading-fallback';
import { useBreakpoint } from 'src/hooks/use-breakpoint';

export const InstagramAuthPage = memo(() => {
  const breakpointUpMd = useBreakpoint(up('md'));
  const { triggerError } = useErrorHandling();

  /*
  
    Los oAuth redirect URLs de Instagram son estáticos (https://app.lgglocal.com/instagram/auth/). 
    No podemos pasarle wildcards como https://app.lgglocal.com/organization/#/i/company/xxx.

    Lo que hace esta ruta, es decirle a Instagram que nos re-dirija a ella, 
    mostramos un loading state por un corto tiempo, mientras se cierra el modal 
    y se dispara la acción de activar la cuenta.

    La función de la ruta, es solo tomar el código que nos brinda instagram en el URL,
     y devolverlo al handler que está haciendo la llamada desde el window padre.

    */

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    const error = params.get('error');

    if (code || error) {
      const channel = new BroadcastChannel('instagram_auth');

      if (code) {
        channel.postMessage({ type: 'instagram_auth', code });
      } else {
        channel.postMessage({
          type: 'instagram_auth',
          error: {
            message: error,
            description: params.get('error_description'),
            reason: params.get('error_reason'),
          },
        });
      }

      window.close(); // Close the popup
    }
  }, [breakpointUpMd, triggerError]);

  return <LoadingIndicator />;
});
