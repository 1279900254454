import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'antd';
import c from 'classnames';
import styled from 'styled-components';
import { BroadcastDiscardedContactsObject } from '@lgg/isomorphic/types/__generated__/graphql';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { BroadcastInfoIcon } from 'src/components/pages/broadcast/components/shared';

const SentBreakDownDetailsContainer = styled(FlexColumn)`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.koala};
  height: 215px;
  padding: 15px;
  text-align: left;
  width: 312px;
`;

const SentBreakDownDetailsTitle = styled.p`
  color: ${({ theme }) => theme.colors.carbon};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 18px;
  margin: 0 0 15px;
`;

const BreakDownDetailText = styled.span`
  color: ${({ theme }) => theme.colors.steel};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 18px;
  text-align: right;
`;

const BreakDownDetailContainer = styled(FlexRow)`
  justify-content: space-between;

  & + & {
    margin-top: 2px;
  }
`;

const StyledBroadcastInfoIcon = styled(BroadcastInfoIcon)`
  &.small {
    height: 10px;
    width: 10px;
  }

  &.regular {
    height: 12px;
    width: 12px;
  }
`;

const BreakDownDetail = ({
  title,
  value,
  testId,
  ...rest
}: {
  title: string;
  value: number;
  testId: string;
}) => {
  return (
    <BreakDownDetailContainer {...rest}>
      <BreakDownDetailText>{title}</BreakDownDetailText>
      <BreakDownDetailText data-lgg-id={testId}>
        {value.toLocaleString()}
      </BreakDownDetailText>
    </BreakDownDetailContainer>
  );
};

const BreakDownSubtitle = styled(BreakDownDetail)`
  border-top: 1px solid ${({ theme }) => theme.colors.porcelain2};
  padding: 6px 0 1px;
  margin: 8px 0 3px;

  ${BreakDownDetailText} {
    color: ${({ theme }) => theme.colors.smalt};
    font-family: ${({ theme }) => theme.font.medium};
    font-size: 12px;
    letter-spacing: 0px;
    line-height: 18px;
  }
`;

export type BroadcastSentBreakDownPopoverProps = {
  iconSize: 'small' | 'regular';
  popoverPlacement: 'left' | 'right';
  discardedContacts: BroadcastDiscardedContactsObject;
  sent: number;
  audience: number;
};

export const BroadcastSentBreakDownPopover = ({
  iconSize = 'small',
  popoverPlacement = 'left',
  discardedContacts,
  sent,
  audience,
}: BroadcastSentBreakDownPopoverProps) => {
  const { t } = useTranslation(['broadcast']);
  const getDiscardedListItem = (value: string, includeDot: boolean = false) =>
    `${includeDot ? '•  ' : ''}${value}:`;
  const { invalidMessageContent, invalidPhoneNumber, optedOut, withoutPhoneNumber } =
    discardedContacts;

  const discardedMessagesCount = useMemo(
    () =>
      [invalidMessageContent, invalidPhoneNumber, optedOut, withoutPhoneNumber].reduce(
        (acc, value) => acc + value,
      ),
    [invalidMessageContent, invalidPhoneNumber, optedOut, withoutPhoneNumber],
  );

  const pendingToSendCount = useMemo(
    () => audience - discardedMessagesCount - sent,
    [audience, discardedMessagesCount, sent],
  );

  const popoverContent = useMemo(
    () => (
      <SentBreakDownDetailsContainer data-lgg-id="broadcast-sent-breakdown-popover">
        <SentBreakDownDetailsTitle>
          {t('broadcast:pages.broadcast.broadcastSentBreakdown.title')}
        </SentBreakDownDetailsTitle>
        <BreakDownDetail
          testId="sent-breakdown-info-sent"
          title={getDiscardedListItem(
            t('broadcast:pages.broadcast.broadcastSentBreakdown.sentSuccessfully'),
          )}
          value={sent}
        />
        <BreakDownDetail
          testId="sent-breakdown-info-pending"
          title={getDiscardedListItem(
            t('broadcast:pages.broadcast.broadcastSentBreakdown.pendingToSend'),
          )}
          value={pendingToSendCount}
        />
        <BreakDownSubtitle
          testId="sent-breakdown-info-discarded"
          title={getDiscardedListItem(
            t('broadcast:pages.broadcast.broadcastSentBreakdown.discarded.title'),
          )}
          value={discardedMessagesCount}
        />
        <BreakDownDetail
          testId="sent-breakdown-info-without-phone"
          title={getDiscardedListItem(
            t(
              'broadcast:pages.broadcast.broadcastSentBreakdown.discarded.contactsWithoutPhoneNumber',
            ),
            true,
          )}
          value={withoutPhoneNumber}
        />
        <BreakDownDetail
          testId="sent-breakdown-info-opted-out"
          title={getDiscardedListItem(
            t(
              'broadcast:pages.broadcast.broadcastSentBreakdown.discarded.contactOptedOut',
            ),
            true,
          )}
          value={optedOut}
        />
        <BreakDownDetail
          testId="sent-breakdown-info-invalid-phone"
          title={getDiscardedListItem(
            t(
              'broadcast:pages.broadcast.broadcastSentBreakdown.discarded.invalidPhoneNumber',
            ),
            true,
          )}
          value={invalidPhoneNumber}
        />
        <BreakDownDetail
          testId="sent-breakdown-info-invalid-content"
          title={getDiscardedListItem(
            t(
              'broadcast:pages.broadcast.broadcastSentBreakdown.discarded.invalidMessageContent',
            ),
            true,
          )}
          value={invalidMessageContent}
        />
      </SentBreakDownDetailsContainer>
    ),
    [
      discardedMessagesCount,
      invalidMessageContent,
      invalidPhoneNumber,
      optedOut,
      pendingToSendCount,
      sent,
      t,
      withoutPhoneNumber,
    ],
  );

  return (
    <Dropdown
      overlay={popoverContent}
      trigger={['click']}
      placement={popoverPlacement === 'left' ? 'bottomLeft' : 'bottomRight'}
      align={{
        offset: popoverPlacement === 'left' ? [-9, 6] : [9, 6],
      }}
    >
      <StyledBroadcastInfoIcon
        type="info"
        className={c({ [iconSize]: true })}
        data-lgg-id="broadcast-send-breakdown-icon"
      />
    </Dropdown>
  );
};
