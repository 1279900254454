import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { match, P } from 'ts-pattern';
import {
  Broadcast,
  BroadcastListWhereInput,
  BroadcastStatus,
  Query,
  QueryBroadcastsArgs,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { LggOptionsDropdownButton } from 'src/components/general/button/dropdown-button';
import { FiltersButton } from 'src/components/general/button/filters-button';
import { PaginationInfo } from 'src/components/general/display/pagination-info';
import { TablePagination } from 'src/components/general/display/table-pagination';
import { TableLayoutPlaceholder } from 'src/components/general/feedback/loading-placeholders';
import {
  getSortDirectionByOrder,
  SorterData,
  TableLayoutHeader,
} from 'src/components/general/table-helpers';
import { PageContainer } from 'src/components/layout/page-containers';
import { TableLayoutPageContainer } from 'src/components/layout/table-layout-page-container';
import {
  BroadcastFilters,
  broadcastFilterSchema,
  BroadcastFiltersFormValues,
} from 'src/components/pages/broadcast/components/broadcast-filters';
import { BroadcastTable } from 'src/components/pages/broadcast/components/broadcast-table';
import { GET_BROADCAST_LIST } from 'src/components/pages/broadcast/queries';
import { useCurrentInstitution } from 'src/hooks/use-current-institution';
import { useTableLayoutQueryParams } from 'src/hooks/use-table-layout-query-params';
import { useVisible } from 'src/hooks/use-visible';

const QUERY_PAGE_SIZE = 50;

export const BroadcastListPage = () => {
  const { t } = useTranslation(['broadcast', 'common']);
  const filtersVisibilityHandler = useVisible();
  const exportVisibilityHandler = useVisible();
  const { id: institutionId } = useCurrentInstitution();

  const {
    handleNextPage,
    handlePreviousPage,
    handleSortChange,
    sortData,
    variables,
    filters,
    hasActiveFilters,
  } = useTableLayoutQueryParams<Partial<QueryBroadcastsArgs>, BroadcastFiltersFormValues>(
    {
      defaultSortData: {
        direction: 'DESC',
        key: 'scheduledAt',
      },
      defaultFilters: {
        archived: '0',
      },
      variablesResolver: (params, sortData) => {
        const where: BroadcastListWhereInput = {};

        if (params.archived) {
          if (params.archived === '1') {
            where.isArchived = {
              _eq: true,
            };
          }

          if (params.archived === '0') {
            where.isArchived = {
              _eq: false,
            };
          }
        }

        if (params.keywords && params.keywords.length > 0) {
          where.name = {
            _contains: params.keywords,
          };
        }

        if (params.status) {
          const statuses: BroadcastStatus[] = [];

          params.status.forEach((status) => {
            if (status === 'IN_PROGRESS') {
              statuses.push('IN_PROGRESS_PREPARING', 'IN_PROGRESS_SENDING');
            } else {
              statuses.push(status);
            }
          });

          where.status = {
            _in: statuses,
          };
        }

        const paginationVariables = match(params)
          .with(
            {
              before: P.not(P.nullish),
            },
            ({ before }) => ({
              before,
              last: QUERY_PAGE_SIZE,
            }),
          )
          .with(
            {
              after: P.not(P.nullish),
            },
            ({ after }) => ({
              after,
              first: QUERY_PAGE_SIZE,
            }),
          )
          .otherwise(() => ({ first: QUERY_PAGE_SIZE }));

        return {
          ...paginationVariables,
          companyId: institutionId,
          where,
          orderBy: {
            [sortData.key]: sortData.direction,
          },
        };
      },
      filtersResolver: broadcastFilterSchema.parse,
    },
  );

  const {
    data: broadcastList,
    loading: loadingBroadcastList,
    refetch: refetchBroadcastList,
  } = useQuery<Pick<Query, 'broadcasts'>, Partial<QueryBroadcastsArgs>>(
    GET_BROADCAST_LIST,
    {
      variables,
    },
  );

  if (!broadcastList || loadingBroadcastList) {
    return (
      <TableLayoutPageContainer>
        <TableLayoutPlaceholder />
      </TableLayoutPageContainer>
    );
  }

  const broadcasts = broadcastList.broadcasts.nodes.map((node) => node);

  const { hasPreviousPage, hasNextPage, startCursor, endCursor } =
    broadcastList.broadcasts.pageInfo;

  return (
    <PageContainer data-lgg-id="broadcast-page-container">
      <TableLayoutHeader
        leftContent={
          <PaginationInfo
            total={broadcastList.broadcasts.totalCount}
            testId="broadcast-page-pagination-info"
            entityName="broadcast"
          />
        }
        rightContent={
          <>
            <FiltersButton
              data-lgg-id="broadcast-page-filters-button"
              filtersVisibilityHandler={filtersVisibilityHandler}
              hasActiveFilters={hasActiveFilters}
            />
            {/* TODO: Add export button logic */}
            <LggOptionsDropdownButton
              variant="defaultWhite"
              size="regular"
              icon="exportbtn"
              loading={false}
              visibilityHandler={exportVisibilityHandler}
              options={[]}
              data-lgg-id="broadcast-page-export-button"
            >
              {t('common:actions.export')}
            </LggOptionsDropdownButton>
          </>
        }
      />
      <BroadcastTable
        data={broadcasts}
        onChange={(pagination, filters, sorter) => {
          const { columnKey = undefined, order = undefined } =
            sorter as SorterData<Broadcast>;

          const sortDirection = getSortDirectionByOrder(order);
          handleSortChange({ key: columnKey, direction: sortDirection });
        }}
        sortData={sortData}
        refetchHandler={refetchBroadcastList}
      />
      <TablePagination
        hasPreviousPage={hasPreviousPage}
        hasNextPage={hasNextPage}
        onLoadPreviousPage={() => {
          if (startCursor) {
            handlePreviousPage({ startCursor });
          }
        }}
        onLoadNextPage={() => {
          if (endCursor) {
            handleNextPage({ endCursor });
          }
        }}
      />
      <BroadcastFilters
        visible={filtersVisibilityHandler.visible}
        onClose={filtersVisibilityHandler.close}
        filters={filters}
      />
    </PageContainer>
  );
};
