import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Skeleton } from 'antd';
import styled, { css } from 'styled-components';
import { Query, QueryBroadcastArgs } from '@lgg/isomorphic/types/__generated__/graphql';
import { FlexColumn } from 'src/components/layout/flex-column';
import { FlexRow } from 'src/components/layout/flex-row';
import { PageContainer } from 'src/components/layout/page-containers';
import {
  BroadcastWizard,
  BroadcastWizardStep,
} from 'src/components/pages/broadcast/components/broadcast-wizard/broadcast-wizard';
import { BroadcastWizardHints } from 'src/components/pages/broadcast/components/broadcast-wizard/broadcast-wizard-hints';
import { canBroadcastBeEdited } from 'src/components/pages/broadcast/helpers';
import { GET_BROADCAST_EDIT_DETAILS } from 'src/components/pages/broadcast/queries';
import { useAddBreadcrumb } from 'src/hooks/use-add-breadcrumb';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';
import { useUrls } from 'src/hooks/use-urls';

const SkeletonButton = Skeleton.Button;

const StyledPageContainer = styled(PageContainer)`
  display: flex;
  flex-direction: row;
  height: 100%;
`;
const baseLoadingContainerStyles = css`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  height: 100%;
  padding: 20px;
  width: 100%;
`;

const ContentLoadingContainer = styled(FlexColumn)`
  ${baseLoadingContainerStyles};
  justify-content: space-between;
  margin-right: 30px;
`;

const HintsLoadingContainer = styled(FlexColumn)`
  ${baseLoadingContainerStyles};
  max-width: 288px;
`;

const LoadingIndicatorContainer = styled(FlexRow)`
  width: 100%;
`;

const LoadingButtonsContainer = styled(FlexRow)`
  justify-content: space-between;
`;

const LoadingIndicator = () => {
  return (
    <LoadingIndicatorContainer>
      <ContentLoadingContainer>
        <span>
          <Skeleton active paragraph={{ rows: 0 }} title={{ width: '70%' }} />
          <Skeleton active />
          <Skeleton active />
        </span>
        <LoadingButtonsContainer>
          <SkeletonButton active size="small" />
          <span>
            <SkeletonButton active size="small" style={{ marginRight: '10px' }} />
            <SkeletonButton active size="small" />
          </span>
        </LoadingButtonsContainer>
      </ContentLoadingContainer>
      <HintsLoadingContainer>
        <Skeleton active paragraph={{ rows: 0 }} title={{ width: '100%' }} />
        <Skeleton active />
        <Skeleton active />
      </HintsLoadingContainer>
    </LoadingIndicatorContainer>
  );
};

export const BroadcastDetailsPage = () => {
  useAddBreadcrumb('');
  const handleGraphQlError = useHandleGraphQLError();
  const history = useHistory();
  const { getBroadcastPageUrl } = useUrls();
  const { broadcastId } = useParams<{
    broadcastId?: string;
  }>();

  const { data, loading } = useQuery<Pick<Query, 'broadcast'>, QueryBroadcastArgs>(
    GET_BROADCAST_EDIT_DETAILS,
    {
      skip: !broadcastId,
      variables: {
        id: broadcastId ?? '',
      },
      onError: handleGraphQlError,
    },
  );

  const broadcast = data?.broadcast;

  const [wizardStep, setWizardStep] = useState<BroadcastWizardStep>(
    BroadcastWizardStep.GENERAL,
  );

  useEffect(() => {
    if (broadcast?.status) {
      if (!canBroadcastBeEdited(broadcast.status)) {
        history.push(getBroadcastPageUrl());
      }
    }
  }, [broadcast, getBroadcastPageUrl, history]);

  return (
    <StyledPageContainer data-lgg-id="broadcast-detail-page">
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <BroadcastWizard
            step={wizardStep}
            setWizardStep={setWizardStep}
            broadcast={broadcast}
          />
          <BroadcastWizardHints step={wizardStep} />
        </>
      )}
    </StyledPageContainer>
  );
};
