import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useService } from 'react-service-locator';
import { dayjs } from '@lgg/isomorphic/dayjs';
import * as dateOperations from '@lgg/isomorphic/utils/date-operations';
import { AuthorizationService } from 'src/services/authorization.service';

export type DateRepresentation = string | Date;

export const useDateHelpers = (preferredTimezone?: string) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(['common']);
  const authState = useService(AuthorizationService);
  const companyTimezone = authState.getTimezone();
  const timezoneToBeUsed = preferredTimezone ?? companyTimezone;

  dayjs.tz.setDefault(timezoneToBeUsed);

  const ensureDate = useCallback(
    (date: DateRepresentation) => (typeof date === 'string' ? new Date(date) : date),
    [],
  );

  const isToday = useCallback(
    (date: DateRepresentation, otherDate: Date = new Date()) => {
      const today = dayjs(ensureDate(otherDate));

      return today.isSame(date, 'day');
    },
    [ensureDate],
  );

  const isYesterday = useCallback(
    (date: DateRepresentation, otherDate: Date = new Date()) => {
      const yesterday = dayjs(ensureDate(otherDate)).subtract(1, 'day');

      return yesterday.isSame(date, 'day');
    },
    [ensureDate],
  );

  const isTomorrow = useCallback(
    (date: DateRepresentation, otherDate: Date) => {
      const tomorrow = dayjs(ensureDate(otherDate)).add(1, 'day');

      return tomorrow.isSame(date, 'day');
    },
    [ensureDate],
  );

  const isSameDay = useCallback(
    (date: DateRepresentation, otherDate: Date = new Date()) =>
      dayjs(date).isSame(otherDate, 'day'),
    [],
  );

  const isSameMonth = useCallback(
    (date: DateRepresentation, otherDate: Date = new Date()) =>
      dayjs(date).isSame(otherDate, 'month'),
    [],
  );

  const isSameYear = useCallback(
    (date: DateRepresentation, otherDate: Date = new Date()) =>
      dayjs(date).isSame(otherDate, 'year'),
    [],
  );

  const isEqual = useCallback(
    (date: DateRepresentation, otherDate: Date = new Date()) =>
      dayjs(date).isSame(otherDate),
    [],
  );

  const isPast = useCallback((date: Date) => dayjs().isAfter(date), []);

  const isFuture = useCallback((date: Date) => dayjs().isBefore(date), []);

  const isLastDayOfMonth = useCallback(
    (date: Date) => {
      const inputObject = dayjs(date).tz(timezoneToBeUsed);

      return inputObject.isSame(inputObject.endOf('month').toDate(), 'day');
    },
    [timezoneToBeUsed],
  );

  const startOfDay = useCallback(
    (date: Date) => dayjs(date).tz(timezoneToBeUsed).startOf('day').toDate(),
    [timezoneToBeUsed],
  );

  const nextMonday = useCallback(
    (date: Date = new Date()) => dayjs(date).day(8).toDate(),
    [],
  );

  const endOfDay = useCallback(
    (date: Date) => dayjs(date).tz(timezoneToBeUsed).endOf('day').toDate(),
    [timezoneToBeUsed],
  );

  const endOfToday = useCallback(
    () => dayjs(new Date()).tz(timezoneToBeUsed).endOf('day').toDate(),
    [timezoneToBeUsed],
  );

  const startOfToday = useCallback(
    () => dayjs(new Date()).tz(timezoneToBeUsed).startOf('day').toDate(),
    [timezoneToBeUsed],
  );

  const startOfTomorrow = useCallback(
    () => dayjs(new Date()).tz(timezoneToBeUsed).add(1, 'day').startOf('day').toDate(),
    [timezoneToBeUsed],
  );

  const startOfMonth = useCallback(
    (date: Date) => dayjs(date).tz(timezoneToBeUsed).startOf('month').toDate(),
    [timezoneToBeUsed],
  );

  const startOfWeek = useCallback(
    (date: Date) => dayjs(date).tz(timezoneToBeUsed).startOf('week').toDate(),
    [timezoneToBeUsed],
  );

  const startOfYear = useCallback(
    (date: Date) => dayjs(date).tz(timezoneToBeUsed).startOf('year').toDate(),
    [timezoneToBeUsed],
  );

  const endOfMonth = useCallback(
    (date: Date) => dayjs(date).tz(timezoneToBeUsed).endOf('month').toDate(),
    [timezoneToBeUsed],
  );

  const endOfWeek = useCallback(
    (date: Date) => dayjs(date).tz(timezoneToBeUsed).endOf('week').toDate(),
    [timezoneToBeUsed],
  );

  const endOfYear = useCallback(
    (date: Date) => dayjs(date).tz(timezoneToBeUsed).endOf('year').toDate(),
    [timezoneToBeUsed],
  );

  const lastDayOfMonth = useCallback((date: Date) => {
    const inputObject = dayjs(date);

    return inputObject.endOf('month').startOf('day').toDate();
  }, []);

  const isBefore = useCallback(
    (date1: Date, date2: Date) => dayjs(date1).isBefore(date2),
    [],
  );

  const differenceInDays = useCallback(
    (date1: Date, date2: Date) => dayjs(date1).diff(date2, 'days'),
    [],
  );

  const parseISO = useCallback((date: string) => dayjs(date).toDate(), []);

  const parseDate = useCallback(
    (date: string, formatString: string) =>
      dayjs.tz(date, formatString, timezoneToBeUsed).toDate(),
    [timezoneToBeUsed],
  );

  const getDatePrefix = useCallback(
    (date: Date, baseDate: Date = new Date()) => {
      if (isToday(date, baseDate)) return t('common:datePrefix.sameDay');

      return '';
    },
    [isToday, t],
  );

  const set = useCallback(
    (
      date: Date,
      {
        hours,
        minutes,
        seconds,
        milliseconds,
        day,
        month,
        year,
      }: {
        hours?: number;
        minutes?: number;
        seconds?: number;
        milliseconds?: number;
        day?: number;
        month?: number;
        year?: number;
      },
    ) => {
      let dateObject = dayjs(date).tz(timezoneToBeUsed);

      if (hours !== undefined) {
        dateObject = dateObject.hour(hours);
      }

      if (minutes !== undefined) {
        dateObject = dateObject.minute(minutes);
      }

      if (seconds !== undefined) {
        dateObject = dateObject.second(seconds);
      }

      if (milliseconds !== undefined) {
        dateObject = dateObject.millisecond(milliseconds);
      }

      if (day !== undefined) {
        dateObject = dateObject.date(day);
      }

      if (month !== undefined) {
        dateObject = dateObject.month(month);
      }

      if (year !== undefined) {
        dateObject = dateObject.year(year);
      }

      return dateObject.toDate();
    },
    [timezoneToBeUsed],
  );

  const format = useCallback(
    (
      date: DateRepresentation,
      formatString: string,
      addLocaleInformation: boolean = true,
    ) => {
      if (addLocaleInformation) {
        return dayjs(date).tz(preferredTimezone).locale(language).format(formatString);
      }

      return dayjs(date).format(formatString);
    },
    [preferredTimezone, language],
  );

  const eachDayOfInterval = useCallback(({ start, end }: { start: Date; end: Date }) => {
    if (start.getTime() > end.getTime()) {
      return [];
    }

    const startObject = dayjs(start);
    const endObject = dayjs(end).add(1, 'day');
    const days: Date[] = [];

    let iterableObject = dayjs(startObject);

    while (iterableObject.isBefore(endObject)) {
      days.push(iterableObject.tz('UTC').startOf('day').toDate());
      iterableObject = iterableObject.add(1, 'day');
    }

    return days;
  }, []);

  return {
    differenceInDays,
    eachDayOfInterval,
    endOfDay,
    endOfMonth,
    endOfToday,
    endOfWeek,
    endOfYear,
    ensureDate,
    format,
    getDatePrefix,
    isBefore,
    isEqual,
    isFuture,
    isLastDayOfMonth,
    isPast,
    isSameDay,
    isSameMonth,
    isSameYear,
    isToday,
    isTomorrow,
    isYesterday,
    lastDayOfMonth,
    nextMonday,
    parseDate,
    parseISO,
    set,
    startOfDay,
    startOfMonth,
    startOfToday,
    startOfTomorrow,
    startOfWeek,
    startOfYear,
    ...dateOperations,
  };
};
