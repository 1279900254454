import React, { ComponentProps, memo } from 'react';
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { up } from 'styled-breakpoints';
import styled, { css } from 'styled-components';
import { ColorPaletteItem } from '@lgg/isomorphic';
import { IconButtonV2 } from 'src/components/general/button/icon-button';
import {
  ButtonSize,
  ButtonVariant,
  ctaGhostButtonStyle,
  isomorphicColorButtonStyles,
} from 'src/components/general/button/shared';
import { Icon } from 'src/components/general/icon';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import {
  tertiaryHoverStyle,
  tertiaryStyle,
  tertiaryActiveStyle,
  tertiaryDefaultColors,
  primaryDefaultColors,
} from 'src/theme/sub-themes/buttons-theme';

type BaseProps = {
  loading?: boolean;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'defaultWhite' | 'ctaGhost';
};

type ButtonProps = BaseProps & React.ButtonHTMLAttributes<HTMLButtonElement>;
type AnchorProps = BaseProps & {
  href: string;
};
type LinkProps = BaseProps & {
  to: string;
};
const baseStyles = css`
  transition: all 150ms;
  cursor: pointer;
`;
const buttonBaseStyle = css`
  align-items: center;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 14px;
  height: 38px;
  justify-content: center;
  padding: 10px;
  width: 100%;
`;
const primaryButtonStyle = css`
  ${({ theme }) => theme.buttonsTheme.default};

  &:focus {
    ${primaryDefaultColors};
  }

  &:hover {
    ${({ theme }) => theme.buttonsTheme.hover};
  }

  &:active {
    ${({ theme }) => theme.buttonsTheme.active};
  }
`;
const secondaryDefaultColors = css`
  background-color: ${({ theme }) => theme.colors.cosmo};
  color: ${({ theme }) => theme.colors.white};
`;
const secondaryHoverColors = css`
  background-color: ${({ theme }) => theme.colors.lightAccentGreen};
  box-shadow: 0 2px 4px 0 #389c7a33;
`;
const secondaryActiveColors = css`
  background-color: ${({ theme }) => theme.colors.darkAccentGreen};
  box-shadow: 0 1px 2px 0 #268c6c33;
`;
const secondaryButtonStyle = css`
  ${buttonBaseStyle};
  ${secondaryDefaultColors};

  &:focus {
    ${secondaryDefaultColors};
  }

  &:hover {
    ${secondaryHoverColors};
  }

  &:active {
    ${secondaryActiveColors};
  }
`;
const tertiaryButtonStyle = css`
  ${tertiaryStyle};

  &:focus {
    ${tertiaryDefaultColors};
  }

  &:hover {
    ${tertiaryHoverStyle};
  }

  &:active {
    ${tertiaryActiveStyle};
  }
`;

const ctaGhostButtonStyles = css`
  ${buttonBaseStyle};
  ${ctaGhostButtonStyle}
`;

const defaultWhiteColors = css`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.flint};
`;

const defaultWhiteButtonStyle = css`
  ${buttonBaseStyle};
  ${defaultWhiteColors};

  &:focus {
    ${defaultWhiteColors};
  }

  &:hover {
    ${defaultWhiteColors};
    color: ${({ theme }) => theme.colors.steel};
    box-shadow: 0 2px 4px 0 #97a3ae1a;
  }

  &:active {
    ${defaultWhiteColors};
    box-shadow: 0 1px 2px 0 #4b68821a;
    color: ${({ theme }) => theme.colors.steel};
  }

  &:disabled {
    ${defaultWhiteColors};
    opacity: 0.4;
  }
`;

const isomorphicStyles = css<Pick<BaseProps, 'variant'>>`
  ${baseStyles};
  ${({ variant }) => {
    switch (variant) {
      case 'defaultWhite':
        return defaultWhiteButtonStyle;
      case 'primary':
        return primaryButtonStyle;
      case 'secondary':
        return secondaryButtonStyle;
      case 'tertiary':
        return tertiaryButtonStyle;
      case 'ctaGhost':
        return ctaGhostButtonStyles;
    }
  }}
`;

const StyledBaseButton = styled.button`
  ${buttonBaseStyle};
`;
const StyledButton = styled.button<Pick<BaseProps, 'variant'>>`
  ${isomorphicStyles};
`;
const StyledLink = styled(Link)<Pick<BaseProps, 'variant'>>`
  ${isomorphicStyles};
`;
const StyledAnchor = styled.a<Pick<BaseProps, 'variant'>>`
  ${isomorphicStyles};
`;
export const BaseButton = React.forwardRef<
  HTMLButtonElement,
  Omit<ButtonProps, 'variant'>
>(({ children, loading, ...rest }, ref) => {
  return (
    <StyledBaseButton {...rest} ref={ref}>
      {loading ? <LoadingOutlined /> : children}
    </StyledBaseButton>
  );
});
export const LggButton: React.FC<ButtonProps> = ({
  children,
  variant = 'primary',
  loading,
  ...rest
}) => {
  return (
    <StyledButton {...rest} variant={variant}>
      {loading ? <LoadingOutlined /> : children}
    </StyledButton>
  );
};
export const LinkButton: React.FC<LinkProps> = ({
  children,
  variant = 'primary',
  loading,
  ...rest
}) => {
  return (
    <StyledLink {...rest} variant={variant}>
      {loading ? <LoadingOutlined /> : children}
    </StyledLink>
  );
};
export const AnchorButton: React.FC<AnchorProps> = ({
  children,
  variant = 'primary',
  loading,
  ...rest
}) => {
  return (
    <StyledAnchor {...rest} variant={variant}>
      {loading ? <LoadingOutlined /> : children}
    </StyledAnchor>
  );
};

export type IconNotificationProps = {
  showNotification: boolean;
  notificationColor?: ColorPaletteItem;
};

// V2 implementation
export const BaseButtonIcon = styled(Icon)<{
  $notificationColor?: ColorPaletteItem;
  $showNotification?: boolean;
}>`
  position: relative;

  &:not(:only-child) {
    margin-right: 10px;
  }

  ${({ $showNotification, $notificationColor }) =>
    $showNotification &&
    css`
      &::before {
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        background: ${({ theme }) =>
          $notificationColor ? theme.colors[$notificationColor] : theme.colors.monk};
        position: absolute;
        top: -10px;
        right: -12px;
        border-radius: 50%;
        margin: 5px;
        border: 2px solid ${({ theme }) => theme.colors.white};
      }
    `}
`;

export const baseButtonV2Styles = css`
  align-items: center;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: ${({ theme }) => theme.font.medium};
  justify-content: center;
  padding: 10px 15px;
`;

export const StyledBaseButtonV2 = styled.button<{
  variant: ButtonVariant;
}>`
  ${baseButtonV2Styles};
  ${isomorphicColorButtonStyles};
`;

type BaseButtonButtonV2Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  variant: ButtonVariant;
  icon?: ComponentProps<typeof Icon>['type'] | React.ReactNode;
  iconNotificationProps?: IconNotificationProps;
};

export const BaseButtonV2 = memo<React.PropsWithChildren<BaseButtonButtonV2Props>>(
  ({ icon, children, iconNotificationProps, ...rest }) => {
    return (
      <StyledBaseButtonV2 {...rest}>
        {icon ? (
          typeof icon === 'string' ? (
            <BaseButtonIcon
              data-element="leading-icon"
              type={icon}
              $showNotification={iconNotificationProps?.showNotification}
              $notificationColor={iconNotificationProps?.notificationColor}
            />
          ) : (
            icon
          )
        ) : null}
        <span data-element="label">{children}</span>
      </StyledBaseButtonV2>
    );
  },
);

const buttonV2BaseStyles = css<{ size: ButtonSize }>`
  ${baseButtonV2Styles};
  ${({ size }) => {
    switch (size) {
      case 'regular':
        return `
          font-size: 14px;
          height: 38px;
          line-height: 17px;
          ${BaseButtonIcon} svg {
            height: 18px;
            width: 18px;
          }
        `;
      case 'small':
        return `
          font-size: 12px;
          height: 34px;
          line-height: 16px;
          ${BaseButtonIcon} svg {
            height: 16px;
            width: 16px;
          }
        `;
    }
  }};
`;

export const StyledButtonV2 = styled.button<{
  size: ButtonSize;
  variant: ButtonVariant;
  active?: boolean;
}>`
  ${buttonV2BaseStyles};
  ${isomorphicColorButtonStyles};
  ${({ active, theme }) =>
    active &&
    css`
      color: ${theme.colors.gogo};
    `}
`;

const ButtonV2Content = styled.span`
  width: 100%;
`;

export type ButtonV2Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  size: ButtonSize;
  variant: ButtonVariant;
  icon?: ComponentProps<typeof Icon>['type'] | React.ReactNode;
  iconNotificationProps?: IconNotificationProps;
  active?: boolean;
};

export const ButtonV2 = memo<React.PropsWithChildren<ButtonV2Props>>(
  ({ icon, children, iconNotificationProps, ...rest }) => {
    return (
      <StyledButtonV2 {...rest}>
        {icon ? (
          typeof icon === 'string' ? (
            <BaseButtonIcon
              data-element="leading-icon"
              type={icon}
              $showNotification={iconNotificationProps?.showNotification}
              $notificationColor={iconNotificationProps?.notificationColor}
            />
          ) : (
            icon
          )
        ) : null}
        <ButtonV2Content>{children}</ButtonV2Content>
      </StyledButtonV2>
    );
  },
);

type ResponsiveButtonProps = Omit<React.PropsWithChildren<ButtonV2Props>, 'icon'> & {
  icon: ComponentProps<typeof Icon>['type'];
  active?: boolean;
};

export const ResponsiveButton = memo<ResponsiveButtonProps>(
  ({ icon, children, iconNotificationProps, ...rest }) => {
    const breakpointUpMd = useBreakpoint(up('md'));

    return breakpointUpMd ? (
      <ButtonV2 icon={icon} {...rest} iconNotificationProps={iconNotificationProps}>
        {children}
      </ButtonV2>
    ) : (
      <IconButtonV2
        {...rest}
        icon={icon}
        variant={rest.variant}
        size={rest.size}
        onClick={rest.onClick}
        notificationProps={iconNotificationProps}
      />
    );
  },
);
