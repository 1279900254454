import { match } from 'ts-pattern';
import { ContactInteractionParticipatingEntityEdge } from '../types/__generated__/graphql';

export const participatingEntityPatterns = {
  agent: {
    __typename: 'ContactInteractionParticipatingEntityEdge',
    type: 'AGENT',
  } as const,
  callflow: {
    __typename: 'ContactInteractionParticipatingEntityEdge',
    type: 'CALLFLOW',
  } as const,
  broadcast: {
    __typename: 'ContactInteractionParticipatingEntityEdge',
    type: 'BROADCAST',
  } as const,
};

type MatchParticipatingEntityHandler<Result> = {
  agent: (participatingEntity: ContactInteractionParticipatingEntityEdge) => Result;
  callflow: (participatingEntity: ContactInteractionParticipatingEntityEdge) => Result;
  broadcast: (participatingEntity: ContactInteractionParticipatingEntityEdge) => Result;
};

export const matchParticipatingEntityExhaustive = <Result>(
  participatingEntity: ContactInteractionParticipatingEntityEdge,
  handler: MatchParticipatingEntityHandler<Result>,
) => {
  return match(participatingEntity)
    .with(participatingEntityPatterns.agent, handler.agent)
    .with(participatingEntityPatterns.callflow, handler.callflow)
    .with(participatingEntityPatterns.broadcast, handler.broadcast)
    .run();
};
