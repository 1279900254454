import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { up } from 'styled-breakpoints';
import styled, { useTheme, ThemeProvider } from 'styled-components';
import { ModalsZIndex } from 'src/components/constants';
import { ButtonV2 } from 'src/components/general/button/lgg-button';
import { ButtonVariant } from 'src/components/general/button/shared';
import { Icon } from 'src/components/general/icon';
import { FlexRow } from 'src/components/layout/flex-row';
import { useBreakpoint } from 'src/hooks/use-breakpoint';

const ConfirmationModalContentWrapper = styled.div<{ $maxWidth?: number }>`
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 4px 34px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 190px;
  justify-content: space-between;
  max-width: ${({ $maxWidth }) => ($maxWidth ? `${$maxWidth}px` : '420px')};
  pointer-events: all;
  width: max-content;
`;

const ConfirmationModalTitle = styled.p`
  color: ${({ theme }) => theme.colors.carbon};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 24px;
  letter-spacing: -0.48px;
  line-height: 29px;
  margin: 0 0 10px;
  padding-right: 26px;
`;

const ConfirmationModalMessage = styled.p`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 14px;
  line-height: 17px;
  margin: 0;
  padding-right: 16px;
`;

const ConfirmationModalBody = styled.div`
  padding: 20px;
`;

const ConfirmationModalFooter = styled(FlexRow)`
  border-top: 1px solid ${({ theme }) => theme.colors.koala};
  justify-content: flex-end;
  padding: 15px 20px;

  & > :not(:last-child) {
    margin-right: 15px;
  }
`;

const CloseIcon = styled(Icon)`
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;

  svg {
    height: 16px;
    width: 16px;

    path {
      fill: ${({ theme }) => theme.colors.casper};
    }
  }
`;

type ConfirmationModalContentProps = {
  title: React.ReactElement | string;
  message: React.ReactElement | string;
  testId: string;
  onConfirm: VoidFunction;
  confirmButtonText: string;
  cancelButtonText?: string;
  confirmButtonType?: ButtonVariant;
  confirmButtonDisabled?: boolean;
  onCancel?: VoidFunction;
  onClose: VoidFunction;
  buttonSize: 'small' | 'regular';
  maxWidth?: number;
};

export const ConfirmationModalContent = ({
  title,
  message,
  onConfirm,
  onCancel,
  confirmButtonText,
  cancelButtonText,
  confirmButtonType = 'cta',
  confirmButtonDisabled = false,
  testId,
  onClose,
  buttonSize,
  maxWidth,
}: ConfirmationModalContentProps) => {
  const { t } = useTranslation(['common']);

  return (
    <ConfirmationModalContentWrapper data-lgg-id={testId} $maxWidth={maxWidth}>
      <CloseIcon type="close" onClick={onClose} data-lgg-id="confirm-modal-close-icon" />
      <ConfirmationModalBody>
        <ConfirmationModalTitle>{title}</ConfirmationModalTitle>
        <ConfirmationModalMessage>{message}</ConfirmationModalMessage>
      </ConfirmationModalBody>
      <ConfirmationModalFooter>
        <ButtonV2
          data-lgg-id="confirmation-modal-cancel-button"
          variant="default"
          size={buttonSize}
          onClick={() => {
            onCancel?.();
            onClose();
          }}
        >
          {cancelButtonText ?? t('common:cancel')}
        </ButtonV2>
        <ButtonV2
          data-lgg-id="confirmation-modal-confirm-button"
          variant={confirmButtonType}
          disabled={confirmButtonDisabled}
          size={buttonSize}
          onClick={() => {
            onConfirm();
            onClose();
          }}
        >
          {confirmButtonText}
        </ButtonV2>
      </ConfirmationModalFooter>
    </ConfirmationModalContentWrapper>
  );
};

type ShowConfirmationModalProps = {
  title: React.ReactElement | string;
  message: React.ReactElement | string;
  testId: string;
  onConfirm: VoidFunction;
  confirmButtonText: string;
  cancelButtonText?: string;
  confirmButtonType?: ButtonVariant;
  confirmButtonDisabled?: boolean;
  onCancel?: VoidFunction;
};

export const useShowConfirmationModal = () => {
  const theme = useTheme();
  const breakpointUpMd = useBreakpoint(up('md'));
  const buttonSize = breakpointUpMd ? 'small' : 'regular';

  const showConfirmationModal = useCallback(
    ({
      title,
      message,
      onConfirm,
      onCancel,
      confirmButtonText,
      cancelButtonText,
      confirmButtonType = 'cta',
      confirmButtonDisabled = false,
      testId,
    }: ShowConfirmationModalProps) => {
      const confirmationModal = Modal.confirm({
        visible: false,
        modalRender: () => <></>,
      });

      const closeConfirmationModal = () => {
        confirmationModal.destroy();
      };

      confirmationModal.update({
        maskClosable: true,
        visible: true,
        icon: null,
        centered: true,
        closable: true,
        zIndex: ModalsZIndex,
        onCancel: closeConfirmationModal,
        modalRender: (_) => {
          return (
            <ThemeProvider theme={theme}>
              <ConfirmationModalContent
                title={title}
                message={message}
                testId={testId}
                onConfirm={onConfirm}
                confirmButtonText={confirmButtonText}
                onClose={() => {
                  closeConfirmationModal();
                }}
                buttonSize={buttonSize}
                cancelButtonText={cancelButtonText}
                confirmButtonDisabled={confirmButtonDisabled}
                confirmButtonType={confirmButtonType}
                onCancel={() => {
                  onCancel?.();
                }}
              />
            </ThemeProvider>
          );
        },
      });

      return confirmationModal;
    },
    [theme, buttonSize],
  );

  return showConfirmationModal;
};
